import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import {
  DEFAULT_INTRO_CONFIG_SUBTITLE,
  DEFAULT_INTRO_CONFIG_TITLE,
} from '../constants';
import logoPlaceholderIcon from 'src/assets/images/logo-placeholder.svg';
import { selectWidgetConfiguration } from 'src/slices/solve-config/solveConfigSlice';

export const Intro = () => {
  const theme = useTheme();
  const { intro_config: introConfig } = useSelector(selectWidgetConfiguration);
  // check that at least one intro config is enabled and populated
  const isIntroEnabled = Object.values(introConfig).some(
    config => config.enabled,
  );

  if (!isIntroEnabled) {
    return null;
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        paddingTop: '16px',
        paddingX: '8px',
        textAlign: 'center',
      }}
    >
      {introConfig.image.enabled && (
        <Box sx={{ display: 'block' }}>
          <Box
            alt=''
            component='img'
            src={introConfig.image.value || logoPlaceholderIcon}
            sx={{ height: '64px', width: 'auto' }}
          />
        </Box>
      )}
      {introConfig.title.enabled && (
        <Typography variant='font20'>
          {introConfig.title.value || DEFAULT_INTRO_CONFIG_TITLE}
        </Typography>
      )}
      {introConfig.subtitle.enabled && (
        <Typography color={theme.palette.colors.grey[600]} variant='font14'>
          {introConfig.subtitle.value || DEFAULT_INTRO_CONFIG_SUBTITLE}
        </Typography>
      )}
    </Box>
  );
};
