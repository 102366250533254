import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { FormProps } from '@rjsf/core';

import {
  Button,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  ButtonGroupWrapper,
  FormButtonGroup,
  FormHeader,
  FormWrapper,
} from './styles';
import JSONSchemaForm from 'src/components/json-schema-form';
import { Credentials } from 'src/services/apiInterfaces';

interface IntegrationFormProps extends FormProps<Credentials> {
  disableDisplayName?: boolean;
  displayName?: string;
  existingNames?: string[];
  formButtons: React.ReactNode;
  isCreate: boolean;
  multiConnectorsEnabled: boolean;
  onDisplayNameChange?: (value: string) => void;
  onSaveDisplayName?: () => void;
}

export default function IntegrationForm({
  disableDisplayName = false,
  displayName: initialDisplayName = '',
  existingNames,
  formButtons,
  formData,
  isCreate,
  multiConnectorsEnabled,
  onDisplayNameChange,
  onSaveDisplayName,
  schema,
  ...rest
}: IntegrationFormProps) {
  const [displayName, setDisplayName] = useState(initialDisplayName);
  const [error, setError] = useState<string | null>(null);
  const [isEditingDisplayName, setIsEditingDisplayName] = useState(false);
  const [isSavingDisplayName, setIsSavingDisplayName] = useState(false);

  useEffect(() => {
    setDisplayName(initialDisplayName);
  }, [initialDisplayName]);

  const validateDisplayName = (value: string) => {
    if (!value.trim()) {
      return 'This is required.';
    }
    if (existingNames?.includes(value.trim())) {
      return 'This display name already exists';
    }
    return null;
  };

  const handleDisplayNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDisplayName(value);

    const validationError = validateDisplayName(value);
    setError(validationError);

    if (!validationError) {
      setIsEditingDisplayName(true);
    }

    if (!validationError && onDisplayNameChange) {
      onDisplayNameChange(value);
    }
  };

  const handleSaveDisplayName = async () => {
    if (!onSaveDisplayName) {
      return;
    }

    const validationError = validateDisplayName(displayName);
    setError(validationError);

    if (!validationError) {
      setIsSavingDisplayName(true);
      await onSaveDisplayName();
      setIsSavingDisplayName(false);
      setIsEditingDisplayName(false);
    }
  };

  const handleCancel = () => {
    setDisplayName(initialDisplayName);
    setError(null);
    setIsEditingDisplayName(false);
  };

  const hasFormFields = !!Object.keys(schema?.properties || {}).length;

  return (
    <FormWrapper>
      {hasFormFields && (
        <FormHeader>
          <Typography variant='font16Bold'>General Preferences</Typography>
        </FormHeader>
      )}

      {!isCreate && multiConnectorsEnabled && (
        <Box sx={{ marginTop: 2 }}>
          <TextField
            disabled={disableDisplayName || isSavingDisplayName}
            error={!!error}
            fullWidth
            helperText={
              isEditingDisplayName
                ? error || 'Provide a display name for easy reference'
                : ''
            }
            label='Display name'
            onChange={handleDisplayNameChange}
            onFocus={() => setIsEditingDisplayName(true)}
            value={displayName}
          />
        </Box>
      )}

      {isEditingDisplayName && (
        <ButtonGroupWrapper>
          <Button
            disabled={isSavingDisplayName}
            onClick={handleCancel}
            size='medium'
            variant='ghost'
          >
            Cancel
          </Button>
          <Button
            disabled={!!error || isSavingDisplayName}
            isLoading={isSavingDisplayName}
            onClick={handleSaveDisplayName}
            size='medium'
            variant='main'
          >
            Save
          </Button>
        </ButtonGroupWrapper>
      )}

      <JSONSchemaForm
        formData={formData}
        isCreate={isCreate}
        schema={schema}
        {...rest}
      >
        <FormButtonGroup>{formButtons}</FormButtonGroup>
      </JSONSchemaForm>
    </FormWrapper>
  );
}
