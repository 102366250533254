import { MultiSelectFilter } from '@forethought-technologies/forethought-elements';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import {
  DiscoverArticle,
  KnowledgeGapArticleRecommendations,
} from 'src/reducers/discoverReducer/types';
import { PolicyRecommendation } from 'src/types/DiscoverTypes';

const BrandFilter = ({
  data,
  onChange,
  selectedTab,
  value,
}: {
  data:
    | PolicyRecommendation[]
    | KnowledgeGapArticleRecommendations['articles']
    | DiscoverArticle[];
  onChange: (value: string[]) => void;
  selectedTab: 'policies' | 'knowledge' | 'generated-articles';
  value?: string[];
}) => {
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const options = [...new Set(data.map(item => item.api_data_filter))]
    .filter(Boolean)
    .map(item => ({
      label: item || '',
      value: item || '',
    }));

  const handleChange = (value: string[]) => {
    const eventType =
      selectedTab === 'generated-articles'
        ? 'discover-topic-detail-brand-filter-changed'
        : 'discover-brand-filter-changed';
    emitTrackingEventCallback(eventType, {
      brands: value,
      tab: selectedTab,
    });
    onChange(value);
  };

  return (
    <MultiSelectFilter
      onChange={handleChange}
      options={options}
      placeholder='Filter by brand'
      value={value || []}
    />
  );
};

export default BrandFilter;
