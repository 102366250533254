import QuickFeedbackTabContent from '../csat-tab-content/QuickFeedbackTabContent';
import KnowledgeTabContent from '../knowledge-tab-content/KnowledgeTabContent';
import { ToneTabContent } from '../tone-tab-content';
import { PlaybookTabKeys } from './types';

interface WidgetContentProps {
  widgetType: PlaybookTabKeys;
}
const WidgetContent = ({ widgetType }: WidgetContentProps) => {
  if (widgetType === 'tone-of-voice') {
    return <ToneTabContent isPlaybook />;
  }
  if (widgetType === 'quick-feedback') {
    return <QuickFeedbackTabContent isPlaybook />;
  }
  if (widgetType === 'knowledge') {
    return <KnowledgeTabContent />;
  }
  return null;
};

export default WidgetContent;
