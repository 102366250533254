import React, { useEffect, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import { FormProps } from '@rjsf/core';

import { Button, theme } from '@forethought-technologies/forethought-elements';
import {
  selectConnectors,
  setIsEditing,
  setIsUpdateError,
} from '../../../../../slices/connectors/connectorsSlice';
import { updateConnector } from '../../../../../slices/connectors/thunks';
import DrawerSection from '../../DrawerSection';
import ConnectConnectorButton from '../ConnectConnectorButton';
import IntegrationForm from '../IntegrationForm';
import { useSaveDisplayName } from './hooks/useSaveDisplayName';
import { Connector, Credentials } from 'src/services/apiInterfaces';

export interface UpdateIntegrationFormProps extends FormProps<Credentials> {
  existingNames?: string[];
  multiConnectorsEnabled: boolean;
  selectedConnector: Connector;
}

export default function UpdateIntegrationForm({
  existingNames,
  formData,
  multiConnectorsEnabled,
  selectedConnector,
  ...rest
}: UpdateIntegrationFormProps) {
  const [editableFormData, setEditableFormData] = useState(formData);
  const dispatch = useDispatch();
  const { isEditing, isUpdateLoading } = useSelector(selectConnectors);

  const {
    currentDisplayName,
    handleSaveDisplayName,
    resetDisplayName,
    setCurrentDisplayName,
  } = useSaveDisplayName({
    connectorId: selectedConnector.connector_id,
    initialDisplayName: selectedConnector.display_name || '',
  });

  useEffect(() => {
    setEditableFormData(formData);
  }, [formData]);

  useBeforeunload(e => {
    if (isEditing) {
      e.preventDefault();
    }
  });

  const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const fieldsChanged =
      JSON.stringify(editableFormData) !== JSON.stringify(formData);

    if (fieldsChanged) {
      dispatch(
        updateConnector({
          connectorId: selectedConnector.connector_id,
          updateConnectorRequest: {
            connector_fields: editableFormData,
            connector_types: selectedConnector.connector_types,
          },
        }),
      );
    }

    dispatch(setIsEditing(false));
  };

  return (
    <>
      {selectedConnector.connector_definition.setup_instructions && (
        <DrawerSection title='Setup Instructions'>
          <SetupInstructions
            dangerouslySetInnerHTML={{
              __html: selectedConnector.connector_definition.setup_instructions,
            }}
          />
        </DrawerSection>
      )}
      <IntegrationForm
        {...rest}
        disabled={!isEditing || isUpdateLoading}
        disableDisplayName={!isEditing}
        displayName={currentDisplayName}
        existingNames={existingNames}
        formButtons={
          isEditing ? (
            <>
              <Button
                disabled={isUpdateLoading}
                fullWidth
                onClick={e => {
                  e.preventDefault();
                  dispatch(setIsEditing(false));
                  dispatch(setIsUpdateError(false));
                  setEditableFormData(formData);
                  resetDisplayName();
                }}
                size='large'
                type='button'
                variant='ghost'
              >
                Cancel
              </Button>
              <Button
                disabled={isUpdateLoading}
                fullWidth
                isLoading={isUpdateLoading}
                onClick={handleSubmit}
                size='large'
                type='submit'
                variant='secondary'
              >
                Save
              </Button>
            </>
          ) : (
            <>
              <ConnectConnectorButton connector={selectedConnector} />
              <Button
                fullWidth
                onClick={e => {
                  e.preventDefault();
                  dispatch(setIsEditing(true));
                }}
                size='large'
                startIcon={<EditIcon />}
                type='button'
                variant='secondary'
              >
                Edit Settings
              </Button>
            </>
          )
        }
        formData={editableFormData}
        isCreate={false}
        multiConnectorsEnabled={multiConnectorsEnabled}
        onChange={e => setEditableFormData(e.formData)}
        onDisplayNameChange={setCurrentDisplayName}
        onSaveDisplayName={handleSaveDisplayName}
      />
    </>
  );
}

const SetupInstructions = styled('div')`
  color: ${theme.palette.grey[700]};
  font-size: 14px;
  line-height: 24px;

  ol,
  ul {
    padding-inline-start: 16px;
  }
`;
