import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, styled, useTheme } from '@mui/material';
import {
  useRTVIClient,
  useRTVIClientTransportState,
} from '@pipecat-ai/client-react';
import {
  IconMicrophone,
  IconMicrophoneOff,
  IconPhone,
} from '@tabler/icons-react';

import {
  LoadingIndicator,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { convertIntentFiltersToDict } from '../utils';
import { selectPreviewIntentFilters } from 'src/slices/workflow-preview/workflowPreviewSlice';

export const ControlButtons = () => {
  const { palette } = useTheme();
  const client = useRTVIClient();
  const transportState = useRTVIClientTransportState();
  const intentFilters = useSelector(selectPreviewIntentFilters);

  const isConnected = ['connected', 'ready'].includes(transportState);
  const isConnecting = [
    'authenticating',
    'initializing',
    'connecting',
  ].includes(transportState);

  const [micEnabled, setMicEnabled] = useState(true);

  // remember the mute state after conversation/client restart
  useEffect(() => {
    if (!micEnabled && transportState === 'connecting' && client) {
      client.enableMic(false);
    }
  }, [micEnabled, transportState, client]);

  const handleEnableMic = () => {
    if (client) {
      client.enableMic(!micEnabled);
    }
    setMicEnabled(!micEnabled);
  };

  const handleConnect = async () => {
    if (!client) {
      console.error('RTVI client is not initialized');
      return;
    }

    client.params.requestData = {
      intent_filters_for_preview: convertIntentFiltersToDict(intentFilters),
    };

    try {
      if (isConnected) {
        await client.disconnect();
      } else {
        await client.connect();
      }
    } catch (error) {
      console.error('Connection error:', error);
    }
  };

  return (
    <Box display='flex' gap='20px' justifyContent='center'>
      <ButtonContainer>
        <MuteButton isMuted={!micEnabled} onClick={handleEnableMic}>
          {micEnabled ? (
            <IconMicrophone color='white' size={40} />
          ) : (
            <IconMicrophoneOff color={palette.colors.red[400]} size={40} />
          )}
        </MuteButton>
        <Typography color={palette.colors.white} variant='font14'>
          {micEnabled ? 'Mute' : 'Unmute'}
        </Typography>
      </ButtonContainer>

      <ButtonContainer>
        <Tooltip
          placement='top'
          tooltipContent={
            isConnected
              ? 'This won’t clean up the existing transcript until you start a new convo'
              : ''
          }
        >
          <CallButton
            disabled={isConnecting}
            isCalling={isConnected}
            onClick={handleConnect}
          >
            {isConnecting ? (
              <LoadingIndicator color={palette.colors.white} />
            ) : (
              <IconPhone
                color='white'
                size={40}
                style={{
                  transform: isConnected ? 'rotate(135deg)' : undefined,
                }}
              />
            )}
          </CallButton>
        </Tooltip>
        <Typography color={palette.colors.white} variant='font14'>
          {isConnected ? 'End' : 'Start'}
        </Typography>
      </ButtonContainer>
    </Box>
  );
};

const ButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

const CustomizedIconButton = styled('button')`
  cursor: pointer;
  display: flex;
  width: 80px;
  height: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  flex-shrink: 0;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px -1.18px 1.18px 0px rgba(255, 255, 255, 0.1) inset,
    0px 1.18px 1.18px 0px rgba(255, 255, 255, 0.25) inset,
    0px 9.439px 7.079px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(59px);

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
`;

const MuteButton = styled(CustomizedIconButton)<{ isMuted?: boolean }>`
  background-color: ${props =>
    props.isMuted && props.theme.palette.colors.white};

  &:hover {
    background-color: ${props =>
      props.isMuted && props.theme.palette.colors.slate[100]};
  }
`;

const CallButton = styled(CustomizedIconButton)<{ isCalling?: boolean }>`
  background-color: ${props =>
    props.isCalling
      ? props.theme.palette.colors.red[500]
      : props.theme.palette.colors.green[500]};

  &:hover {
    background-color: ${props =>
      props.isCalling
        ? props.theme.palette.colors.red[400]
        : props.theme.palette.colors.green[400]};
  }
`;
