import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconPencilStar } from '@tabler/icons-react';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import PageControl from './PageControl';
import lightningIcon from 'src/assets/images/lightning-icon.svg';

interface GeneratedReplyFooterProps {
  isLoading?: boolean;
}

const GeneratedReplyFooter = ({ isLoading }: GeneratedReplyFooterProps) => {
  const { palette } = useTheme();

  const handleAddtoReply = () => undefined;

  return (
    <Box
      alignItems='center'
      bgcolor={palette.colors.slate[100]}
      display='flex'
      justifyContent='space-between'
      p={2}
      sx={{ borderTop: `1px solid ${palette.colors.slate[200]}` }}
    >
      <Box alignItems='center' display='flex' gap={1.25}>
        <Button
          disabled={isLoading}
          onClick={handleAddtoReply}
          startIcon={<img src={lightningIcon} />}
          variant='main'
        >
          Add to reply
        </Button>
        <Box
          alignItems='center'
          display='flex'
          gap={0.5}
          sx={{ cursor: 'pointer', span: { fontWeight: 600 } }}
        >
          <IconPencilStar
            color={palette.colors.grey[700]}
            size={20}
            strokeWidth={2}
          />
          <Typography color={palette.colors.grey[700]} variant='font14'>
            Improve
          </Typography>
        </Box>
      </Box>
      <PageControl />
    </Box>
  );
};

export default GeneratedReplyFooter;
