import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconX } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import SupportGPTIcon from 'src/assets/images/support-gpt.svg';

const GeneratedReplyHeader = () => {
  const { palette } = useTheme();
  return (
    <Box
      alignItems='center'
      className='generated-reply-handle'
      display='flex'
      justifyContent='space-between'
      px={2}
      py={1}
      sx={{
        borderBottom: `1px solid ${palette.colors.slate[200]}`,
        cursor: 'grab',
      }}
    >
      <Box alignItems='center' display='flex' gap={0.5}>
        <img alt='' src={SupportGPTIcon} />
        <Box sx={{ span: { fontWeight: 700 } }}>
          <Typography color={palette.colors.grey[600]} variant='font12'>
            Forethought Assist - Generated reply
          </Typography>
        </Box>
      </Box>
      <IconX color={palette.colors.grey[600]} />
    </Box>
  );
};

export default GeneratedReplyHeader;
