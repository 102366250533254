import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';

import { theme } from '@forethought-technologies/forethought-elements';
import {
  addAction,
  getHandoffCustomization,
  updateActionSettings,
} from '../../../slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { FieldNameToIdx } from '../handoff-configuration/zendesk/constants';
import isEqual from 'lodash/fp/isEqual';
import { setCustomizableActionId } from 'src/actions/action-builder-actions/actionBuilderActions';
import { LoadingSkeleton } from 'src/components/reusable-components/loading-skeleton';
import StyledButton, { SecondaryButton } from 'src/components/styled-button';
import { useTrackCanvasWorkflowActionEditedEvent } from 'src/hooks/hooks';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { ZendeskTicketCreationConfiguration } from 'src/pages/workflow-builder-edit/handoff-configuration';
import { hasAtLeastOneError } from 'src/pages/workflow-builder-edit/handoff-configuration/helpers';
import {
  cleanCustomizationData,
  initializeFieldTouchedArr,
  validateTicketCreationCustomizationData,
} from 'src/pages/workflow-builder-edit/handoff-configuration/zendesk/helpers';
import { selectCanvasWorkflowBuilder } from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  CanvasWorkflowBuilderState,
  selectAddingAction,
  selectHandoffCustomization,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { ActionPanelMode } from 'src/types/actionBuilderApiTypes';
import {
  ZendeskHandoffCustomization,
  ZendeskTicketCreationCustomization,
} from 'src/types/workflowBuilderAPITypes';
import { ActionBuilderActionTypes } from 'src/utils/enums';

interface ZendeskTicketCreationCustomizationPanelProps {
  hasWorkflowConflict: boolean;
  onDiscard: () => void;
  onDismiss: () => void;
  setActionPanelVisibilityParameters: React.Dispatch<
    React.SetStateAction<ActionPanelMode>
  >;
  setAreActionSettingsUnsaved: (show: boolean) => void;
}

const ZendeskTicketCreationCustomizationPanel: FC<
  React.PropsWithChildren<ZendeskTicketCreationCustomizationPanelProps>
> = ({
  hasWorkflowConflict,
  onDiscard,
  onDismiss,
  setActionPanelVisibilityParameters,
  setAreActionSettingsUnsaved,
}) => {
  const canvasState: CanvasWorkflowBuilderState = useSelector(
    selectCanvasWorkflowBuilder,
  );
  const addingAction = useSelector(selectAddingAction);
  const defaultZendeskHandoffCustomizationSettings = useSelector(
    selectHandoffCustomization,
  ) as Partial<ZendeskHandoffCustomization>;
  const defaultZendeskTicketCreationCustomizationSettings = useMemo(
    () => ({
      email: '',
      name: '',
      question: 'free_form_query',
      ticket_creation_settings: {
        ticket_creation_config: {
          allow_attachment_upload: false,
          available_groups: [],
          available_priorities: [],
          available_statuses: [],
          available_ticket_brands: [],
          available_types: [],
          custom_fields_and_values: [],
          group_id: null,
          priority: null,
          status: null,
          ticket_brand: null,
          ticket_custom_fields: [],
          ticket_tags: [],
          ticket_title: null,
          type: null,
          update_flamethrower_conversation_deflection_status: true,
          ...defaultZendeskHandoffCustomizationSettings.ticket_creation_settings
            ?.ticket_creation_config,
        },
      },
      ticket_id_output_variable: null,
    }),
    [
      defaultZendeskHandoffCustomizationSettings.ticket_creation_settings
        ?.ticket_creation_config,
    ],
  );
  const handoffCustomization = addingAction
    ? defaultZendeskTicketCreationCustomizationSettings
    : (canvasState?.actionSettings
        .configuration_fields as ZendeskTicketCreationCustomization);
  const dispatchActionEditedTrackingEvent =
    useTrackCanvasWorkflowActionEditedEvent();

  const dispatch = useDispatch();

  const { contextVariables } = useGetContextVariables({
    shouldIncludeSystemContextVariables: true,
  });

  // Creating a new state so we can keep track of what has changed
  const [customizationData, setCustomizationData] =
    useState<ZendeskTicketCreationCustomization>(handoffCustomization);

  const errorMessages = validateTicketCreationCustomizationData({
    allowableContextVariables: contextVariables,
    customizationData: customizationData,
  });

  const isPristine = isEqual(customizationData, handoffCustomization);

  const [isFieldTouchedArr, setIsFieldTouchedArr] = useState(
    initializeFieldTouchedArr(customizationData),
  );

  const nameErrorMessage = isFieldTouchedArr[FieldNameToIdx.NAME]
    ? errorMessages[FieldNameToIdx.NAME]
    : '';
  const emailErrorMessage = isFieldTouchedArr[FieldNameToIdx.EMAIL]
    ? errorMessages[FieldNameToIdx.EMAIL]
    : '';
  const questionErrorMessage = isFieldTouchedArr[FieldNameToIdx.QUESTION]
    ? errorMessages[FieldNameToIdx.QUESTION]
    : '';

  const isSaveDisabledFromForm =
    hasWorkflowConflict ||
    Boolean(nameErrorMessage) ||
    Boolean(emailErrorMessage) ||
    Boolean(questionErrorMessage) ||
    (!addingAction && isPristine);

  useEffect(() => {
    if (!isPristine) {
      setAreActionSettingsUnsaved(true);
    } else {
      setAreActionSettingsUnsaved(false);
    }
  }, [isPristine, setAreActionSettingsUnsaved]);

  useEffect(() => {
    if (!canvasState.isLoadingCustomization) {
      setCustomizationData(
        addingAction
          ? defaultZendeskTicketCreationCustomizationSettings
          : handoffCustomization,
      );
    }
  }, [
    canvasState.isLoadingCustomization,
    handoffCustomization,
    addingAction,
    defaultZendeskTicketCreationCustomizationSettings,
  ]);

  useEffect(() => {
    if (addingAction) {
      dispatch(
        getHandoffCustomization(
          ActionBuilderActionTypes.ZENDESK_TICKET_CREATION,
        ),
      );
    }
  }, [dispatch, addingAction]);

  if (
    canvasState.isLoadingCustomization ||
    !handoffCustomization ||
    !customizationData
  ) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <ZendeskTicketCreationConfiguration
        customizationData={customizationData}
        errorMessages={errorMessages}
        isFieldTouchedArr={isFieldTouchedArr}
        isFromCanvas
        setCustomizationData={setCustomizationData}
        setIsFieldTouchedArr={setIsFieldTouchedArr}
      />

      <SaveContainer hasTopBorder>
        <SecondaryButton
          onClick={() => {
            onDiscard();
          }}
        >
          Cancel
        </SecondaryButton>
        <StyledButton
          disabled={isSaveDisabledFromForm}
          onClick={() => {
            setIsFieldTouchedArr(isFieldTouchedArr.map(() => true));

            if (hasAtLeastOneError(errorMessages)) {
              return;
            }

            dispatch(
              addingAction
                ? addAction({
                    ...addingAction,
                    data: {
                      ...addingAction.data,
                      configuration_fields: customizationData,
                    },
                  })
                : updateActionSettings({
                    action_type: canvasState.actionSettings
                      .action_type as string,
                    configuration_fields:
                      cleanCustomizationData(customizationData),
                  }),
            );
            dispatchActionEditedTrackingEvent({
              actionType: ActionBuilderActionTypes.ZENDESK_TICKET_CREATION,
            });
            setActionPanelVisibilityParameters('hidden');
            setAreActionSettingsUnsaved(false);
            dispatch(setCustomizableActionId(''));
            onDismiss();
          }}
        >
          Save
        </StyledButton>
      </SaveContainer>
    </>
  );
};

export default ZendeskTicketCreationCustomizationPanel;

const SaveContainer = styled('div')<{ hasTopBorder?: boolean }>`
  border-bottom: 8px;
  border-top: ${props =>
    props.hasTopBorder
      ? `1px solid ${theme.palette.colors.slate[200]}`
      : 'none'};
  width: 100%;
  height: 40px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
