import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { Box, styled } from '@mui/material';

import {
  theme,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { quickFeedbackOneToTwoOptions } from '../constants';
import {
  AgentMessage,
  AgentMessageContainer,
  CustomImage,
  WidgetContentContainer,
} from '../styledComponents';
import WidgetPrimaryInstruction from '../widget-primary-instruction/WidgetPrimaryInstruction';
import agentChatImageDefaultIcon from 'src/assets/images/agent-chat-image-default.svg';
import {
  selectLegibleTextColor,
  selectWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';

export const QuickFeedbackWidgetContent = () => {
  const { agent_chat_image, theme_color } = useSelector(
    selectWidgetConfiguration,
  );
  const fontColor = useSelector(selectLegibleTextColor);
  const widgetConfiguration = useSelector(selectWidgetConfiguration);

  const {
    feedback_prompt: feedbackPrompt,
    hover_label_bad: hoverLabelBad,
    hover_label_good: hoverLabelGood,
    is_enabled: isEnabled,
    style,
  } = widgetConfiguration.quick_feedback_config;

  const chosenStyleOptions = quickFeedbackOneToTwoOptions.find(
    option => option.value === style,
  );

  return (
    <WidgetContentContainer>
      <InstructionComponentWrapper className='plus-jakarta-sans'>
        <WidgetPrimaryInstruction>
          <Typography variant='font16'>
            Personalize your quick feedback using the menu on the right
          </Typography>
        </WidgetPrimaryInstruction>
        <Typography color={theme.palette.colors.grey[400]} variant='font16'>
          Check the preview for the correct resolution
        </Typography>
      </InstructionComponentWrapper>
      <ChatContainer>
        <CustomerMessage backgroundColor={theme_color}>
          <Typography color={fontColor} variant='font16'>
            I have some issues with my account
          </Typography>
        </CustomerMessage>
        <AgentMessageContainer>
          <AgentMessage sx={{ marginLeft: '24px' }}>
            <Box sx={{ padding: '10px 0px' }}>
              <Typography variant='font16'>
                It sounds like you&apos;re experiencing some issues with your
                account. I&apos;m sorry to hear that.
              </Typography>
            </Box>
          </AgentMessage>
        </AgentMessageContainer>
        <AgentMessageContainer>
          <CustomImage
            alt=''
            src={
              agent_chat_image ? agent_chat_image : agentChatImageDefaultIcon
            }
          />
          <AgentMessage>
            <Box sx={{ padding: '10px 0px' }}>
              <Typography variant='font16'>
                This article <ChatLink>Account issue</ChatLink> covers common
                account-related problems and provides step-by-step solutions to
                help you resolve them. From forgotten passwords to billing
                inquiries, we&apos;ve got you covered.
              </Typography>
            </Box>
          </AgentMessage>
        </AgentMessageContainer>
        {isEnabled ? (
          <AgentMessageContainer>
            <Box marginLeft='40px'>
              <Typography
                color={theme.palette.colors.grey[700]}
                variant='font14'
              >
                {feedbackPrompt}
              </Typography>
            </Box>
            <Box
              aria-label={hoverLabelGood}
              sx={{ cursor: 'pointer' }}
              width='24px'
            >
              <Tooltip placement='bottom' tooltipContent={hoverLabelGood}>
                <Box>
                  <SVGIcon
                    src={chosenStyleOptions?.positiveIcons?.default || ''}
                  />
                </Box>
              </Tooltip>
            </Box>
            <Box
              aria-label={hoverLabelBad}
              sx={{ cursor: 'pointer' }}
              width='24px'
            >
              <Tooltip placement='bottom' tooltipContent={hoverLabelBad}>
                <Box>
                  <SVGIcon
                    src={chosenStyleOptions?.negativeIcons?.default || ''}
                  />
                </Box>
              </Tooltip>
            </Box>
          </AgentMessageContainer>
        ) : null}
      </ChatContainer>
    </WidgetContentContainer>
  );
};

const CustomerMessage = styled('div', {
  shouldForwardProp: propName => propName !== 'backgroundColor',
})<{ backgroundColor: string }>`
  align-self: flex-end;
  background-color: ${props => props.backgroundColor};
  border-radius: 24px;
  padding: 14px 16px;
`;

const ChatContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ChatLink = styled('span')`
  color: ${props => props.theme.palette.colors.blue[600]};
  text-decoration: underline;
`;

const SVGIcon = styled(ReactSVG)`
  div {
    svg {
      height: 24px;
      width: 24px;

      path {
        :hover {
          stroke: ${props => props.theme.palette.colors.black};
          stroke-width: 2px;
          stroke-linejoin: round;
        }
      }
    }
  }

  :hover {
    div {
      svg {
        path {
          stroke: ${props => props.theme.palette.colors.black};
          stroke-width: 2px;
          stroke-linejoin: round;
        }
      }
    }
  }
`;

const InstructionComponentWrapper = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 32px 32px 0px 32px;
`;
