import React from 'react';
import { useSelector } from 'react-redux';
import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { GREETING_LINE_PLACEHOLDER } from '../constants';
import { useGetIntents } from '../hooks/useGetIntents';
import { useGetRemirrorContextVariables } from '../hooks/useGetRemirrorContextVariables';
import IntentButton from '../intent-button/IntentButton';
import {
  AgentMessage,
  AgentMessageContainer,
  ChatContainer,
  CustomImage,
  ResponseMessage,
} from '../styledComponents';
import { handleIntentNames } from '../utils';
import BannerImage from './BannerImage';
import { getIntentOptionType } from './helper';
import { Intro } from './Intro';
import agentChatImageDefaultIcon from 'src/assets/images/agent-chat-image-default.svg';
import HtmlComponent from 'src/components/html-component/HtmlComponent';
import { solveLinkifyHtmlOptions } from 'src/components/html-component/solveLinkifyHtmlOptions';
import { replaceContextVariableIdsWithMarkup } from 'src/components/rich-text-editor/helpers/replaceContextVariableIdsWithMarkup';
import useStrippedHTML from 'src/hooks/useStrippedHTML';
import { selectIsSolveLiteEnabled } from 'src/reducers/userReducer/userReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  selectIsSaving,
  selectLegibleTextColor,
  selectWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';

type ThemeWidgetContentProps = {
  includeBanner?: boolean;
  includeIntro?: boolean;
  primaryInstruction?: string;
  secondaryInstruction?: string;
  spaceBetween?: boolean;
  WidgetContent?: React.FC;
  wrapText?: boolean;
};

const ThemeWidgetContent: React.FC<
  React.PropsWithChildren<ThemeWidgetContentProps>
> = ({
  includeBanner = false,
  includeIntro = false,
  primaryInstruction = 'Personalize your chat by using the menu on the right.',
  secondaryInstruction,
  spaceBetween = true,
  WidgetContent = RegularWidgetContent,
  wrapText = false,
}) => {
  const theme = useTheme();
  const { banner_image_config } = useSelector(selectWidgetConfiguration);

  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  const showBanner = includeBanner && featureFlags.includes('banner_image');

  return (
    <>
      {showBanner && <BannerImage config={banner_image_config} />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: '32px',
          justifyContent: spaceBetween ? 'space-between' : undefined,
        }}
      >
        {includeIntro && <Intro />}
        <InstructionalTextWrapper className='plus-jakarta-sans'>
          <Box
            sx={{
              background:
                'linear-gradient(270deg, #BD00FF 5.08%, #3866F5 94.92%)',
              fontStyle: 'italic',
              textAlign: 'center',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              whiteSpace: wrapText ? 'pre-wrap' : undefined,
            }}
          >
            <Typography variant='font16Medium'>{primaryInstruction}</Typography>
          </Box>
          {secondaryInstruction && (
            <Typography
              color={theme.palette.colors.grey[400]}
              variant='font16Medium'
            >
              {secondaryInstruction}
            </Typography>
          )}
        </InstructionalTextWrapper>
        <WidgetContent />
      </Box>
    </>
  );
};

const RegularWidgetContent = () => {
  const {
    agent_chat_image,
    greeting_line: greetingLine,
    manually_selected_top_intents: manuallySelectedTopIntents,
    num_top_intents: numTopIntents,
    theme_color,
    top_n_intents: topNIntents,
  } = useSelector(selectWidgetConfiguration);
  const intents = useGetIntents({ isActiveUserCreatedWorkflows: true });

  const fontColor = useSelector(selectLegibleTextColor);
  const isSaving = useSelector(selectIsSaving);
  const isSolveLiteEnabled = useSelector(selectIsSolveLiteEnabled);

  const intentType = getIntentOptionType(
    manuallySelectedTopIntents,
    numTopIntents,
  );

  const remirrorContextVariables = useGetRemirrorContextVariables();
  const intentNames = handleIntentNames({
    intents,
    intentType,
    isSaving,
    manuallySelectedTopIntents,
    numTopIntents,
    topNIntents,
  });

  const isGreetingLinePlainText =
    useStrippedHTML(greetingLine) === greetingLine;

  return (
    <ChatContainer>
      <AgentMessageContainer>
        <CustomImage
          alt=''
          src={agent_chat_image ? agent_chat_image : agentChatImageDefaultIcon}
        />
        <AgentMessage>
          <Typography variant='font16Medium'>
            <Box
              sx={{
                marginBlockEnd: isGreetingLinePlainText ? '1em' : 'initial',
                marginBlockStart: isGreetingLinePlainText ? '1em' : 'initial',
              }}
            >
              <HtmlComponent
                content={replaceContextVariableIdsWithMarkup(
                  greetingLine || `<p>${GREETING_LINE_PLACEHOLDER}</p>`,
                  remirrorContextVariables,
                )}
                linkifyHtmlOptions={solveLinkifyHtmlOptions}
              />
            </Box>
          </Typography>
        </AgentMessage>
      </AgentMessageContainer>
      {!isSolveLiteEnabled && (
        <Box mb='-8px' textAlign='right'>
          {intentNames.map(intentName => (
            <IntentButton
              intentName={intentName}
              isProactiveBubble={false}
              key={intentName}
            />
          ))}
        </Box>
      )}
      <ResponseMessage backgroundColor={theme_color}>
        <Typography color={fontColor} variant='font16Medium'>
          I have some issues with my account
        </Typography>
      </ResponseMessage>
    </ChatContainer>
  );
};

export default ThemeWidgetContent;

const InstructionalTextWrapper = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 30px 90px 0px 90px;
`;
