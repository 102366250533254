import React, { useMemo, useState } from 'react';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  ListOption,
  TextField,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  SelectDropdown,
  theme,
} from '@forethought-technologies/forethought-elements';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import {
  ALLOWED_DISPLAY_NAME_CHARACTER_REGEX,
  ALLOWED_DISPLAY_NAME_LENGTH,
} from 'src/pages/action-builder/context-variables-settings/constants';
import { useCreateContextVariableMutation } from 'src/services/action-builder/actionBuilderApi';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';
import { ContextVariableTypes } from 'src/utils/enums';
import { flattenDynamicContextVariables } from 'src/utils/solve/dynamicContextVariableUtils';

interface ContextVariableListOption extends ListOption {
  value: keyof typeof ContextVariableTypes;
}

interface ContextVariableSelectDropdownProps {
  additionalOptions?: (ListOption & { category: string })[];
  'aria-label'?: string;
  contextVariableTypeOptions?: ContextVariableListOption[];
  disabled?: boolean;
  disabledContextVariableTypes?: ContextVariable['context_variable_type'][];
  error?: string | boolean;
  filterFn?: (cv: ContextVariable) => boolean;
  id?: string;
  isClearable?: boolean;
  label?: string;
  onChange: (cv: string) => void;
  placeholder?: string;
  shouldErrorMessageTakeUpSpace?: boolean;
  shouldIncludeDynamicListContextVariableChildren?: boolean;
  shouldIncludeDynamicListContextVariables?: boolean;
  shouldIncludeSystemContextVariables?: boolean;
  shouldIncludeTemplateContextVariables?: boolean;
  shouldIncludeWorkflowTagContextVariable?: boolean;
  shouldOverrideDisabledContextVariableSelection?: boolean;
  shouldProvideCVIdFormatting?: boolean;
  undefinedContextVariables?: string[];
  value?: string;
}

const ContextVariableSelectDropdown = ({
  additionalOptions = [],
  contextVariableTypeOptions,
  disabled = false,
  disabledContextVariableTypes = [],
  error = false,
  filterFn,
  id,
  label,
  onChange,
  placeholder = 'Select Context Variable',
  shouldErrorMessageTakeUpSpace,
  shouldIncludeDynamicListContextVariableChildren = true,
  shouldIncludeDynamicListContextVariables = true,
  shouldIncludeSystemContextVariables = false,
  shouldIncludeTemplateContextVariables = false,
  shouldIncludeWorkflowTagContextVariable = true,
  shouldOverrideDisabledContextVariableSelection = false,
  shouldProvideCVIdFormatting = false,
  undefinedContextVariables = [],
  value = '',
  ...props
}: ContextVariableSelectDropdownProps) => {
  const { palette } = useTheme();
  const [createContextVariable] = useCreateContextVariableMutation();
  const { contextVariables } = useGetContextVariables({
    filterFn,
    shouldIncludeDynamicListContextVariables,
    shouldIncludeSystemContextVariables,
    shouldIncludeTemplateContextVariables,
    shouldIncludeWorkflowTagContextVariable,
  });

  const flattenedContextVariables = useMemo(() => {
    return flattenDynamicContextVariables(
      contextVariables,
      shouldIncludeDynamicListContextVariableChildren,
    );
  }, [contextVariables, shouldIncludeDynamicListContextVariableChildren]);

  const [isCreateMode, setIsCreateMode] = useState(false);

  const handleReset = () => {
    setIsCreateMode(false);
  };

  const undefinedCvSet = new Set(undefinedContextVariables);

  const labelToRender =
    !label && props['aria-label'] ? '' : label ?? 'Context Variable';

  const onSave = async (
    cvName: string,
    cvType: keyof typeof ContextVariableTypes,
  ) => {
    const res = await createContextVariable({
      context_variable_name: cvName,
      context_variable_type: cvType,
    });

    if (!('data' in res)) {
      console.error('Cv update failed');
      return;
    }

    onChange(res.data.context_variable_id);
    handleReset();
  };

  if (!disabled && shouldIncludeTemplateContextVariables) {
    throw new Error('Cannot use template CV in live input');
  }

  return (
    <Box sx={{ backgroundColor: theme.palette.colors.white }}>
      <SelectDropdown
        addNewButtonText={isCreateMode ? '' : 'Create new Context Variable'}
        aria-label={props['aria-label']}
        disabled={disabled}
        error={error}
        id={id || 'context-variables-dropdown'}
        isMenuSearchable
        key={contextVariables.length}
        label={labelToRender}
        menuMaxHeight={450}
        onAddNewClick={
          isCreateMode || additionalOptions.length
            ? undefined
            : (e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                setIsCreateMode(true);
              }
        }
        onChange={e => {
          onChange(e?.target.value ?? '');
          handleReset();
        }}
        onClear={
          props.isClearable
            ? () => {
                onChange('');
                handleReset();
              }
            : undefined
        }
        options={[
          ...additionalOptions,
          ...flattenedContextVariables.map(
            ({ disabled, fullLabel, id, indent, label, type }) => ({
              category: additionalOptions.length
                ? 'Context Variables'
                : undefined,
              color: undefinedCvSet.has(id)
                ? palette.colors.red[500]
                : palette.colors.purple[500],
              disabled:
                (!shouldOverrideDisabledContextVariableSelection &&
                  disabled &&
                  shouldIncludeDynamicListContextVariableChildren) ||
                disabledContextVariableTypes?.includes(type),
              indent,
              label: '$' + (fullLabel ?? label),
              value: shouldProvideCVIdFormatting ? `{{${id}}}` : id,
            }),
          ),
        ]}
        placeholder={placeholder}
        shouldErrorMessageTakeUpSpace={shouldErrorMessageTakeUpSpace}
        topMenuChildren={
          isCreateMode ? (
            <ContextVariableMenuForm
              contextVariableTypeOptions={contextVariableTypeOptions}
              onClose={handleReset}
              onSave={onSave}
            />
          ) : null
        }
        value={value}
      />
    </Box>
  );
};

interface ContextVariableMenuFormProps {
  contextVariableTypeOptions?: ContextVariableListOption[];
  onClose: () => void;
  onSave: (
    newCvName: string,
    newCvType: keyof typeof ContextVariableTypes,
  ) => void;
}

const getContextVariableTypesOptions = (featureFlags: string[] = []) => {
  return Object.keys(ContextVariableTypes)
    .map(type => ({
      disabled:
        type === 'DYNAMIC_LIST' ||
        type === 'MULTIOPTIONS' ||
        type === 'LIST' ||
        type === 'MULTI_SELECT_LIST',
      label: ContextVariableTypes[type as keyof typeof ContextVariableTypes],
      value: type,
    }))
    .filter(
      type =>
        type.label !== ContextVariableTypes.MULTIOPTIONS &&
        (type.label !== ContextVariableTypes.FLAT_LIST ||
          featureFlags.includes('attachment_analysis_upload_step')),
    );
};
export const ContextVariableMenuForm = ({
  contextVariableTypeOptions,
  onClose,
  onSave,
}: ContextVariableMenuFormProps) => {
  const { palette } = useTheme();

  const [newCvName, setNewCvName] = useState('');
  const [newCvType, setNewCvType] = useState<keyof typeof ContextVariableTypes>(
    contextVariableTypeOptions?.[0]?.value ?? 'SHORT_TEXT',
  );

  const { contextVariables } = useGetContextVariables();
  const { data } = useGetFeatureFlagsQuery();

  const getErrorMessage = () => {
    if (newCvName.length > ALLOWED_DISPLAY_NAME_LENGTH) {
      return `Display names must be ${ALLOWED_DISPLAY_NAME_LENGTH} characters or less`;
    } else if (ALLOWED_DISPLAY_NAME_CHARACTER_REGEX.test(newCvName)) {
      return 'Display names must not use special characters';
    } else if (
      contextVariables.some(
        cv => cv.context_variable_name.trim() === newCvName.trim(),
      )
    ) {
      return 'Display names must be unique';
    } else if (newCvName === '') {
      return 'Display names must be greater than 1 character';
    }
    return false;
  };

  const hasDisplayNameErrors = getErrorMessage();

  const clearForm = () => {
    setNewCvName('');
    setNewCvType('SHORT_TEXT');
  };

  const handleSave = () => {
    onSave(newCvName, newCvType);
    clearForm();
    onClose();
  };

  return (
    <Box
      borderBottom={`1px solid ${palette.colors.slate[200]}`}
      borderTop={`1px solid ${palette.colors.slate[200]}`}
      padding='20px 16px 18px 16px'
    >
      <Box pb='17px'>
        <Typography variant='font14Bold'>
          Connect a new Context Variable
        </Typography>
      </Box>
      <Box pb='12px'>
        <TextField
          aria-label='CV name'
          error={hasDisplayNameErrors}
          id='cv-name-field'
          onChange={e => {
            const { value } = e.target;
            setNewCvName(value);
          }}
          placeholder='Name the variable'
          value={newCvName}
        />
      </Box>
      <Box pb='16px'>
        <SelectDropdown
          id={'cv-type-selector'}
          onChange={e => {
            const { value } = e.target;
            setNewCvType(value as keyof typeof ContextVariableTypes);
          }}
          options={
            contextVariableTypeOptions ??
            getContextVariableTypesOptions(data?.feature_flags)
          }
          placeholder='Select type'
          value={newCvType}
        />
      </Box>
      <Box alignItems='center' display='flex' gap='10px'>
        <Button
          id='cv-cancel-create'
          onClick={() => {
            clearForm();
            onClose();
          }}
          variant='secondary'
        >
          Cancel
        </Button>
        <Button
          disabled={Boolean(hasDisplayNameErrors)}
          id='cv-save'
          onClick={handleSave}
          variant='main'
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default ContextVariableSelectDropdown;
