import { useState } from 'react';
import { useLocation } from 'react-router';
import { ThemeProvider } from '@emotion/react';
import { Box } from '@mui/material';
import { IconFilterPlus } from '@tabler/icons-react';

import {
  BaseFilterButton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  useGetIsTemplateIntentWorkflow,
  useGetTemplateAutoflowWorkflow,
} from '../workflow-builder-edit/hooks';
import { IntentFilterModal } from './intent-filter-modal/IntentFilterModal';
import {
  getTemplateIntentWorkflowId,
  useGetDefaultIntentFilters,
  useGetIntentFiltersMode,
  useGetLightTheme,
  useGetThemeBasedOnMode,
} from './utils';

export const IntentFiltersButton = ({
  disableUpdateFilter = false,
  hideModeColumn = false,
}: {
  disableUpdateFilter?: boolean;
  hideModeColumn?: boolean;
}) => {
  const lightTheme = useGetLightTheme();
  const location = useLocation();
  const theme = useGetThemeBasedOnMode();

  const isTemplate = useGetIsTemplateIntentWorkflow();

  const { defaultIntentFiltersMapping } = useGetDefaultIntentFilters();
  const intentFiltersMode = useGetIntentFiltersMode();

  const params = new URLSearchParams(location.search);
  const previewFromIntentId = params.get('intentId');
  const { template } = useGetTemplateAutoflowWorkflow(
    getTemplateIntentWorkflowId(previewFromIntentId ?? ''),
  );

  const [isIntentFilterDialogOpen, setIsIntentFilterDialogOpen] =
    useState(false);
  const isDarkMode = theme.palette.mode === 'dark';

  const getIntentFiltersModeMessage = () => {
    if (isTemplate && template) {
      return template.name + ' template';
    }

    if (intentFiltersMode === 'live-traffic') {
      return 'Currently previewing all ‘Live’ traffic';
    }
    if (intentFiltersMode === 'current-intent-and-live-traffic') {
      const intentName =
        defaultIntentFiltersMapping[previewFromIntentId || '']?.intentName ||
        'workflow name';
      return `Currently previewing the draft version of the '${intentName}' and all other 'Live' traffic`;
    }

    return 'Currently previewing a custom selection of intents';
  };

  return (
    <Box display='flex' flexDirection='column' gap='16px'>
      <Typography
        color={
          isDarkMode ? theme.palette.grey[100] : theme.palette.colors.grey[700]
        }
        variant='font12'
      >
        {getIntentFiltersModeMessage()}
      </Typography>
      <BaseFilterButton
        aria-label='choose intent filter'
        isOpen={false}
        onClick={() => {
          setIsIntentFilterDialogOpen(prev => !prev);
        }}
        showCaret={intentFiltersMode !== 'customized'}
        size='workflow-builder-preview'
        startAdornment={
          <IconFilterPlus
            color={
              isDarkMode
                ? theme.palette.grey[500]
                : theme.palette.colors.grey[700]
            }
            size={20}
          />
        }
        value={
          intentFiltersMode === 'customized'
            ? 'Custom intents selected'
            : 'Select intents to preview'
        }
      />
      <ThemeProvider theme={lightTheme}>
        <IntentFilterModal
          disableUpdateFilter={disableUpdateFilter}
          hideModeColumn={hideModeColumn}
          isOpen={isIntentFilterDialogOpen}
          setIsOpen={setIsIntentFilterDialogOpen}
        />
      </ThemeProvider>
    </Box>
  );
};
