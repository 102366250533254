import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { IconX } from '@tabler/icons-react';

import { useGetIntents } from '../hooks/useGetIntents';
import { useGetRemirrorContextVariables } from '../hooks/useGetRemirrorContextVariables';
import IntentButton from '../intent-button/IntentButton';
import { getIntentOptionType } from '../theme-tab-content/helper';
import { handleIntentNames } from '../utils';
import { LaunchButton } from './LaunchButton';
import HtmlComponent from 'src/components/html-component/HtmlComponent';
import { solveLinkifyHtmlOptions } from 'src/components/html-component/solveLinkifyHtmlOptions';
import { replaceContextVariableIdsWithMarkup } from 'src/components/rich-text-editor/helpers/replaceContextVariableIdsWithMarkup';
import {
  selectIsSaving,
  selectWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';
import { WidgetConfiguration } from 'src/slices/solve-config/types';

export const LaunchButtonWithProactivePrompt = () => {
  const {
    display_proactive_chat: displayProactiveChat,
    display_proactive_chat_intents: displayProactiveChatIntents,
    manually_selected_top_intents: manuallySelectedTopIntents,
    num_top_intents: numTopIntents,
    placement,
    proactive_chat_greeting: proactiveChatGreeting,
    top_n_intents: topNIntents,
  } = useSelector(selectWidgetConfiguration);
  const intents = useGetIntents({ isActiveUserCreatedWorkflows: true });
  const isSaving = useSelector(selectIsSaving);

  const intentType = getIntentOptionType(
    manuallySelectedTopIntents,
    numTopIntents,
  );

  const remirrorContextVariables = useGetRemirrorContextVariables();
  const intentNames = handleIntentNames({
    intents,
    intentType,
    isSaving,
    manuallySelectedTopIntents,
    numTopIntents,
    topNIntents,
  });

  return (
    <LaunchButtonContainer placement={placement}>
      {displayProactiveChat && proactiveChatGreeting && (
        <ProactivePromptContainer placement={placement}>
          <CloseButton>
            <IconX size={18} />
          </CloseButton>
          <GreetingMessage placement={placement}>
            <HtmlComponent
              content={replaceContextVariableIdsWithMarkup(
                proactiveChatGreeting,
                remirrorContextVariables,
              )}
              linkifyHtmlOptions={solveLinkifyHtmlOptions}
            />
          </GreetingMessage>
        </ProactivePromptContainer>
      )}
      {displayProactiveChat && displayProactiveChatIntents && (
        <IntentsContainer placement={placement}>
          {intentNames.map(intentName => (
            <IntentButton intentName={intentName} key={intentName} />
          ))}
        </IntentsContainer>
      )}
      <StyledLaunchButton placement={placement} />
    </LaunchButtonContainer>
  );
};

const LaunchButtonContainer = styled('div')<{
  placement: WidgetConfiguration['placement'];
}>`
  align-self: ${props =>
    props.placement === 'bottom_left' ? 'flex-start' : 'flex-end'};
`;

const StyledLaunchButton = styled(LaunchButton)<{
  placement: WidgetConfiguration['placement'];
}>`
  ${props =>
    props.placement === 'bottom_left'
      ? 'margin-right: auto;'
      : 'margin-left: auto;'}
  display: flex;
`;

const ProactivePromptContainer = styled('div')<{
  placement: WidgetConfiguration['placement'];
}>`
  display: flex;
  flex-direction: column;
  align-items: ${props =>
    props.placement === 'bottom_left' ? 'flex-start' : 'flex-end'};
  margin-bottom: 16px;
`;

const CloseButton = styled('div')`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${props => props.theme.palette.background.default};
  box-shadow: 0px 2px 10px 0px #00000040;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  background-repeat: no-repeat;
  background-position: center center;
`;

const GreetingMessage = styled('div')<{
  placement: WidgetConfiguration['placement'];
}>`
  background-color: ${props => props.theme.palette.background.default};
  border-radius: ${props =>
    props.placement === 'bottom_left'
      ? '25px 25px 25px 8px'
      : '25px 25px 8px 25px'};
  padding: 14px 16px;
  box-shadow: 0px 2px 10px 0px #00000040;
  word-break: break-word;

  max-width: 280px;

  line-height: 20px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

const IntentsContainer = styled('div')<{
  placement: WidgetConfiguration['placement'];
}>`
  display: flex;
  flex-direction: column;
  align-items: ${props =>
    props.placement === 'bottom_left' ? 'flex-start' : 'flex-end'};
  padding-bottom: 8px;

  :not(:empty) {
    margin-top: 16px;
  }
`;
