import ReactMarkdown from 'react-markdown';
import { styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconArrowUp } from '@tabler/icons-react';

import {
  Badge,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { HEADLINE_REGEX, INSTRUCTION_REGEX } from '../../constants';
import { verifyValues } from './helpers';
import RelatedArticlesSection from './RelatedArticlesSection';
import SummarySection from './SummarySection';
import { formatDateForTicketCard } from 'src/components/dashboard-pages/discover-topic-detail-page/helpers';
import { NA } from 'src/constants/solve';
import { KnowledgeGapArticleRecommendation } from 'src/reducers/discoverReducer/types';

interface GeneratedArticleProps {
  article: KnowledgeGapArticleRecommendation;
  isBrandsEnabled?: boolean;
}

const GeneratedArticle = ({
  article,
  isBrandsEnabled,
}: GeneratedArticleProps) => {
  const { palette } = useTheme();

  const isArticlePublished = article?.state.includes('published');

  const articleBody = article
    ? article.generated_body.trim().replace(INSTRUCTION_REGEX, '')
    : '';

  const articleTitle = article
    ? article.generated_title.replace(HEADLINE_REGEX, '')
    : '';

  const hasSummaryAndArticles = verifyValues(article);

  return (
    <Box display='flex' flexDirection='column' rowGap={4}>
      <Box display='flex' flexDirection='column' rowGap={1}>
        <Box columnGap={1} display='grid' gridTemplateColumns='70px 400px'>
          <Typography color={palette.colors.grey[600]} noWrap variant='font12'>
            Article title:
          </Typography>
          <Typography variant='font12'>{article.generated_title}</Typography>
        </Box>
        {isBrandsEnabled && (
          <Box columnGap={1} display='grid' gridTemplateColumns='70px 400px'>
            <Typography
              color={palette.colors.grey[600]}
              noWrap
              variant='font12'
            >
              Brand:
            </Typography>
            <Typography
              color={
                !article.api_data_filter ? palette.colors.grey[600] : undefined
              }
              variant='font12'
            >
              {article.api_data_filter || NA}
            </Typography>
          </Box>
        )}
        <Box
          alignItems='center'
          columnGap={1}
          display='grid'
          gridTemplateColumns='70px auto'
        >
          <Typography color={palette.colors.grey[600]} variant='font12'>
            Generated:
          </Typography>
          <Typography variant='font12'>
            {formatDateForTicketCard(
              new Date(article.article_generated_at).getTime(),
            )}
          </Typography>
        </Box>
      </Box>
      {hasSummaryAndArticles && (
        <Box display='flex' flexDirection='column' rowGap={1}>
          <SummarySection article={article} />
          <RelatedArticlesSection
            articles={
              (article as KnowledgeGapArticleRecommendation).relevant_articles
            }
          />
        </Box>
      )}
      <Box display='flex' flexDirection='column' rowGap={1}>
        <Box
          alignItems='center'
          display='flex'
          height='40px'
          justifyContent='space-between'
        >
          <Typography variant='font11'>Generated Article</Typography>
        </Box>
        {isArticlePublished ? (
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
          >
            <Badge
              label={
                <Box alignItems='center' display='flex'>
                  <IconArrowUp size={12} />
                  Uploaded
                </Box>
              }
              variant='success'
            />
          </Box>
        ) : null}
        <Typography variant='font32'>{articleTitle}</Typography>
        <Typography variant='font16'>
          <ArticleDisplay>
            <ReactMarkdown>{articleBody}</ReactMarkdown>
          </ArticleDisplay>
        </Typography>
      </Box>
    </Box>
  );
};

const ArticleDisplay = styled('div')`
  > ol:first-child {
    margin-top: 0;
    margin-block-start: 0;
  }
  > ol {
    padding-inline-start: 20px;
  }
`;

export default GeneratedArticle;
