import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@forethought-technologies/forethought-elements';
import { ContextVariable } from 'src/types/actionBuilderApiTypes';

interface VariableBoxProps {
  color?: string;
  contextVariables: ContextVariable[];
  label: string;
  undefinedContextVariableIds?: string[];
}

const VariableBox = ({
  color,
  contextVariables,
  label,
  undefinedContextVariableIds = [],
}: VariableBoxProps) => {
  const { palette } = useTheme();

  if (!contextVariables.length) {
    return null;
  }

  return (
    <>
      <Typography color={palette.colors.grey[600]} variant='font11'>
        {label}
      </Typography>
      <Box
        fontFamily='Fira Code !important'
        fontSize='12px'
        fontWeight='450'
        lineHeight='18px'
      >
        {contextVariables.map((cv, index) => (
          <Typography
            color={
              undefinedContextVariableIds.includes(cv.context_variable_id)
                ? palette.error.main
                : color
            }
            key={cv.context_variable_id}
            variant='font12'
          >
            {cv.context_variable_name}
            {index !== contextVariables.length - 1 ? ', ' : ''}
          </Typography>
        ))}
      </Box>
    </>
  );
};

export default VariableBox;
