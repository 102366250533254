import { useCallback, useMemo, useRef, useState } from 'react';
import { Box, useTheme } from '@mui/material';

import { useSolveMetricsQueryParams } from '../intent-workflows-table/hooks/useSolveMetricsQueryParams';
import { TemplateTab } from '../template-tab';
import { LandingPage } from './LandingPage';
import capitalize from 'lodash/fp/capitalize';
import { URLParamTabs } from 'src/components/url-param-tabs/URLParamTabs';
import { supportedSubChannelsMap } from 'src/constants/solve';
import { EmailConversationsTab } from 'src/pages/intent-conversation-analytics/EmailConversationsTab';
import { WidgetConversationsTab } from 'src/pages/intent-conversation-analytics/WidgetConversationsTab';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { useGetOrgConfigQuery } from 'src/services/settings/settingsApi';

const ParentLandingPage = () => {
  const { palette } = useTheme();
  const { data: featureFlagResponse, isLoading } = useGetFeatureFlagsQuery();
  const { data: orgConfigData } = useGetOrgConfigQuery();
  const featureFlags = featureFlagResponse?.feature_flags || [];
  const isSolveEmailEnabled = featureFlags.includes('solve_email_enabled');
  const isSolveWidgetEnabled = featureFlags.includes('solve_widget_enabled');
  const isSolveApiEnabled = featureFlags.includes('solve_api');
  const isSolveSlackEnabled = featureFlags.includes('solve_slack');
  const isSolveVoiceEnabled = featureFlags.includes('solve_voice');
  const isAssistGptEnabled = orgConfigData?.is_assist_gpt_enabled;

  const { headerChannel } = useSolveMetricsQueryParams();
  const containerRef = useRef<HTMLDivElement>();
  const [scrollTop, setScrollTop] = useState(0);

  const channelTabs = useMemo(() => {
    const supportedChannelsBySubChannel =
      supportedSubChannelsMap[headerChannel];

    return supportedChannelsBySubChannel
      .filter(channel => {
        if (channel === 'widget' && !isSolveWidgetEnabled) {
          return false;
        }
        if (channel === 'email' && !isSolveEmailEnabled) {
          return false;
        }
        if (channel === 'api' && !isSolveApiEnabled) {
          return false;
        }
        if (channel === 'slack' && !isSolveSlackEnabled) {
          return false;
        }
        if (channel === 'voice' && !isSolveVoiceEnabled) {
          return false;
        }

        if (channel === 'playbook' && !isAssistGptEnabled) {
          return false;
        }

        return true;
      })
      .map(channel => (channel === 'api' ? 'API' : capitalize(channel)));
  }, [
    headerChannel,
    isSolveWidgetEnabled,
    isSolveEmailEnabled,
    isSolveApiEnabled,
    isSolveSlackEnabled,
    isSolveVoiceEnabled,
    isAssistGptEnabled,
  ]);

  const scrollToTop = useCallback((yOffset: number, smooth = false) => {
    if (!containerRef.current) {
      return;
    }
    if (containerRef.current.scrollTop > yOffset) {
      containerRef.current.scrollTo({
        behavior: smooth ? 'smooth' : 'auto',
        top: yOffset,
      });
    }
  }, []);

  const page = useMemo(() => {
    if (isLoading) {
      return null;
    }

    if (headerChannel === 'conversations') {
      return (
        <EmailConversationsTab
          scrollTop={scrollTop}
          scrollToTop={scrollToTop}
        />
      );
    }

    switch (headerChannel) {
      case 'chats':
        return (
          <WidgetConversationsTab
            scrollTop={scrollTop}
            scrollToTop={scrollToTop}
          />
        );
      case 'templates':
        return <TemplateTab />;
      default:
        return <LandingPage />;
    }
  }, [headerChannel, scrollTop, scrollToTop, isLoading]);

  return (
    <Box
      sx={{
        bgcolor: palette.colors.white,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      {channelTabs.length > 1 && (
        <Box m='0 40px'>
          <URLParamTabs
            ariaLabel='solve channel tabs'
            paramName='channel'
            tabs={channelTabs}
          />
        </Box>
      )}
      <Box
        flex='1'
        onScroll={e => {
          setScrollTop(e.currentTarget.scrollTop);
        }}
        overflow='auto'
        ref={containerRef}
      >
        {page}
      </Box>
    </Box>
  );
};

export default ParentLandingPage;
