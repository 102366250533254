import { Fragment, ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetSelectedBrand } from '../hooks';
import ExpandedWidget from './ExpandedWidget';
import MinimizedWidget from './MinimizedWidget';
import { WidgetBrandTagLabels } from './WidgetBrandTagLabels';
import { useIsFeatureFlagEnabled } from 'src/hooks/hooks';

type StaticSolveWidgetType = 'expanded' | 'minimized';

interface StaticSolveWidgetProps {
  children?: ReactNode;
  type?: StaticSolveWidgetType;
}

const StaticSolveWidget = ({
  children,
  type = 'expanded',
}: StaticSolveWidgetProps) => {
  const [params] = useSearchParams();
  const tab = params.get('tab');
  const subTab = params.get('sub-tab') || '';

  const ExpandedWidgetWrapper =
    tab === 'theme' && (subTab === 'theme' || subTab === '')
      ? MinimizedWidget
      : Fragment;

  if (type === 'minimized') {
    return (
      <StaticSolveWidgetContainer>
        <MinimizedWidget>{children}</MinimizedWidget>
      </StaticSolveWidgetContainer>
    );
  }

  return (
    <StaticSolveWidgetContainer>
      <ExpandedWidgetWrapper>
        <ExpandedWidget>{children}</ExpandedWidget>
      </ExpandedWidgetWrapper>
    </StaticSolveWidgetContainer>
  );
};

const StaticSolveWidgetContainer = ({ children }: { children: ReactNode }) => {
  const isMultibrandEnabled = useIsFeatureFlagEnabled('multibrand_enabled');
  const selectedBrand = useGetSelectedBrand();
  const [params] = useSearchParams();

  if (
    selectedBrand &&
    isMultibrandEnabled &&
    params.get('channel') === 'widget'
  ) {
    return (
      <>
        <WidgetBrandTagLabels brand={selectedBrand} />
        {children}
      </>
    );
  }

  return <>{children}</>;
};

export default StaticSolveWidget;
