import { Box } from '@mui/material';

import FallbackDescription from './FallbackDescription';

const FallbackWidgetContent = () => {
  return (
    <Box>
      <Box
        alignSelf='center'
        display='flex'
        flexDirection='column'
        padding='30px 90px 0px 90px'
        sx={{
          background: 'linear-gradient(270deg, #BD00FF 0%, #3866F5 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
        textAlign='center'
      >
        <FallbackDescription isFallbackWordWithGradient={false} />
      </Box>
    </Box>
  );
};

export default FallbackWidgetContent;
