import freshdeskLogoSrc from 'src/assets/images/freshdesk-article-logo.svg';
import intercomLogoSrc from 'src/assets/images/intercom-article-logo.svg';
import jiraLogoSrc from 'src/assets/images/jira-logo.svg';
import kustomerLogoSrc from 'src/assets/images/kustomer-article-logo.svg';
import zendeskLogoSrc from 'src/assets/images/zendesk-article-logo.svg';
import { Helpdesk } from 'src/components/app/types';
import sfdcLogoSrc from 'src/pages/workflow-builder-edit/assets/icons/salesforce.svg';
import { SolveWidgetProduct } from 'src/types/types';
import {
  ConversationChannel,
  WorkflowBuilderHeaderChannel,
} from 'src/types/workflowBuilderAPITypes';

export const VALID_DOMAIN_REGEX =
  /^(http(s)?:\/\/)?([\w-]+\.)+[\w\-\._~:/?#[\]@!\%$&'\(\)\*\+,;=.]+$/;
export const VALID_KNOWLEDGE_URL_WITH_HTTPS_REGEX =
  /^(https:\/\/)([\w-]+\.)+[\w\-\._~:\/?#[\]@!\%$&'\(\)\*\+,;=.]+$/;
export const GET_DOMAIN_REGEX =
  /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?<domain>[^:\/?\n]+)/;

export const INVALID_URL_MESSAGE = 'Valid URL format required';
export const DUPLICATE_DOMAIN_MESSAGE = 'Domain already exists';
export const HTTPS_INVALID_URL_MESSAGE =
  'Please enter a valid URL format with the "https://" prefix';

export const HELPDESK_LOGOS: Partial<Record<Helpdesk, string>> = {
  freshdesk: freshdeskLogoSrc,
  intercom: intercomLogoSrc,
  jira: jiraLogoSrc,
  kustomer: kustomerLogoSrc,
  salesforce: sfdcLogoSrc,
  zendesk: zendeskLogoSrc,
};

export const SHARED_METRIC_DEFINITIONS = {
  avgCSAT: {
    label: 'Avg. CSAT',
    tooltip:
      'This metric represents the average satisfaction score for all chat sessions. If a user does not respond to the satisfaction survey, it will not be factored into this calculation.',
  },
  deflectionRate: {
    label: 'Chat Deflection Rate',
    tooltip:
      'Previously Self Service Rate. Total Deflected Chat Sessions divided by Total Chat Sessions.',
  },
  fallback: {
    label: 'Fallback',
    tooltip:
      "Number of chats that triggered the Fallback workflow. The reason these chats triggered the workflow is due to the absence of relevant help articles or workflows available to answer the users' questions.",
  },
  irrelevant: { label: 'irrelevant' },
  relevance: {
    label: 'Relevance',
    tooltip:
      "Relevance Rating uses AI to determine how well the response directly addresses the inquiry. Each deflected chat receives one rating. Non-deflected chats and some historical chats won't have a Relevance rating.",
  },
  relevant: { label: 'relevant' },
  somewhatRelevant: { label: 'somewhat relevant' },
  totalChats: {
    label: 'Total Chats',
    tooltip:
      "This metric counts the instances of user interaction with a chatbot, including each session start or resumption after 3 hours, indicating the chatbot's engagement frequency.",
  },
  totalDeflectedChats: {
    label: 'Total Deflected Chats',
    tooltip:
      "This metric counts the chat sessions where the user wasn't transferred to a human chat agent (handoff) or didn't issue a ticket. Even if a single chat session had several handoffs or generated multiple tickets, it would only count as one Not Deflected Chat.",
  },
  userEngagement: {
    label: 'User engagement',
    tooltip:
      'User engagement uses AI to determine whether the user remained engaged throughout the chat or dropped off mid-way during the conversation.',
  },
} as const;

export const DRAG_AND_DROP_NEW_STEP_TYPE = 'new-step';

export const NA = 'n/a';

// TODO ft elements color
export const TEMP_BLUE = '#003BD2';
export const TEMP_LIGHT_BLUE = '#3191FF';

export const supportedChannelsMap: Record<
  ConversationChannel,
  WorkflowBuilderHeaderChannel[]
> = {
  api: ['workflows', 'chats'],
  email: ['workflows', 'conversations'],
  playbook: ['workflows', 'chats'],
  slack: ['workflows', 'chats'],
  voice: ['workflows', 'chats'],
  widget: ['workflows', 'chats', 'templates'],
};

export const supportedSubChannelsMap: Record<
  WorkflowBuilderHeaderChannel,
  ConversationChannel[]
> = {
  // chats and conversations are treated the same, so we need to include both for email and widget
  chats: ['widget', 'email', 'api', 'slack', 'voice', 'playbook'],
  conversations: ['widget', 'email'],
  templates: ['widget'],
  workflows: ['widget', 'email', 'api', 'slack', 'voice', 'playbook'],
};

export const conversationChannelToProductMap: Record<
  ConversationChannel,
  SolveWidgetProduct
> = {
  api: 'api',
  email: 'interactive_email',
  playbook: 'playbook',
  slack: 'slack',
  voice: 'voice',
  widget: 'workflow_builder',
};
