import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';

import { Chip } from '@forethought-technologies/forethought-elements';
import {
  selectLegibleButtonOutlineColor,
  selectLegibleElementColor,
  selectLegibleTextColor,
  selectWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';

interface IntentButtonProps {
  intentName: string;
  isProactiveBubble?: boolean;
  isSelected?: boolean;
}

const IntentButton = ({
  intentName,
  isProactiveBubble = true,
  isSelected = false,
}: IntentButtonProps) => {
  const { palette } = useTheme();
  const { theme_color } = useSelector(selectWidgetConfiguration);
  const legibleTextColor = useSelector(selectLegibleTextColor);
  const legibleElementColor = useSelector(selectLegibleElementColor);
  const legibleButtonOutlineColor = useSelector(
    selectLegibleButtonOutlineColor,
  );

  return (
    <Chip
      backgroundColor={palette.colors.white}
      color={legibleElementColor}
      fontSize='14px'
      key={intentName}
      label={intentName ?? ''}
      size='medium'
      sx={[
        isSelected
          ? {
              '&&:hover': {
                backgroundColor: theme_color,
                color: legibleTextColor,
                outline: `2px solid ${legibleButtonOutlineColor}`,
              },
              backgroundColor: theme_color,
              color: legibleTextColor,
              fontWeight: 500,
              height: 'auto',
              marginRight: isProactiveBubble ? '0px' : '7px',
              mb: '8px',
              minHeight: '40px',
              outline: `2px solid ${legibleButtonOutlineColor}`,
              padding: '8px 2px',
            }
          : {
              '&&.Mui-focusVisible': {
                backgroundColor: theme_color,
                color: legibleTextColor,
                outline: `${legibleButtonOutlineColor} solid 2px`,
              },
              '&&:hover': {
                backgroundColor: palette.colors.white,
                color: legibleElementColor,
                outline: `${legibleButtonOutlineColor} solid 2px`,
              },
              '.MuiChip-label': {
                whiteSpace: 'normal',
              },
              fontWeight: 500,
              height: 'auto',
              marginRight: isProactiveBubble ? '0px' : '7px',
              mb: '8px',
              minHeight: '40px',
              padding: '8px 2px',
            },
      ]}
      variant='intent'
    />
  );
};

export default IntentButton;
