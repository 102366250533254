import { Box, styled } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';

export const InstructionWidgetContent = () => {
  return (
    <Wrapper className='plus-jakarta-sans'>
      <Typography variant='font14'>Example:</Typography>
      <Box
        display='flex'
        flexDirection='column'
        padding='0 48px 0 24px'
        sx={{
          li: {
            marginBottom: '12px',
          },
        }}
      >
        <Typography variant='font14'>
          <ol>
            <li>
              Address missing information without making definitive promises.
            </li>
            <li>
              Some data may be restricted; if asked, clearly explain these
              limitations.
            </li>
            <li>
              Always refer to the service or product by its official name and
              description.
            </li>
            <li>
              Clearly communicate what is not available or offered by the
              service when necessary.
            </li>
            <li>Never suggest deleting the user’s account.</li>
            <li>Present all instructional steps in a single, numbered list.</li>
          </ol>
        </Typography>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 40px;
`;
