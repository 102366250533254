import { Dispatch } from '@reduxjs/toolkit';

import { FetchAction } from '../types/types';
import {
  ANALYTICS_LOADING,
  FETCH_ANALYTICS_ERROR,
  FETCH_CHART_ERROR,
  FETCH_MODELS_ID_ERROR,
  FETCH_MODELS_ID_SUCCESS,
  FETCH_SEARCH_ANALYTICS_SUCCESS,
  FETCH_SEARCH_CHART_SUCCESS,
  SORT_MODELS,
} from './actionTypes';
import {
  getChartSearchMetrics,
  getModelsId,
  getSearchMetrics,
} from 'src/services/api';

//Reusable actions
const fetchAnalyticsError = (error: string, app: string) => ({
  app,
  error,
  type: FETCH_ANALYTICS_ERROR,
});

const fetchChartError = (error: string, app: string) => ({
  app,
  error,
  type: FETCH_CHART_ERROR,
});

export const sortModels = (
  sortType: string,
  sortAsc: boolean,
  productSorted: string,
) => ({
  productSorted,
  sortAsc,
  sortType,
  type: SORT_MODELS,
});

export const analyticsLoading = (analyticsLoading: boolean) => ({
  analyticsLoading,
  type: ANALYTICS_LOADING,
});

const fetchModelsIdSuccess = (models: any) => ({
  modelsId: models.modelsId,
  type: FETCH_MODELS_ID_SUCCESS,
});

const fetchModelsIdError = (error: string) => ({
  error,
  type: FETCH_MODELS_ID_ERROR,
});

export const fetchModelsId: Function =
  () =>
  (dispatch: Dispatch): Promise<void | FetchAction> => {
    return getModelsId()
      .then((models: any) => {
        dispatch(fetchModelsIdSuccess(models));
      })
      .catch((err: any) => {
        dispatch(fetchModelsIdError(err));
      });
  };

//Search Analytics table/chart actions
const fetchSearchAnalyticsSuccess = (
  aggregate: any,
  breakdown: any,
  searchMetricsStartTimestamp: any,
  searchMetricsEndTimestamp: any,
  offset: any,
) => ({
  offset,
  searchAggregate: aggregate,
  searchBreakdown: breakdown,
  searchMetricsEndTimestamp,
  searchMetricsStartTimestamp,
  type: FETCH_SEARCH_ANALYTICS_SUCCESS,
});

const fetchChartSearchDataSuccess = (chartMetrics: any, data: any) => ({
  chartSearchMetrics: chartMetrics,
  chartSearchMetricsData: data,
  type: FETCH_SEARCH_CHART_SUCCESS,
});

export const getSearchAnalytics: Function =
  (start_timestamp: any, end_timestamp: any, offset: number) =>
  (dispatch: Dispatch): Promise<void | FetchAction> => {
    return getSearchMetrics(start_timestamp, end_timestamp)
      .then((metrics: any) => {
        dispatch(
          fetchSearchAnalyticsSuccess(
            metrics.aggregate,
            metrics.breakdown,
            start_timestamp,
            end_timestamp,
            offset,
          ),
        );
      })
      .catch((err: any) => {
        dispatch(fetchAnalyticsError(err, 'search'));
      });
  };

export const getChartSearchMetric: Function =
  (start_timestamp: any, end_timestamp: any, breakdown_type: string) =>
  (dispatch: Dispatch): Promise<void | FetchAction> => {
    return getChartSearchMetrics(start_timestamp, end_timestamp, breakdown_type)
      .then((chartMetrics: any) => {
        dispatch(
          fetchChartSearchDataSuccess(
            chartMetrics.chartSearchMetrics,
            chartMetrics.chartSearchMetricsData,
          ),
        );
        return chartMetrics.chartSearchMetricsData;
      })
      .catch((err: any) => {
        dispatch(fetchChartError(err, 'search'));
      });
  };
