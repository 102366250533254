import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconNews } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import GeneratedReplyFooter from './GeneratedReplyFooter';
import GeneratedReplyHeader from './GeneratedReplyHeader';
import aiListIcon from 'src/assets/images/ai-list.svg';

interface GeneratedReplyProps {
  preview: React.ReactNode;
}

const GeneratedReply = ({ preview }: GeneratedReplyProps) => {
  const { palette } = useTheme();
  return (
    <Box
      bgcolor='white'
      borderRadius={1}
      display='grid'
      gridTemplateRows='49px auto 69px'
      height='582px'
      overflow='hidden'
      position='relative'
      sx={{
        '&::before': {
          background: 'linear-gradient(270deg, #BD00FF 5.08%, #3866F5 94.92%)',
          borderRadius: 'inherit',
          content: '""',
          inset: 0,
          maskComposite: 'exclude',
          padding: '1px',
          position: 'absolute',
          WebkitMask:
            'linear-gradient(white 0 0) content-box, linear-gradient(white 0 0)',
          WebkitMaskComposite: 'destination-out',
        },
      }}
      width='700px'
    >
      <GeneratedReplyHeader />
      <Box
        display='flex'
        flexDirection='column'
        gap={2}
        p={2}
        sx={{ overflowY: 'auto' }}
      >
        <Box
          alignItems='center'
          display='flex'
          gap={0.5}
          pb={1.5}
          sx={{ cursor: 'pointer' }}
        >
          <img alt='' src={aiListIcon} />
          <Box alignItems='center' display='flex' ml={0.5}>
            <Typography color={palette.colors.grey[500]} variant='font11'>
              Show Ticket Summary
            </Typography>
          </Box>
        </Box>

        <Box
          display='flex'
          flexDirection='column'
          gap={2}
          sx={{
            height: '100%',
            overflowY: 'scroll',
            whiteSpace: 'break-spaces',
            wordWrap: 'break-word',
          }}
        >
          {preview}
        </Box>

        <Box
          alignItems='center'
          display='flex'
          gap={0.5}
          pt={1.5}
          sx={{ cursor: 'pointer' }}
        >
          <IconNews color={palette.colors.grey[500]} />
          <Box alignItems='center' display='flex' ml={0.5}>
            <Typography color={palette.colors.grey[500]} variant='font11'>
              Show Reply Source
            </Typography>
          </Box>
        </Box>
      </Box>
      <GeneratedReplyFooter />
    </Box>
  );
};

export default GeneratedReply;
