import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useDebouncedSubmitConfigForRTE } from '../hooks/useDebouncedSubmitConfig';
import { DEFAULT_TONE_OF_VOICE_INPUT } from './constants';
import { getToneOfVoiceStatus } from './helpers';
import { useToneOfVoiceTestQuery } from 'src/services/solve-config/solveConfigApi';
import {
  selectWidgetConfiguration,
  setToneOfVoiceConfig,
  setToneOfVoiceConfigAssist,
} from 'src/slices/solve-config/solveConfigSlice';
import { ToneOfVoiceConfig } from 'src/slices/solve-config/types';
import { useAppDispatch } from 'src/store/hooks';

const useToneOfVoiceConfig = (isPlaybook?: boolean) => {
  const config = useSelector(selectWidgetConfiguration);
  const toneOfVoiceConfig: ToneOfVoiceConfig | undefined = isPlaybook
    ? config.tone_of_voice_config_playbook
    : config.tone_of_voice_config;

  const toneOfVoiceStatus = getToneOfVoiceStatus(toneOfVoiceConfig);

  const { refetch } = useToneOfVoiceTestQuery(
    {
      input: DEFAULT_TONE_OF_VOICE_INPUT,
    },
    { skip: toneOfVoiceStatus === 'default' },
  );
  const dispatch = useAppDispatch();
  const debouncedSubmitConfig = useDebouncedSubmitConfigForRTE();

  const updateToneOfVoiceConfig = useCallback(
    ({ predefined_tones, ...fields }: ToneOfVoiceConfig) => {
      const target = isPlaybook
        ? 'tone_of_voice_config_assist'
        : 'tone_of_voice_config';
      const newFields = {
        predefined_tones: predefined_tones || [],
        ...fields,
      };
      if (isPlaybook) {
        dispatch(setToneOfVoiceConfigAssist(newFields));
      } else {
        dispatch(setToneOfVoiceConfig(newFields));
      }
      debouncedSubmitConfig(
        {
          ...config,
          [target]: newFields,
        },
        target,
        JSON.stringify(newFields),
        refetch,
      );
    },
    [debouncedSubmitConfig, dispatch, refetch, config, isPlaybook],
  );

  return {
    toneOfVoiceConfig,
    toneOfVoiceStatus,
    updateToneOfVoiceConfig,
  } as const;
};

export default useToneOfVoiceConfig;
