import { useMemo } from 'react';

import useTabs from './useTabs';

export const useGetCurrentTab = ({
  articleId,
  isParentTopic,
  tab,
  tableTab,
  topicId,
  workflowId,
}: {
  articleId?: string;
  isParentTopic?: boolean;
  tab?: string;
  tableTab?: string;
  topicId?: string;
  workflowId?: string;
}) => {
  const { tabIdToIndexMap } = useTabs();
  const articleIndex = tabIdToIndexMap['article'].toString();

  return useMemo(() => {
    if (topicId || isParentTopic) {
      return 'topic';
    }
    if (workflowId || tab === '3') {
      return 'workflow';
    }
    if (articleId || tableTab === articleIndex || tab === articleIndex) {
      return 'article';
    }
    return 'chat';
  }, [
    articleId,
    isParentTopic,
    tab,
    tableTab,
    topicId,
    workflowId,
    articleIndex,
  ]);
};
