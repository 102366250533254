import { ConnectorDefinition } from 'src/services/apiInterfaces';

// TODO: temporarily filtering connectors on the frontend while awaiting backend changes to add 'hide' functionality
const HIDDEN_CONNECTOR_SLUGS = [
  'legacy_clickup_internal',
  'legacy_mindtouch',
  'legacy_other',
];

function matchesTemporaryFilters(connectorDefinition: ConnectorDefinition) {
  return (
    connectorDefinition.setup_type !== 'THIRD_PARTY_TRAY_WIZARD' &&
    !HIDDEN_CONNECTOR_SLUGS.includes(connectorDefinition.slug)
  );
}

export const filterConnectorBySearchQuery =
  (searchQuery: string) => (connectorDefinition: ConnectorDefinition) => {
    const matchesSearchQuery = connectorDefinition.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    return matchesSearchQuery && matchesTemporaryFilters(connectorDefinition);
  };
