import { ToneOfVoiceConfig } from 'src/slices/solve-config/types';

export const getToneOfVoiceStatus = (toneOfVoiceConfig?: ToneOfVoiceConfig) => {
  if (!toneOfVoiceConfig?.enabled) {
    return 'default';
  }

  if (
    !toneOfVoiceConfig?.should_use_predefined_tones &&
    toneOfVoiceConfig?.custom_prompt
  ) {
    return 'custom';
  }

  if (
    toneOfVoiceConfig?.predefined_tones.length > 0 &&
    toneOfVoiceConfig?.should_use_predefined_tones
  ) {
    return 'predefined';
  }

  return 'default';
};
