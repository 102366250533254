import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  SelectChangeEvent,
  TableContainer,
  useTheme,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
  Button,
  Dialog,
  FilterToggleButton,
  SearchBar,
  SelectDropdown,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  intentSelectedOptions,
  modeSelectedOptions,
  statusSelectedOptions,
} from '../constants';
import { intentColumnType, modeColumnType, statusColumnType } from '../types';
import {
  filterNPartitionIntentFilters,
  isGeneralFallback,
  useGetDefaultIntentFilters,
} from '../utils';
import { IntentFilterRow } from './IntentFilterRow';
import isEqual from 'lodash/fp/isEqual';
import { IntentFilter } from 'src/slices/workflow-preview/types';
import {
  selectPreviewIntentFilters,
  setPreviewIntentFilters,
} from 'src/slices/workflow-preview/workflowPreviewSlice';
import { useAppDispatch } from 'src/store/hooks';

export const IntentFilterModal = ({
  disableUpdateFilter,
  hideModeColumn,
  isOpen,
  setIsOpen,
}: {
  disableUpdateFilter?: boolean;
  hideModeColumn?: boolean;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { defaultIntentFilters, defaultIntentFiltersMapping } =
    useGetDefaultIntentFilters();
  const originalIntentFilters = useSelector(selectPreviewIntentFilters);
  const params = new URLSearchParams(location.search);

  const previewFromIntentId = params.get('intentId');

  const [intentFilters, setIntentFilters] = useState<IntentFilter[]>([]);
  const [search, setSearch] = useState('');

  // consts for rendering
  const [currentIntent, liveIntents, inactiveIntents] =
    filterNPartitionIntentFilters({
      defaultIntentFiltersMapping: defaultIntentFiltersMapping,
      intentFilters: intentFilters,
      previewFromIntentId: previewFromIntentId,
      search: search,
    });

  const includesLiveTraffic = defaultIntentFilters.every(
    (defaultIntentFilter, index) => {
      if (!defaultIntentFilter.isChecked) {
        return true;
      }

      if (defaultIntentFilter.intentId === previewFromIntentId) {
        return true;
      }

      return isEqual(defaultIntentFilter, intentFilters[index]);
    },
  );

  // Calculate what state each of the columns are in
  const intentSelected: intentColumnType = useMemo<intentColumnType>(() => {
    const isAllIntents = intentFilters.every(
      intentFilter =>
        isGeneralFallback(intentFilter.intentId) || intentFilter.isChecked,
    );

    const isAllActiveIntents = intentFilters.every(
      (intentFilter, index) =>
        isGeneralFallback(intentFilter.intentId) ||
        intentFilter.isChecked === defaultIntentFilters[index]?.isChecked,
    );

    const isAllInactiveIntents = intentFilters.every(
      (intentFilter, index) =>
        isGeneralFallback(intentFilter.intentId) ||
        intentFilter.isChecked !== defaultIntentFilters[index]?.isChecked,
    );

    const isAllAutoflows = intentFilters.every(
      (intentFilter, index) =>
        isGeneralFallback(intentFilter.intentId) ||
        intentFilter.isChecked === defaultIntentFilters[index]?.isAutoflow,
    );
    if (isAllIntents) {
      return 'All intents';
    } else if (isAllActiveIntents) {
      return 'All active intents';
    } else if (isAllInactiveIntents) {
      return 'All inactive intents';
    } else if (isAllAutoflows) {
      return 'All autoflows';
    } else {
      return '';
    }
  }, [defaultIntentFilters, intentFilters]);

  const statusSelected = useMemo<statusColumnType>(() => {
    const isAllPublished = intentFilters.every(
      (intentFilter, index) =>
        !defaultIntentFilters[index]?.isChecked || !intentFilter.isDraft,
    );
    const isAllDraft = intentFilters.every(
      intentFilter => intentFilter.isDraft,
    );
    if (isAllDraft) {
      return 'All draft';
    } else if (isAllPublished) {
      return 'All published';
    } else {
      return '';
    }
  }, [intentFilters, defaultIntentFilters]);

  const modeSelected = useMemo<modeColumnType>(() => {
    const isAllClassic = intentFilters.every(
      intentFilter => !intentFilter.isAutoflow,
    );
    const isAllAutoflow = intentFilters.every(
      intentFilter => intentFilter.isAutoflow,
    );
    if (isAllClassic) {
      return 'All classic';
    } else if (isAllAutoflow) {
      return 'All autoflow';
    } else {
      return '';
    }
  }, [intentFilters]);

  // set the initial values of intent filters and live mode when it opens
  useEffect(() => {
    setIntentFilters([...originalIntentFilters]);
  }, [originalIntentFilters, isOpen]);

  // handlers
  const setIsChecked = (intentId: string, val: boolean) => {
    setIntentFilters(
      intentFilters.map(intentFilter =>
        intentFilter.intentId === intentId
          ? { ...intentFilter, isChecked: val }
          : intentFilter,
      ),
    );
  };

  const setIsDraft = (
    intentId: string,
    val: boolean,
    isIntentDefaultAutoflow: boolean,
  ) => {
    setIntentFilters(
      intentFilters.map(intentFilter =>
        intentFilter.intentId === intentId
          ? {
              ...intentFilter,
              isAutoflow: isIntentDefaultAutoflow,
              isDraft: val,
            }
          : intentFilter,
      ),
    );
  };

  const setIsAutoflow = (intentId: string, val: boolean) => {
    setIntentFilters(
      intentFilters.map(intentFilter =>
        intentFilter.intentId === intentId
          ? { ...intentFilter, isAutoflow: val }
          : intentFilter,
      ),
    );
  };

  const handleOnlyClicked = (intentId: string) => {
    setIntentFilters(
      intentFilters.map(intentFilter => {
        if (isGeneralFallback(intentFilter.intentId)) {
          return intentFilter;
        }
        return intentFilter.intentId === intentId
          ? { ...intentFilter, isChecked: true }
          : { ...intentFilter, isChecked: false };
      }),
    );
  };

  const handleIntentSelectedChange = (e: SelectChangeEvent<string>) => {
    const newMode = e.target.value;
    if (newMode === intentSelected) {
      return;
    }

    switch (newMode) {
      case 'All intents':
        setIntentFilters(
          intentFilters.map(intentFilter => ({
            ...intentFilter,
            isChecked: true,
          })),
        );
        return;
      case 'All active intents':
        setIntentFilters(
          intentFilters.map((intentFilter, index) => ({
            ...intentFilter,
            isChecked:
              defaultIntentFilters[index]?.isChecked ?? intentFilter.isChecked,
          })),
        );
        return;
      case 'All inactive intents':
        setIntentFilters(
          intentFilters.map((intentFilter, index) =>
            isGeneralFallback(intentFilter.intentId)
              ? intentFilter
              : {
                  ...intentFilter,
                  isChecked: !(
                    defaultIntentFilters[index]?.isChecked ??
                    intentFilter.isChecked
                  ),
                },
          ),
        );
        return;
      case 'All autoflows':
        setIntentFilters(
          intentFilters.map((intentFilter, index) =>
            isGeneralFallback(intentFilter.intentId)
              ? intentFilter
              : {
                  ...intentFilter,
                  isChecked:
                    defaultIntentFilters[index]?.isAutoflow ??
                    intentFilter.isAutoflow,
                },
          ),
        );
        return;
    }
  };

  const handleModeSelectedChange = (e: SelectChangeEvent<string>) => {
    const newMode = e.target.value;
    if (newMode === modeSelected) {
      return;
    }

    switch (newMode) {
      case 'All classic':
        setIntentFilters(
          intentFilters.map(intentFilter => ({
            ...intentFilter,
            isAutoflow: false,
            isDraft:
              !intentFilter.isDraft &&
              defaultIntentFiltersMapping[intentFilter.intentId]?.isAutoflow !==
                false
                ? true
                : intentFilter.isDraft,
          })),
        );
        return;
      case 'All autoflow':
        setIntentFilters(
          intentFilters.map(intentFilter => ({
            ...intentFilter,
            isAutoflow: true,
            isDraft:
              !intentFilter.isDraft &&
              defaultIntentFiltersMapping[intentFilter.intentId]?.isAutoflow !==
                true
                ? true
                : intentFilter.isDraft,
          })),
        );
        return;
    }
  };

  const handleStatusSelectedChange = (e: SelectChangeEvent<string>) => {
    const newMode = e.target.value;
    if (newMode === statusSelected) {
      return;
    }

    switch (newMode) {
      case 'All published':
        setIntentFilters(
          intentFilters.map(intentFilter => {
            if (defaultIntentFiltersMapping[intentFilter.intentId]?.isChecked) {
              return {
                ...intentFilter,
                isAutoflow:
                  defaultIntentFiltersMapping[intentFilter.intentId]
                    ?.isAutoflow,
                isDraft: false,
              };
            }
            return intentFilter;
          }),
        );
        return;
      case 'All draft':
        setIntentFilters(
          intentFilters.map(intentFilter => ({
            ...intentFilter,
            isDraft: true,
          })),
        );
        return;
    }
  };

  const handleIncludeLiveTrafficClicked = () => {
    if (includesLiveTraffic) {
      const newIntentFilters = intentFilters.map(intentFilter => {
        if (intentFilter.intentId === previewFromIntentId) {
          return intentFilter;
        }

        if (
          defaultIntentFiltersMapping[intentFilter.intentId]?.isChecked &&
          !isGeneralFallback(intentFilter.intentId)
        ) {
          return { ...intentFilter, isChecked: false };
        }

        return intentFilter;
      });

      setIntentFilters(newIntentFilters);
      return;
    }

    const newIntentFilters = intentFilters.map(intentFilter => {
      if (intentFilter.intentId === previewFromIntentId) {
        return intentFilter;
      }

      if (defaultIntentFiltersMapping[intentFilter.intentId]?.isChecked) {
        return { ...defaultIntentFiltersMapping[intentFilter.intentId] };
      }

      return intentFilter;
    });

    setIntentFilters(newIntentFilters);
  };

  return (
    <Dialog
      onClose={() => {
        setIsOpen(false);
      }}
      open={isOpen}
      title='Intents filter by'
    >
      <Box display='flex' flexDirection='column'>
        <Box display='flex' gap={2} mb={2}>
          <Box width='300px'>
            <SearchBar
              onChange={e => setSearch(e.target.value)}
              onClear={() => setSearch('')}
              placeholder='Search'
              size='small'
              value={search}
            />
          </Box>
          <FilterToggleButton
            handleChange={handleIncludeLiveTrafficClicked}
            isChecked={includesLiveTraffic}
            label={"include 'Live' traffic"}
          />
        </Box>
        <TableContainer sx={{ maxHeight: 550 }}>
          <Table stickyHeader sx={{ minWidth: 950 }}>
            <TableHead>
              <TableCell>
                <Box
                  alignItems='center'
                  display='flex'
                  gap='8px'
                  sx={{
                    '.MuiFormControl-root': { width: 'fit-content' },
                    '.MuiInputBase-root': { height: '32px' },
                  }}
                >
                  <Typography variant='font14Bold'>Intent</Typography>
                  <SelectDropdown
                    id='intent-selected'
                    onChange={handleIntentSelectedChange}
                    options={intentSelectedOptions}
                    value={intentSelected}
                  />
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  alignItems='center'
                  display='flex'
                  gap='8px'
                  sx={{
                    '.MuiFormControl-root': { width: 'fit-content' },
                    '.MuiInputBase-root': { height: '32px' },
                  }}
                >
                  <Typography variant='font14Bold'>Status</Typography>
                  <SelectDropdown
                    id='status-selected'
                    onChange={handleStatusSelectedChange}
                    options={statusSelectedOptions}
                    placeholder='Mixed'
                    value={statusSelected}
                  />
                </Box>
              </TableCell>
              {!hideModeColumn && (
                <TableCell>
                  <Box
                    alignItems='center'
                    display='flex'
                    gap='8px'
                    sx={{
                      '.MuiFormControl-root': { width: 'fit-content' },
                      '.MuiInputBase-root': { height: '32px' },
                    }}
                  >
                    <Typography variant='font14Bold'>Mode</Typography>
                    <SelectDropdown
                      id='mode-selected'
                      onChange={handleModeSelectedChange}
                      options={modeSelectedOptions}
                      value={modeSelected}
                    />
                  </Box>
                </TableCell>
              )}
            </TableHead>
            <TableBody>
              {currentIntent && (
                <>
                  <TableRow>
                    <TableCell
                      sx={{
                        border: 0,
                        padding: '10px 0px',
                      }}
                    >
                      <Typography
                        color={theme.palette.colors.grey[500]}
                        variant='font12'
                      >
                        Current Intent
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <IntentFilterRow
                    handleOnlyClicked={handleOnlyClicked}
                    hideModeColumn={hideModeColumn}
                    intentFilter={currentIntent}
                    setIsAutoflow={setIsAutoflow}
                    setIsChecked={setIsChecked}
                    setIsDraft={setIsDraft}
                  />
                </>
              )}
              {liveIntents.length > 0 && (
                <>
                  <TableRow>
                    <TableCell
                      sx={{
                        border: 0,
                        padding: '10px 0px',
                      }}
                    >
                      <Typography
                        color={theme.palette.colors.grey[500]}
                        variant='font12'
                      >
                        Active Intents
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {liveIntents.map(intentFilter => {
                    return (
                      <IntentFilterRow
                        handleOnlyClicked={handleOnlyClicked}
                        hideModeColumn={hideModeColumn}
                        intentFilter={intentFilter}
                        key={intentFilter.intentId}
                        setIsAutoflow={setIsAutoflow}
                        setIsChecked={setIsChecked}
                        setIsDraft={setIsDraft}
                      />
                    );
                  })}
                </>
              )}
              {inactiveIntents.length > 0 && (
                <>
                  <TableRow>
                    <TableCell
                      sx={{
                        border: 0,
                        padding: '10px 0px',
                      }}
                    >
                      <Typography
                        color={theme.palette.colors.grey[500]}
                        variant='font12'
                      >
                        Inactive Intents
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {inactiveIntents.map(intentFilter => {
                    return (
                      <IntentFilterRow
                        handleOnlyClicked={handleOnlyClicked}
                        hideModeColumn={hideModeColumn}
                        intentFilter={intentFilter}
                        key={intentFilter.intentId}
                        setIsAutoflow={setIsAutoflow}
                        setIsChecked={setIsChecked}
                        setIsDraft={setIsDraft}
                      />
                    );
                  })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          alignItems='center'
          display='flex'
          gap={1}
          marginLeft='auto'
          mt={3}
        >
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            variant='ghost'
          >
            Cancel
          </Button>
          <Tooltip
            placement='top'
            tooltipContent={
              disableUpdateFilter
                ? 'Cannot save filter update due to an ongoing conversation'
                : ''
            }
          >
            <Button
              disabled={disableUpdateFilter}
              onClick={() => {
                dispatch(setPreviewIntentFilters([...intentFilters]));
                setIsOpen(false);
              }}
              variant='main'
            >
              Apply filter
            </Button>
          </Tooltip>
        </Box>
      </Box>
    </Dialog>
  );
};
