import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import { COMPANY_AVATARS } from './constants';
import ftLogo from 'src/assets/images/ft-logo-white.png';

const IntegrationHeader = () => {
  return (
    <Box
      alignItems='center'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      width='100%'
    >
      <Box height='200px' maxWidth='700px' position='relative' width='100%'>
        <Bubble p={0.25} size='35px' top='78px'>
          <img src={COMPANY_AVATARS.readmeio} />
        </Bubble>
        <Bubble left='111px' p={0.5} size='44px' top='149px'>
          <img src={COMPANY_AVATARS.confluence} />
        </Bubble>
        <Bubble left='223px' p={1} size='51px' top='47px'>
          <img src={COMPANY_AVATARS.salesforce} />
        </Bubble>
        <Bubble left='394px' p={0.25} size='30px' top='42px'>
          <img src={COMPANY_AVATARS.intercom} />
        </Bubble>
        <Bubble left='518px' p={1} size='51px' top='99px'>
          <img src={COMPANY_AVATARS.zendesk} />
        </Bubble>
        <Bubble left='665px' p={0.25} size='35px' top='74px'>
          <img src={COMPANY_AVATARS.snowflake} />
        </Bubble>
      </Box>
      <Box
        alignItems='center'
        bgcolor='black'
        borderRadius={2}
        boxShadow='0px 0px 0.845px 0px rgba(0, 12, 32, 0.02), 3.38px 10.141px 16.901px 0px rgba(3, 17, 38, 0.11)'
        color='white'
        display='flex'
        gap={1}
        height='60px'
        justifyContent='center'
        mt={-4}
        width='250px'
      >
        <img src={ftLogo} width='20px' />
        <Typography variant='font16'>Forethought Assist</Typography>
      </Box>
    </Box>
  );
};

const Bubble = styled(Box)<{ size: string }>(({ size }) => ({
  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: '100%',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)',
  display: 'flex',
  height: size,
  img: {
    display: 'block',
    width: '100%',
  },
  justifyContent: 'center',
  position: 'absolute',

  width: size,
}));

export default IntegrationHeader;
