import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

import {
  IconButton,
  Typography,
} from '@forethought-technologies/forethought-elements';

const PageControl = () => {
  const { palette } = useTheme();

  return (
    <Box alignItems='center' display='flex' gap={0.5}>
      <IconButton aria-label='previous page' disabled variant='ghost'>
        <IconChevronLeft size={20} />
      </IconButton>
      <Typography color={palette.colors.grey[500]} variant='font11'>
        1 of 1
      </Typography>
      <IconButton aria-label='next page' disabled variant='ghost'>
        <IconChevronRight size={20} />
      </IconButton>
    </Box>
  );
};

export default PageControl;
