import { useCallback, useRef, useState } from 'react';
import { Box, useTheme } from '@mui/material';

import ChatsTable from '../../solve-insights/chats/ChatsTable';
import { OverallCards } from './OverallCards';
import TabHeader from 'src/components/dashboard-pages/solve-insights/common/TabHeader';
import { stringifyDateRange } from 'src/components/dashboard-pages/solve-insights/helpers';
import { useEmitTrackingEventCallback, useStateParams } from 'src/hooks/hooks';
import {
  dateRangeDeserialize,
  dateRangeSerialize,
  timeFilterParameterValidator,
} from 'src/utils/discover/helpers';
import { last30DaysTimeRange } from 'src/utils/timeRangeHelpers';

const VoiceInsightsConversations = () => {
  const { palette } = useTheme();

  const [scrollTop, setScrollTop] = useState(0);

  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  const [dateRange, setDateRange] = useStateParams({
    deserialize: dateRangeDeserialize,
    initialState: last30DaysTimeRange,
    paramsName: 'date',
    serialize: dateRangeSerialize,
    validator: timeFilterParameterValidator([]),
  });

  const containerRef = useRef<HTMLDivElement>();

  const scrollToTop = useCallback(
    (yOffset: number, smooth = false) => {
      if (!containerRef.current) {
        return;
      }
      if (containerRef.current.scrollTop > yOffset) {
        containerRef.current.scrollTo({
          behavior: smooth ? 'smooth' : 'auto',
          top: yOffset,
        });
        // TODO - when tab navigation is added this should be updated
        emitTrackingEventCallback('insight-scroll-to-top-clicked', {
          scope: 'main',
          tab: 'chat',
        });
      }
    },
    [emitTrackingEventCallback],
  );

  return (
    <Box
      bgcolor={palette.colors.white}
      flex='1'
      height='100%'
      onScroll={e => setScrollTop(e.currentTarget.scrollTop)}
      overflow='auto'
      ref={containerRef}
    >
      <Box
        bgcolor={palette.colors.white}
        display='flex'
        flexDirection='column'
        gap={3}
        height='100%'
        padding='40px'
        width='100%'
      >
        <TabHeader
          dateRange={stringifyDateRange(dateRange)}
          scope='main'
          tabName='Conversations'
        />
        <OverallCards dateRange={dateRange} />
        <ChatsTable
          dateRange={dateRange}
          isDetail
          isVoiceChannel
          scope='main'
          scrollTop={scrollTop}
          scrollToTop={scrollToTop}
          setDateRange={setDateRange}
          view='chats'
        />
      </Box>
    </Box>
  );
};

export default VoiceInsightsConversations;
