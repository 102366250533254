import { Box, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';

export default function SlackIntegrationContent() {
  const theme = useTheme();

  return (
    <Box
      alignItems='center'
      className='plus-jakarta-sans'
      display='flex'
      flexDirection='column'
    >
      <Box
        sx={{
          background: 'linear-gradient(270deg, #BD00FF 0%, #3866F5 100%)',
          fontStyle: 'italic',
          marginTop: '80px',
          textAlign: 'center',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        <Typography variant='font16Medium'>
          Configure Slack integration using the menu on the right
        </Typography>
      </Box>

      <Box marginTop='80px'>
        <Typography color={theme.palette.grey[700]} variant='font28'>
          Hello and welcome!
        </Typography>
      </Box>

      <Box marginTop='8px'>
        <Typography color={theme.palette.grey[700]} variant='font14Medium'>
          What can I do to assist you today?
        </Typography>
      </Box>

      <Box
        marginTop='20px'
        sx={{
          backgroundColor: theme.palette.colors.white,
          borderColor: theme.palette.grey[300],
          borderRadius: '14.97px',
          borderStyle: 'solid',
          borderWidth: '1.5px',
          height: '30px',
          padding: '6.8px 13.61px 6.8px 13.61px',
          width: '372px',
        }}
      >
        <Typography
          color={theme.palette.grey[500]}
          component='div'
          variant='font11'
        >
          Search for keyword or ask me anything
        </Typography>
      </Box>
    </Box>
  );
}
