import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { Typography } from '@forethought-technologies/forethought-elements';
import SupportGPTIcon from 'src/assets/images/support-gpt.svg';

const GeneratedReplyAction = () => {
  const { palette } = useTheme();
  return (
    <Box display='flex' justifyContent='center' my={5}>
      <Box
        alignItems='center'
        bgcolor='white'
        borderRadius={2}
        boxShadow='0px 0px 0.845px 0px rgba(0, 12, 32, 0.02), 3.38px 10.141px 16.901px 0px rgba(3, 17, 38, 0.11)'
        display='flex'
        gap={1}
        px={2}
        py={2.5}
        sx={{ span: { fontWeight: 700 } }}
      >
        <img alt='' src={SupportGPTIcon} />
        <Typography color={palette.colors.grey[600]} variant='font12'>
          Forethought Assist - Generated reply
        </Typography>
      </Box>
    </Box>
  );
};

export default GeneratedReplyAction;
