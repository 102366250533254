export const UPDATE_QUERY_STRING = 'UPDATE_QUERY_STRING';
export const UPDATE_SEARCH_DATA = 'UPDATE_SEARCH_DATA';
export const SEARCH_LOADING = 'SEARCH_LOADING';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_SELECTED_RESULT = 'UPDATE_SELECTED_RESULT';
export const FETCH_ANALYTICS_ERROR = 'FETCH_ANALYTICS_ERROR';
export const FETCH_MODELS_ID_SUCCESS = 'FETCH_MODELS_ID_SUCCESS';
export const FETCH_MODELS_ID_ERROR = 'FETCH_MODELS_ID_ERROR';
export const SORT_MODELS = 'SORT_MODELS';
export const APPS_AVAILABLE = 'APPS_AVAILABLE';
export const SET_CURRENT_APPLICATION = 'SET_CURRENT_APPLICATION';
export const UPDATE_ROLE_INFO = 'UPDATE_ROLE_INFO';
export const FETCH_CHART_ERROR = 'FETCH_CHART_ERROR';
export const ANALYTICS_LOADING = 'ANALYTICS_LOADING';
export const FETCH_SEARCH_CHART_SUCCESS = 'FETCH_SEARCH_CHART_SUCCESS';
export const FETCH_SEARCH_ANALYTICS_SUCCESS = 'FETCH_SEARCH_ANALYTICS_SUCCESS';
export const FETCH_ANSWERS_ANALYTICS_SUCCESS =
  'FETCH_ANSWERS_ANALYTICS_SUCCESS';
export const FETCH_ANSWERS_CHART_SUCCESS = 'FETCH_ANSWERS_CHART_SUCCESS';
export const FETCH_SOLVE_CHART_SUCCESS = 'FETCH_SOLVE_CHART_SUCCESS';
export const FETCH_SOLVE_CHANNELS_SUCCESS = 'FETCH_SOLVE_CHANNELS_SUCCESS';
export const FETCH_SOLVE_CHANNELS_ERROR = 'FETCH_SOLVE_CHANNELS_ERROR';
export const SET_ORG_CODE = 'SET_ORG_CODE';
export const SET_DASHBOARD_APPLICATIONS_BY_ROLE =
  'SET_DASHBOARD_APPLICATIONS_BY_ROLE';

// V1
export const GET_ANSWERS_CHART_DATA_SUCCESS = 'GET_ANSWERS_CHART_DATA_SUCCESS';
export const GET_ANSWERS_BREAKDOWN_VALUES_SUCESS =
  'GET_ANSWERS_BREAKDOWN_VALUES_SUCESS';
export const GET_ANSWERS_TABLE_DATA_SUCCESS = 'GET_ANSWERS_TABLE_DATA_SUCCESS';
export const GET_SOLVE_TABLE_DATA_SUCCESS = 'GET_SOLVE_TABLE_DATA_SUCCESS';
export const GET_OVERVIEW_DATA_SUCCESS = 'GET_OVERVIEW_DATA_SUCCESS';
export const SET_SELECTED_CHANNEL = 'SET_SELECTED_CHANNEL';

export const GET_DATA_FAILURE = 'GET_DATA_FAILURE';
export const GET_ALL_REPORTS = 'GET_ALL_REPORTS';
export const GET_ALL_REPORTS_FAILURE = 'GET_ALL_REPORTS_FAILURE';
export const GET_REPORT = 'GET_REPORT';
export const GET_REPORT_FAILURE = 'GET_REPORT_FAILURE';
export const SET_FILTER_TYPE = 'SET_FILTER_TYPE';

// Triage Settings
export enum AnalyticsSettingsActions {
  GET_HELPDESK = 'GET_HELPDESK',
  GET_PREDICTED_FIELDS = 'GET_PREDICTED_FIELDS',
  GET_TRIAGE_MODEL = 'GET_TRIAGE_MODEL',
  GET_TRIAGE_MODELS = 'GET_TRIAGE_MODELS',
  POST_TRIAGE_MODEL_FIELDS_TO_PREDICT = 'POST_TRIAGE_MODEL_FIELDS_TO_PREDICT',
  UPDATE_TRIAGE_MODEL_STATUS = 'UPDATE_TRIAGE_MODEL_STATUS',
}

// Discover
export enum DiscoverActions {
  AUTOMATE_TOPIC = 'AUTOMATE_TOPIC',
  GET_ALL_TOPICS = 'GET_ALL_TOPICS',
  GET_ARTICLE_INTEGRATIONS = 'GET_ARTICLE_INTEGRATIONS',
  GET_AUTOMATIONS = 'GET_AUTOMATIONS',
  GET_CUSTOM_INTENTS = 'GET_CUSTOM_INTENTS',
  GET_DASHBOARD_BANNER_DATA = 'GET_DASHBOARD_BANNER_DATA',
  GET_DASHBOARD_METRICS = 'GET_DASHBOARD_METRICS',
  GET_FILTER_VALUES = 'GET_FILTER_VALUES',
  GET_KNOWLEDGE_BASE_SECTIONS = 'GET_KNOWLEDGE_BASE_SECTIONS',
  GET_KNOWLEDGE_GAP_RECOMMENDATIONS = 'GET_KNOWLEDGE_GAP_RECOMMENDATIONS',
  GET_METADATA = 'GET_METADATA',
  GET_RECOMMENDATION_DETAILS = 'GET_RECOMMENDATION_DETAILS',
  GET_RECOMMENDATIONS = 'GET_RECOMMENDATIONS',
  GET_TICKET_COST_INFO = 'GET_TICKET_COST_INFO',
  GET_TICKET_DETAILS = 'GET_TICKET_DETAILS',
  GET_TOPIC_METRICS = 'GET_TOPIC_METRICS',
  GET_TOPIC_TICKETS = 'GET_TOPIC_TICKETS',
  PATCH_ARTICLE = 'PATCH_ARTICLE',
  PATCH_TOPIC = 'PATCH_TOPIC',
  POST_REPORTED_TOPIC = 'POST_REPORTED_TOPIC',
  PUBLISH_ARTICLE = 'PUBLISH_ARTICLE',
  TOGGLE_WATCHING_TOPIC = 'TOGGLE_WATCHING_TOPIC',
}

// BREAKDOWNS
export const GET_BREAKDOWN_DATA_FAILURE = 'GET_BREAKDOWN_DATA_FAILURE';
export const TOGGLE_BREAKDOWN_FIELD = 'TOGGLE_BREAKDOWN_FIELD';
export const TOGGLE_SELECT_ALL_BREAKDOWNS = 'TOGGLE_SELECT_ALL_BREAKDOWNS';
export const TOGGLE_BREAKDOWN_VALUE = 'TOGGLE_BREAKDOWN_VALUE';
export const APPLY_BREAKDOWN_FIELD = 'APPLY_BREAKDOWN_FIELD';
export const DELETE_BREAKDOWN_FIELD = 'DELETE_BREAKDOWN_FIELD ';
export const CLEAR_SELECTED_BREAKDOWN = 'CLEAR_SELECTED_BREAKDOWN ';
export const GET_BREAKDOWN_FIELDS_SUCCESS = 'GET_BREAKDOWN_FIELDS_SUCCESS';

export enum PageSettingsActions {
  LOAD_Y_AXIS = 'load/y_axis',
  SET_BREAKDOWN_LOADING = 'set/breakdown_loading',
  SET_LOADING = 'set/loading',
  SET_MACRO_CONTROLS_VIEW_MODE = 'set/macro_controls_view_mode',
  SET_PAGE_NUMBER = 'set/page_number',
  SET_REPORT = 'set/report',
  SET_SOLVE_VIEW_MODE = 'set/solve_view_mode',
  SET_TIME_FRAME_FILTER = 'set/time_frame_filter',
  SET_TIME_RANGE = 'set/time_range',
  SET_VIEW_MODE = 'set/view_mode',
  SET_Y_AXIS = 'set/y_axis',
  SORT_TABLE = 'sort/table',
}

export enum FilterActions {
  CLEAN_FILTER_VALUES = 'clear/filter/values',
  CLEAR_FILTERS = 'cleat/filters',
  CLEAR_SELECTED_FILTER_VALUES = 'clear/clear_selected_filter_values',
  DELETE_FILTER = 'delete/filter',
  GET_FILTER_FAILURE = 'get/filter/failure',
  GET_FILTER_FIELDS_SUCCESS = 'set/filter_field_success',
  GET_FILTER_VALUES_SUCCESS = 'set/filter_values_success',
  SET_FILTER = 'set/filter',
  SET_FILTER_FIELD_NULL = 'set/filter_field_to_null',
  SET_FILTER_LOADING = 'set/filter_loading',
  TOGGLE_FILTER_VALUE = 'toggle/filter_value',
  TOGGLE_SELECTED_FILTER_FIELD = 'toggle/toggle_selected_filter_values',
}

export enum ToastNotificationActions {
  SET_TOAST_NOTIFICATION = 'set/toast_type',
}

export enum ReportsActions {
  CLEAR_CURRENT_REPORT = 'clear/current_report',
  SET_CURRENT_REPORT = 'set/current_report',
  SET_IS_REPORT_MODIFIED = 'set/is_report_modified',
  SET_UNSAVED_REPORT = 'set/unsaved_report',
}

export enum SuperAdminActions {
  GET_SEARCH_ORG_FAILURE = 'load/search_orgs_failure',
  GET_SEARCH_ORG_SUCCESS = 'load/search_orgs_success',
}

export enum MacroControlsActions {
  GET_MACRO_CONTROLS_DATA = 'get/macro_controls_data',
  GET_MACRO_CONTROLS_DATA_FAILURE = 'get/macro_controls_data_failure',
  SET_FILTERED_MACROS_DATA = 'set/filtered_macros_data',
  SET_MACRO_FIELD_TO_UPDATE = 'set/macro_field_to_update',
  SET_SELECTED_MACRO = 'set/selected_macro',
  UPDATE_MACRO_DATA = 'update/macro_data_success',
  UPDATE_MACRO_DATA_FAILURE = 'update/macro_data_failure',
}
