import { FormProps } from '@rjsf/core';

import CreateIntegrationForm from '../integration-form/create-integration-form/CreateIntegrationForm';
import UpdateIntegrationForm from '../integration-form/update-integration-form/UpdateIntegrationForm';
import {
  Connector,
  ConnectorDefinition,
  Credentials,
} from 'src/services/apiInterfaces';

interface IntegrationDrawerBodyProps {
  connectorDefinition: ConnectorDefinition;
  existingNames?: string[];
  formProps: FormProps<Credentials>;
  multiConnectorsEnabled: boolean;
  selectedConnector?: Connector;
}

export default function IntegrationDrawerBody({
  connectorDefinition,
  existingNames,
  formProps,
  multiConnectorsEnabled,
  selectedConnector,
}: IntegrationDrawerBodyProps) {
  if (selectedConnector) {
    return (
      <UpdateIntegrationForm
        existingNames={existingNames}
        multiConnectorsEnabled={multiConnectorsEnabled}
        selectedConnector={selectedConnector}
        {...formProps}
      />
    );
  } else {
    return (
      <CreateIntegrationForm
        connectorDefinition={connectorDefinition}
        existingNames={existingNames}
        multiConnectorsEnabled={multiConnectorsEnabled}
        oauthConnector={selectedConnector}
        {...formProps}
      />
    );
  }
}
