import { IconMicrophone } from '@tabler/icons-react';
import { IconMoodSmileBeam } from '@tabler/icons-react';
import { IconBulb } from '@tabler/icons-react';

import { PlaybookTabKeys } from './types';
import { bubbleAvatars } from 'src/components/dashboard-pages/integrations-page/integrations-page-header/utils';

export const PLAYBOOK_TABS: {
  icon: React.ReactNode;
  name: string;
  tab: PlaybookTabKeys;
}[] = [
  {
    icon: <IconBulb />,
    name: 'Knowledge',
    tab: 'knowledge',
  },
  {
    icon: <IconMicrophone />,
    name: 'Tone',
    tab: 'tone-of-voice',
  },
  {
    icon: <IconMoodSmileBeam />,
    name: 'Feedback',
    tab: 'quick-feedback',
  },
];

export const TONE_PREVIEW_TEXT =
  'Express your brand through tone of voice, establishing consistency to strengthen brand identity and foster connections with customers.';

export const FEEDBACK_PREVIEW_TEXT = `Personalize the feed's quick feedback using the menu on the right. 

Allow your agent to provide feedback on articles, and this data will be included in the insights along with article usage.`;

export const COMPANY_AVATARS = {
  confluence: bubbleAvatars.right[1],
  intercom: bubbleAvatars.left[3],
  readmeio: bubbleAvatars.right[3],
  salesforce: bubbleAvatars.left[0],
  snowflake: bubbleAvatars.left[1],
  zendesk: bubbleAvatars.right[2],
};
