import React, { useMemo, useState } from 'react';
import { useDrag } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import {
  Colors,
  FilterButton,
  SearchBar,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { CanvasModes, routeFilterOptions } from '../../constants';
import { useGetBuilderQueryParams } from '../../hooks';
import BaseDraggableCard from './BaseDraggableCard';
import partition from 'lodash/fp/partition';
import filterIcon from 'src/assets/images/action-type-filter.svg';
import Spinner from 'src/components/spinner';
import { conversationChannelToProductMap } from 'src/constants/solve';
import { useGetIntentsQueryWithProduct } from 'src/hooks/hooks';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { useGetSortedConnectableIntents } from 'src/pages/solve-config/hooks/useGetSortedConnectableIntents';
import intentDisabledIcon from 'src/pages/workflow-builder-edit/assets/icons/intent-disabled.svg';
import {
  selectChatOrderLastStepId,
  selectHandoffTooltip,
  selectIntentWorkflowId,
  selectIsRestartConversationDisabled,
  selectIsRouteIntentDisabled,
  selectRestartConversationTooltip,
  selectRouteIntentTooltip,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetHandoffConfigurationsQuery } from 'src/services/workflow-builder-metrics';
import {
  GoToIntentData,
  RestartStepData,
} from 'src/slices/canvas-workflow-builder/types/dataTypes';
import {
  CanvasWorkflowBuilderState,
  selectRouteFilter,
  setMode,
  setRouteFilter,
} from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { addGoToIntentStep } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice.thunks';
import { useAppDispatch } from 'src/store/hooks';
import { textBoldStyle } from 'src/styles/styledMixin';
import { IntentData } from 'src/types/workflowBuilderAPITypes';
import { RevampedDragNDrops, Routes, StepTypes } from 'src/utils/enums';
import { isDropValid } from 'src/utils/solve/stepUtils';

interface IntentRoutingTabContentProps {
  isDisabled: boolean;
  isShowingCustomHandoffIntents?: boolean;
}

const IntentRoutingTabContent: React.FC<
  React.PropsWithChildren<IntentRoutingTabContentProps>
> = ({ isDisabled, isShowingCustomHandoffIntents = false }) => {
  const { view } = useGetBuilderQueryParams();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const currentWorkflowId = useSelector(selectIntentWorkflowId);
  const { intents: routableIntents, isLoading: intentsIsLoading } =
    useGetSortedConnectableIntents({
      product: conversationChannelToProductMap[view],
    });
  const { data, isLoading: allIntentsLoading } = useGetIntentsQueryWithProduct(
    undefined,
    view === 'email' ? { productOverride: 'interactive_email' } : undefined,
  );
  const { intents: allIntents = [] } = data ?? {};
  const {
    data: handoffConfigurationsResponse,
    isLoading: handoffConfigurationsIsLoading,
  } = useGetHandoffConfigurationsQuery();
  const routeFilter = useSelector(selectRouteFilter);

  const isLoading =
    allIntentsLoading || handoffConfigurationsIsLoading || intentsIsLoading;
  const lowerCaseSearchText = searchText.toLowerCase();

  const intentsToRender = useMemo(() => {
    const handoffConfigurations =
      handoffConfigurationsResponse?.configurations ?? [];
    // add current intent to the routable intents if it doesn't exist
    if (
      !routableIntents.find(
        intent => intent.intent_workflow_id === currentWorkflowId,
      )
    ) {
      const currentIntent = allIntents.find(
        ({ intent_workflow_id: intentWorkflowId }) =>
          intentWorkflowId === currentWorkflowId,
      );

      if (currentIntent) {
        // is active status is false here since if it's active, it would be in the routable intents
        routableIntents.push({
          intent_name: currentIntent.intent_name,
          intent_workflow_id: currentIntent.intent_workflow_id,
          is_active: false,
        });
      }
    }

    // group the routable intents into handoffs and normal intents
    const [customHandoffIntents, normalIntents] = partition(
      intent =>
        handoffConfigurations.some(
          handoffConfiguration =>
            handoffConfiguration.intent_workflow_id ===
            intent.intent_workflow_id,
        ),
      routableIntents,
    );
    const groupedIntents = [...customHandoffIntents, ...normalIntents];

    const intentsToShow = groupedIntents
      .filter(({ intent_name: intentName }) => {
        return intentName.toLowerCase().includes(lowerCaseSearchText);
      })
      .map(intent => {
        const fullIntentDetails = allIntents.find(
          ({ intent_name: intentName, intent_workflow_id: intentWorkflowId }) =>
            intentWorkflowId === intent.intent_workflow_id &&
            intentName === intent.intent_name,
        );
        const isHandoff = handoffConfigurations.some(
          ({ intent_workflow_id: intentWorkflowId }) =>
            intentWorkflowId === intent.intent_workflow_id,
        );
        if (fullIntentDetails) {
          return {
            ...intent,
            is_autoflow: fullIntentDetails?.is_autoflow,
            is_handoff: isHandoff,
          };
        }

        return { ...intent, is_autoflow: false, is_handoff: isHandoff };
      })
      .filter(
        ({ is_autoflow: isAutoflow, is_handoff: isHandoff }) =>
          routeFilter === 'All' ||
          (routeFilter === 'Handoff Workflow' && isHandoff) ||
          (routeFilter === 'Workflow - Classic' && !isHandoff && !isAutoflow) ||
          (routeFilter === 'Workflow - AutoFlow' && !isHandoff && isAutoflow),
      )
      .sort((firstIntent, secondIntent) =>
        firstIntent?.intent_name?.localeCompare(secondIntent?.intent_name),
      );

    return intentsToShow;
  }, [
    allIntents,
    currentWorkflowId,
    lowerCaseSearchText,
    routableIntents,
    routeFilter,
    handoffConfigurationsResponse,
  ]);

  return (
    <Box>
      <Box
        alignItems='center'
        display='flex'
        gap={2}
        justifyContent='space-between'
        my={3}
        width={'100%'}
      >
        <Box width={'100%'}>
          <SearchBar
            onChange={e => setSearchText(e.target.value)}
            placeholder='Search'
            value={searchText}
          />
        </Box>
        <Box>
          <FilterButton
            aria-label='Route type'
            initialValue='All'
            onChange={newValue => {
              dispatch(
                setRouteFilter(
                  newValue as CanvasWorkflowBuilderState['routeFilter'],
                ),
              );
            }}
            options={routeFilterOptions}
            startAdornment={<img alt='' src={filterIcon} />}
            value={routeFilter}
          />
        </Box>
      </Box>
      <Box display='flex' flexDirection='column' gap={2} mt={2}>
        {!isShowingCustomHandoffIntents &&
          routeFilter === 'All' &&
          'Restart conversation'
            .toLowerCase()
            .includes(lowerCaseSearchText) && (
            <DraggableRestartConversationCard isDisabled={isDisabled} />
          )}
        {intentsToRender.map(intent => (
          <DraggableIntentCard
            allIntents={allIntents}
            intent={intent}
            isDisabled={isDisabled}
            key={intent.intent_workflow_id}
          />
        ))}
      </Box>
      {isLoading ? (
        <Spinner />
      ) : intentsToRender.length === 0 ? (
        <NoContentContainer>
          <Spacer height='85px' />
          <ReactSVG src={intentDisabledIcon} />
          <Spacer height='14px' />
          <NoContentDescription>No other intents</NoContentDescription>
          <Spacer height='110px' />
        </NoContentContainer>
      ) : null}
    </Box>
  );
};

const Container = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const NoContentDescription = styled('div')`
  ${textBoldStyle({
    color: Colors.ui.text.tertiary,
    fontSize: '18px',
  })};
  line-height: 26px;
  letter-spacing: -0.1px;
`;

const NoContentContainer = styled(Container)`
  justify-content: center;
  align-items: center;
`;

const Spacer = styled('div')<{ height?: string; width?: string }>`
  width: ${props => props.width || 0};
  height: ${props => props.height || 0};
`;

export default IntentRoutingTabContent;

const DraggableRestartConversationCard = ({
  isDisabled,
}: {
  isDisabled: boolean;
}) => {
  const { contextVariables } = useGetContextVariables();

  const dispatch = useAppDispatch();
  const lastStepId = useSelector(selectChatOrderLastStepId);
  const isRestartConversationDisabled = useSelector(
    selectIsRestartConversationDisabled,
  );
  const restartConversationTooltip = useSelector(
    selectRestartConversationTooltip,
  );

  const [{ opacity }, drag] = useDrag(
    () => ({
      canDrag: !isDisabled,
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.3 : 1,
      }),
      end: (_, monitor) => {
        if (!isDropValid(monitor.getDropResult())) {
          return;
        }

        if (monitor.didDrop()) {
          const restartStepData: RestartStepData = {
            is_entry_step: !lastStepId,
            parent_step_id: lastStepId,
            step_fields: {
              restart_message: 'Is there anything else I can help with today?',
              show_greeting_message: false,
              show_top_n_intents: true,
            },
            step_type: StepTypes.RESTART_CONVERSATION,
          };
          dispatch(addGoToIntentStep(restartStepData));
          dispatch(setMode({ contextVariables, mode: CanvasModes.MESSAGE }));
        }
      },
      type: RevampedDragNDrops.REVAMPED_FT_RESTART,
    }),
    [lastStepId, dispatch, contextVariables, isDisabled],
  );

  return (
    <Tooltip tooltipContent={restartConversationTooltip}>
      <BaseDraggableCard
        badgeText='Restart'
        drag={isRestartConversationDisabled ? undefined : drag}
        isActive
        label='Restart conversation'
        opacity={opacity}
        stepType={StepTypes.RESTART_CONVERSATION}
        width='351px'
      />
    </Tooltip>
  );
};

interface IntentType {
  intent_name: string;
  intent_workflow_id: string;
  is_active: boolean;
  is_autoflow?: boolean;
  is_handoff?: boolean;
}

interface DraggableIntentCardProps {
  allIntents: IntentData[];
  intent: IntentType;
  isDisabled: boolean;
}

const DraggableIntentCard = ({
  allIntents,
  intent,
  isDisabled,
}: DraggableIntentCardProps) => {
  const { contextVariables } = useGetContextVariables();
  const { view } = useGetBuilderQueryParams();

  const dispatch = useAppDispatch();
  const lastStepId = useSelector(selectChatOrderLastStepId);
  const isRouteIntentDisabled = useSelector(selectIsRouteIntentDisabled);
  const routeIntentTooltip = useSelector(
    intent.is_handoff ? selectHandoffTooltip : selectRouteIntentTooltip,
  );

  const [{ opacity }, drag] = useDrag(
    () => ({
      canDrag: !isDisabled,
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.3 : 1,
      }),
      end: (item, monitor) => {
        if (!isDropValid(monitor.getDropResult())) {
          return;
        }

        if (monitor.didDrop()) {
          const intentData: GoToIntentData = {
            is_entry_step: !lastStepId,
            parent_step_id: lastStepId,
            step_fields: {
              intent_workflow_id: item.intent_workflow_id,
            },
            step_type: StepTypes.GO_TO_INTENT,
          };
          dispatch(addGoToIntentStep(intentData));
          dispatch(setMode({ contextVariables, mode: CanvasModes.MESSAGE }));
        }
      },
      item: intent,
      type: RevampedDragNDrops.REVAMPED_FT_INTENT,
    }),
    [lastStepId, isDisabled],
  );

  const params = useMemo(() => {
    if (view === 'email') {
      const intentData = allIntents.find(
        ({ intent_workflow_id }) =>
          intent_workflow_id === intent.intent_workflow_id,
      );
      return new URLSearchParams({
        emailMode: 'Interactive',
        intentId: intentData?.intent_definition_id ?? '',
        view,
        workflowId: intent.intent_workflow_id,
      });
    }

    return new URLSearchParams({
      view,
      workflowId: intent.intent_workflow_id,
    });
  }, [view, intent.intent_workflow_id, allIntents]);

  const handoffLabel = 'Handoff Workflow';
  const intentLabel = `Workflow - ${
    intent.is_autoflow ? 'AutoFlow' : 'Classic'
  }`;
  return (
    <Tooltip tooltipContent={routeIntentTooltip}>
      <BaseDraggableCard
        badgeText={intent.is_handoff ? handoffLabel : intentLabel}
        drag={isRouteIntentDisabled ? undefined : drag}
        isActive={intent.is_active}
        label={
          <Link
            target='_blank'
            to={`${Routes.WORKFLOW_BUILDER_EDIT}?${params}`}
          >
            {intent.intent_name}
          </Link>
        }
        opacity={opacity}
        stepType={StepTypes.GO_TO_INTENT}
        width='351px'
      />
    </Tooltip>
  );
};
