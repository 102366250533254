import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateConnectorDisplayName } from 'src/slices/connectors/thunks';

interface UseSaveDisplayNameProps {
  connectorId: string;
  initialDisplayName: string;
}

export function useSaveDisplayName({
  connectorId,
  initialDisplayName,
}: UseSaveDisplayNameProps) {
  const dispatch = useDispatch();
  const [originalDisplayName, setOriginalDisplayName] =
    useState(initialDisplayName);
  const [currentDisplayName, setCurrentDisplayName] =
    useState(initialDisplayName);

  const handleSaveDisplayName = useCallback(() => {
    if (originalDisplayName !== currentDisplayName) {
      dispatch(
        updateConnectorDisplayName({
          connectorId,
          displayName: currentDisplayName,
        }),
      );
      setOriginalDisplayName(currentDisplayName);
    }
  }, [originalDisplayName, currentDisplayName, connectorId, dispatch]);

  const resetDisplayName = useCallback(() => {
    setCurrentDisplayName(originalDisplayName);
  }, [originalDisplayName]);

  return {
    currentDisplayName,
    handleSaveDisplayName,
    resetDisplayName,
    setCurrentDisplayName,
  };
}
