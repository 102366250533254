import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconArrowsDiagonal, IconDots } from '@tabler/icons-react';

import {
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { quickFeedbackOneToTwoOptions } from '../constants';
import lightningIcon from 'src/assets/images/lightning-icon.svg';
import zendeskLogoSrc from 'src/assets/images/zendesk-article-logo.svg';
import { selectWidgetConfiguration } from 'src/slices/solve-config/solveConfigSlice';

const QuickFeedbackPreview = () => {
  const { palette } = useTheme();
  const widgetConfiguration = useSelector(selectWidgetConfiguration);

  const {
    hover_label_bad: hoverLabelBad,
    hover_label_good: hoverLabelGood,
    is_enabled: isEnabled,
    style,
  } = widgetConfiguration.quick_feedback_config_playbook || {};
  const chosenStyleOptions = quickFeedbackOneToTwoOptions.find(
    option => option.value === style,
  );

  return (
    <Box display='flex' justifyContent='center'>
      <Box
        bgcolor={palette.grey[50]}
        borderRadius={1.5}
        display='flex'
        flexDirection='column'
        gap={1}
        p={1}
        sx={{
          border: `1px solid ${palette.colors.grey[100]}`,
        }}
        width='400px'
      >
        <Box alignItems='center' display='flex' justifyContent='space-between'>
          <Box display='flex' gap={0.5}>
            <img src={zendeskLogoSrc} width='24px' />
            <Box
              bgcolor={palette.colors.slate[100]}
              borderRadius={0.5}
              px={1}
              py={0.5}
            >
              <Typography color={palette.colors.grey[700]} variant='font14'>
                Articles
              </Typography>
            </Box>
          </Box>
          <Box
            display='flex'
            gap={0.5}
            sx={{
              svg: {
                path: {
                  stroke: palette.colors.purple[500],
                },
              },
            }}
          >
            <ReactSVG src={lightningIcon} />
            <IconArrowsDiagonal size={20} />
            <IconDots size={20} />
          </Box>
        </Box>
        <Typography variant='font18Bold'>Article title</Typography>
        <Typography variant='font16'>
          Reasons an account is changing and requires new owner in addition to
          current account own...
        </Typography>
        {isEnabled && (
          <Box display='flex' gap={2} mt={2}>
            <Box
              aria-label={hoverLabelGood}
              sx={{ cursor: 'pointer' }}
              width='24px'
            >
              <Tooltip placement='bottom' tooltipContent={hoverLabelGood || ''}>
                <IconWrapper>
                  <ReactSVG
                    src={chosenStyleOptions?.positiveIcons?.default || ''}
                    style={{ height: '24px', width: '24px' }}
                  />
                </IconWrapper>
              </Tooltip>
            </Box>
            <Box
              aria-label={hoverLabelBad}
              sx={{ cursor: 'pointer' }}
              width='24px'
            >
              <Tooltip placement='bottom' tooltipContent={hoverLabelBad || ''}>
                <IconWrapper>
                  <ReactSVG
                    src={chosenStyleOptions?.negativeIcons?.default || ''}
                    style={{ height: '24px', width: '24px' }}
                  />
                </IconWrapper>
              </Tooltip>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const IconWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  background-color: ${props => props.theme.palette.colors.slate[100]};

  svg {
    width: 24px;
    height: 24px;
  }
`;

export default QuickFeedbackPreview;
