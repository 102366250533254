import React, { useEffect, useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box } from '@mui/material';
import { styled } from '@mui/material';

import { Tabs } from '@forethought-technologies/forethought-elements';
import {
  Button,
  Drawer,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { ArticleFeedbackButtons } from '../article-feedback';
import GeneratedArticle from './GeneratedArticle';
import TicketSources from './TicketSources';
import { copyArticleToClipboard } from 'src/components/dashboard-pages/discover-topic-detail-page/helpers';
import { ARTICLE_DRAWER_TABS } from 'src/components/global-discover/constants';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import {
  ArticleIntegrations,
  DiscoverArticle,
  KnowledgeGapArticleRecommendation,
} from 'src/reducers/discoverReducer/types';
import { useGetArticleTicketsQuery } from 'src/services/discover/discoverApi';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

interface ArticleDrawerProps {
  articleId: string;
  articles: (DiscoverArticle | KnowledgeGapArticleRecommendation)[] | undefined;
  integrations: ArticleIntegrations | null;
  isBrandsEnabled?: boolean;
  onClickArticlePublishButton: () => void;
  setFeedBackArticleModalId: (articleId: string) => void;
  setSelectedArticleId: (newId: string) => void;
}

const ArticleDrawer: React.FC<React.PropsWithChildren<ArticleDrawerProps>> = ({
  articleId,
  articles,
  integrations,
  isBrandsEnabled,
  onClickArticlePublishButton,
  setFeedBackArticleModalId,
  setSelectedArticleId,
}) => {
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const [activeTab, setActiveTab] = useState(0);
  const {
    data: ticketsResponse,
    isError,
    isFetching,
    isLoading,
  } = useGetArticleTicketsQuery(articleId, { skip: !articleId });

  const ticketsLoading = isLoading || isFetching;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (articleId) {
      emitTrackingEventCallback('discover-generated-article-drawer-open');
    }
  }, [emitTrackingEventCallback, articleId]);

  if (!articles) {
    return null;
  }

  const articleIndex = articles.findIndex(
    article => article.article_id === articleId,
  );
  const article:
    | (KnowledgeGapArticleRecommendation | DiscoverArticle)
    | undefined = articles[articleIndex];

  const isArticlePublished = article?.state.includes('published');
  const shouldShowUploadButton =
    !isArticlePublished && Boolean(integrations?.connected_helpdesks?.length);

  const isTicketSourceSupported = Boolean(
    (article as KnowledgeGapArticleRecommendation)?.summary,
  );

  const tabs = isTicketSourceSupported
    ? ARTICLE_DRAWER_TABS
    : [ARTICLE_DRAWER_TABS[0]];

  const handleOnClose = () => {
    setSelectedArticleId('');
    setActiveTab(0);
  };

  const handleCopy = async () => {
    emitTrackingEventCallback('discover-generated-article-copied');

    await copyArticleToClipboard(article);

    dispatch(
      setGlobalToastOptions({
        autoHideDuration: 3000,
        title: 'Copied article to clipboard',
        variant: 'main',
      }),
    );
  };

  const handleUpload = () => {
    emitTrackingEventCallback('discover-generated-article-upload');

    onClickArticlePublishButton();
  };

  return (
    <Drawer
      footerContent={
        article && activeTab === 0 ? (
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
            mx={1}
          >
            <Box display='flex' gap={1}>
              {shouldShowUploadButton && (
                <Tooltip tooltipContent='Upload as a draft to knowledge center'>
                  <Button onClick={handleUpload} size='large' variant='main'>
                    Upload
                  </Button>
                </Tooltip>
              )}
              <Tooltip tooltipContent='Copy the article to your clipboard'>
                <Button onClick={handleCopy} size='large' variant='secondary'>
                  Copy
                </Button>
              </Tooltip>
              {article.helpdesk_article_url ? (
                <StyledAnchor href={article.helpdesk_article_url}>
                  <Button size='large' variant='secondary'>
                    Edit article in knowledge center
                    <OpenInNewIcon />
                  </Button>
                </StyledAnchor>
              ) : null}
            </Box>
            <ArticleFeedbackButtons
              article={article}
              buttonSize='large'
              setPatchedArticleModalId={setFeedBackArticleModalId}
            />
          </Box>
        ) : null
      }
      isOpen={Boolean(article)}
      onClose={handleOnClose}
    >
      {article ? (
        <Box
          display='flex'
          flexDirection='column'
          height='100%'
          rowGap={4}
          sx={{ code: { textWrap: 'wrap' }, overflowX: 'scroll' }}
        >
          <Box
            position='sticky'
            pt='40px'
            px='40px'
            sx={{ backgroundColor: 'white' }}
            top={0}
          >
            <Tabs
              a11yProps={index => ({
                'aria-controls': `tabpanel-${index}`,
                id: `main-tab-${index}`,
              })}
              aria-label='tabs to choose article or tickets'
              onChange={(_, tab) => {
                setActiveTab(tab);
              }}
              tabs={tabs}
              typographyVariant='font24'
              value={activeTab}
            />
          </Box>
          <Box px='40px'>
            {activeTab === 0 && (
              <GeneratedArticle
                article={article as KnowledgeGapArticleRecommendation}
                isBrandsEnabled={isBrandsEnabled}
              />
            )}
            {activeTab === 1 && (
              <TicketSources
                articleId={article.article_id}
                isError={isError}
                loading={ticketsLoading}
                tickets={ticketsResponse?.tickets}
              />
            )}
          </Box>
        </Box>
      ) : null}
    </Drawer>
  );
};

const StyledAnchor = styled('a')`
  text-decoration: none;
  flex: 1;
  white-space: nowrap;
`;

export default ArticleDrawer;
