import { Box, Typography, useTheme } from '@mui/material';

import PoweredByForethoughtIcon from 'src/assets/images/forethought-footer-logo.svg';

export default function PoweredByText() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: '1px',
        justifyContent: 'center',
      }}
    >
      <img alt='' height={16} src={PoweredByForethoughtIcon} />
      <Typography
        className='plus-jakarta-sans'
        sx={{
          '&:hover': {
            color: theme.palette.colors.grey[900],
          },
          color: theme.palette.colors.grey[700],
          fontSize: '10px',
          fontWeight: 600,
        }}
      >
        Powered by Forethought
      </Typography>
    </Box>
  );
}
