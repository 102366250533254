import { Box, styled, useTheme } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import {
  Button,
  Checkbox,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { isGeneralFallback, useGetDefaultIntentFilters } from '../utils';
import aaBadgeSrc from 'src/assets/images/autonomous-agent-badge.svg';
import { IntentFilter } from 'src/slices/workflow-preview/types';

export const IntentFilterRow = ({
  handleOnlyClicked,
  hideModeColumn,
  intentFilter,
  setIsAutoflow,
  setIsChecked,
  setIsDraft,
}: {
  handleOnlyClicked: (intentId: string) => void;
  hideModeColumn?: boolean;
  intentFilter: IntentFilter;
  setIsAutoflow: (intentId: string, val: boolean) => void;
  setIsChecked: (intentId: string, val: boolean) => void;
  setIsDraft: (
    intentId: string,
    val: boolean,
    isIntentDefaultAutoflow: boolean,
  ) => void;
}) => {
  const theme = useTheme();
  const { defaultIntentFiltersMapping } = useGetDefaultIntentFilters();

  // const for rendering
  const defaultIntentFilter =
    defaultIntentFiltersMapping[intentFilter.intentId];
  const isIntentDefaultDraft = Boolean(defaultIntentFilter?.isDraft);
  const isIntentDefaultAutoflow = Boolean(defaultIntentFilter?.isAutoflow);

  const shouldDisablePublishedOption = Boolean(!defaultIntentFilter?.isChecked);
  const shouldDisableClassicOption =
    !intentFilter.isDraft && isIntentDefaultAutoflow;
  const shouldDisableAutoflowOption =
    !intentFilter.isDraft && !isIntentDefaultAutoflow;

  return (
    <TableRow
      sx={{
        background: intentFilter.isChecked
          ? theme.palette.colors.purple[100]
          : theme.palette.colors.white,
      }}
    >
      <TableCell
        sx={{
          '& .MuiButton-root': {
            display: 'none',
          },
          '&:hover .MuiButton-root': {
            display: 'inline-flex',
          },
          '.MuiCheckbox-root': {
            marginRight: '8px',
            padding: 0,
          },

          border: 0,
          padding: '10px 16px',
        }}
      >
        <Box display='flex' justifyContent='space-between'>
          <Checkbox
            checked={intentFilter.isChecked}
            disabled={isGeneralFallback(intentFilter.intentId)}
            label={
              <Box alignItems='center' display='flex' gap='8px'>
                <Tooltip
                  tooltipContent={isIntentDefaultDraft ? 'Inactive' : 'Active'}
                >
                  <StatusIcon isEnabled={!isIntentDefaultDraft} />
                </Tooltip>

                <Typography variant='font14'>
                  {intentFilter.intentName}
                </Typography>
                {isIntentDefaultAutoflow && !hideModeColumn && (
                  <Tooltip tooltipContent='Autoflow'>
                    <img src={aaBadgeSrc} style={{ height: 20, width: 20 }} />
                  </Tooltip>
                )}
              </Box>
            }
            onChange={() => {
              setIsChecked(intentFilter.intentId, !intentFilter.isChecked);
            }}
          />
          {!isGeneralFallback(intentFilter.intentId) && (
            <Box
              sx={{
                '.MuiButtonBase-root': {
                  fontSize: '12px',
                  height: '20px',
                  padding: 0,
                },
                display: 'inline-flex',
              }}
            >
              <Button
                onClick={() => {
                  handleOnlyClicked(intentFilter.intentId);
                }}
                size='medium'
                variant='secondary'
              >
                Only
              </Button>
            </Box>
          )}
        </Box>
      </TableCell>
      <TableCell
        sx={{
          border: 0,
          padding: '10px 16px',
          width: '350px',
        }}
      >
        <CellContainer>
          <RadioButton
            checked={!intentFilter.isDraft}
            disabled={shouldDisablePublishedOption}
            onChange={() => {
              setIsDraft(intentFilter.intentId, false, isIntentDefaultAutoflow);
            }}
            type='radio'
          />
          <Typography
            color={
              shouldDisablePublishedOption
                ? theme.palette.colors.grey[400]
                : theme.palette.colors.black
            }
            component='span'
            variant='font14'
          >
            Published
          </Typography>
          <RadioButton
            checked={intentFilter.isDraft}
            onChange={() => {
              setIsDraft(intentFilter.intentId, true, isIntentDefaultAutoflow);
            }}
            type='radio'
          />
          <Typography component='span' variant='font14'>
            Draft
          </Typography>
        </CellContainer>
      </TableCell>

      {!hideModeColumn && (
        <TableCell
          sx={{
            border: 0,
            padding: '10px 16px',
            width: '250px',
          }}
        >
          <CellContainer>
            <RadioButton
              checked={!intentFilter.isAutoflow}
              disabled={shouldDisableClassicOption}
              onChange={() => {
                setIsAutoflow(intentFilter.intentId, false);
              }}
              type='radio'
            />
            <Typography
              color={
                shouldDisableClassicOption
                  ? theme.palette.colors.grey[400]
                  : theme.palette.colors.black
              }
              component='span'
              variant='font14'
            >
              Classic
            </Typography>
            <RadioButton
              checked={intentFilter.isAutoflow}
              disabled={shouldDisableAutoflowOption}
              onChange={() => {
                setIsAutoflow(intentFilter.intentId, true);
              }}
              type='radio'
            />
            <Typography
              color={
                shouldDisableAutoflowOption
                  ? theme.palette.colors.grey[400]
                  : theme.palette.colors.black
              }
              component='span'
              variant='font14'
            >
              Autoflow
            </Typography>
          </CellContainer>
        </TableCell>
      )}
    </TableRow>
  );
};

const RadioButton = styled('input')`
  appearance: none;
  background-color: ${props => props.theme.palette.colors.white};
  margin: 0;
  font: inherit;
  color: ${props => props.theme.palette.colors.purple[500]};
  width: 16px;
  height: 16px;
  border: 1px solid
    ${props =>
      props.disabled
        ? props.theme.palette.colors.grey[100]
        : !props.checked
        ? props.theme.palette.colors.grey[300]
        : props.theme.palette.colors.purple[500]};
  border-radius: 50%;
  transform: translateY(0.15em);
  display: inline-grid;
  place-content: center;

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 20px 20px
      ${props =>
        props.disabled
          ? props.theme.palette.colors.grey[300]
          : props.theme.palette.colors.purple[500]};
  }

  &:checked::before {
    transform: scale(1);
  }

  ${props => !props.disabled && 'cursor: pointer;'}
  ${props =>
    props.disabled && `background: ${props.theme.palette.colors.slate[100]}`}
`;

const CellContainer = styled('div')`
  display: flex;
  gap: 8px;
`;

const StatusIcon = styled('div')<{ isEnabled: boolean }>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${props =>
    props.isEnabled
      ? props.theme.palette.colors.green[500]
      : props.theme.palette.colors.grey[400]};
`;
