import { FetchAction } from '../../types/types';
import { AnalyticsState } from './types';
import {
  ANALYTICS_LOADING,
  FETCH_ANALYTICS_ERROR,
  FETCH_ANSWERS_ANALYTICS_SUCCESS,
  FETCH_ANSWERS_CHART_SUCCESS,
  FETCH_CHART_ERROR,
  FETCH_MODELS_ID_ERROR,
  FETCH_MODELS_ID_SUCCESS,
  FETCH_SEARCH_ANALYTICS_SUCCESS,
  FETCH_SEARCH_CHART_SUCCESS,
  FETCH_SOLVE_CHANNELS_ERROR,
  FETCH_SOLVE_CHANNELS_SUCCESS,
  FETCH_SOLVE_CHART_SUCCESS,
  SORT_MODELS,
} from 'src/actions/actionTypes';
import { AnswersReports, TablesToSort } from 'src/utils/enums';

export const initialState: AnalyticsState = {
  activeAgentsAggregate: [],
  activeAgentsBreakdown: [],
  analyticsLoading: false,
  answersAggregate: [],
  answersBreakdown: [],
  answersCardsTotals: {},
  answersMetricsEndTimestamp: null,
  answersMetricsFinalPageReached: false,
  answersMetricsStartTimestamp: null,

  app: '',

  channels: [],
  chartActiveAgentsAnswersMetrics: [],
  chartActiveAgentsAnswersMetricsData: [],
  chartEngagedTicketsAnswersMetrics: [],
  chartEngagedTicketsAnswersMetricsData: [],

  chartPredictionMetrics: [],
  chartPredictionMetricsData: [],
  chartSearchMetrics: [],
  chartSearchMetricsData: [],
  chartSolveData: [],
  chartSolveMetrics: [],
  dataTypes: {},
  error: null,
  modelName: '',
  modelsId: null,
  predictionsBreakdown: [],
  predictionsEndTimestamp: null,
  predictionsFinalPageReached: false,
  predictionsMetrics: [],
  predictionsStartTimestamp: null,
  productSorted: '',
  searchAggregate: [],
  searchBreakdown: [],
  searchMetricsEndTimestamp: null,
  searchMetricsFinalPageReached: false,
  searchMetricsStartTimestamp: null,
  solveAggregate: [],
  solveBreakdown: [],
  solveMetricsEndTimestamp: 0,
  solveMetricsFinalPageReached: false,
  solveMetricsStartTimestamp: 0,
  sortAsc: false,
  sortType: '',
  y: [],
};

export default function analyticsReducer(
  state = initialState,
  action: FetchAction,
) {
  if (action.type === FETCH_SEARCH_ANALYTICS_SUCCESS) {
    let searchBreakdown;
    const searchMetricsFinalPageReached = state.searchMetricsFinalPageReached;
    if (
      state.searchMetricsStartTimestamp ===
        action.searchMetricsStartTimestamp &&
      state.searchMetricsEndTimestamp === action.searchMetricsEndTimestamp
    ) {
      searchBreakdown = state.searchBreakdown;
    } else {
      searchBreakdown = [];
      while (action.searchBreakdown.length) {
        searchBreakdown.push(action.searchBreakdown.splice(0, 50));
      }
    }
    return Object.assign({}, state, {
      error: null,
      searchAggregate: action.searchAggregate,
      searchBreakdown: searchBreakdown,
      searchMetricsEndTimestamp: action.searchMetricsEndTimestamp,
      searchMetricsFinalPageReached: searchMetricsFinalPageReached,
      searchMetricsStartTimestamp: action.searchMetricsStartTimestamp,
    });
  } else if (action.type === FETCH_ANSWERS_ANALYTICS_SUCCESS) {
    let answersBreakdown: any;
    const answersMetricsFinalPageReached = state.answersMetricsFinalPageReached;
    if (
      state.answersMetricsStartTimestamp ===
        action.answersMetricsStartTimestamp &&
      state.answersMetricsEndTimestamp === action.answersMetricsEndTimestamp
    ) {
      answersBreakdown = state.activeAgentsBreakdown;
    } else {
      answersBreakdown = [];
      while (action.answersBreakdown.length) {
        answersBreakdown.push(action.answersBreakdown.splice(0, 50));
      }
    }
    if (action.yAxis === AnswersReports.ACTIVE_AGENTS) {
      const totals = { ...state.answersCardsTotals };
      totals.active_agents = action.totals;
      return Object.assign({}, state, {
        activeAgentsAggregate: action.answersAggregate,
        activeAgentsBreakdown: answersBreakdown,
        answersCardsTotals: totals,
        answersMetricsEndTimestamp: action.answersMetricsEndTimestamp,
        answersMetricsFinalPageReached: answersMetricsFinalPageReached,
        answersMetricsStartTimestamp: action.answersMetricsStartTimestamp,
        dataTypes: action.dataTypes,
        error: null,
      });
    }
  } else if (action.type === FETCH_SOLVE_CHANNELS_SUCCESS) {
    return Object.assign({}, state, {
      channels: action.channels,
      error: null,
    });
  } else if (action.type === FETCH_SOLVE_CHANNELS_ERROR) {
    return Object.assign({}, state, {
      error: action.error,
    });
  } else if (action.type === SORT_MODELS) {
    const productSorted = action.productSorted;
    let sortedData = [];
    const data: any = [];
    let comparison = 0;
    let objectA: any;
    let objectB: any;
    const sortingTypes = [
      'field_value',
      'actual_count',
      'predicted_count',
      'recall',
      'precision',
      'open_rate',
      'frequency',
      'engaged_count',
      'total_count',
      'engaged_ratio',
      'accuracy',
      'pending',
      'attempts',
      'resolutions',
      'deflect_rate',
      'dropoffs',
    ];
    if (productSorted === TablesToSort.PREDICTIONS) {
      const predictionsFinalPageReached = state.predictionsFinalPageReached;
      sortedData = state.predictionsBreakdown.flat(1).sort((a: any, b: any) => {
        sortingTypes.map((sortingType: string) => {
          if (sortingType === action.sortType) {
            objectA = a[sortingType];
            objectB = b[sortingType];
            if (isNaN(Number(objectA))) {
              objectA = a[sortingType].toUpperCase();
            }
            if (isNaN(Number(objectB))) {
              objectB = b[sortingType].toUpperCase();
            }
          }
        });
        comparison = action.sortAsc
          ? objectA < objectB
            ? -1
            : 1
          : objectA < objectB
          ? -1 * -1
          : 1 * -1;
        return comparison;
      });
      while (sortedData.length) {
        data.push(sortedData.splice(0, 50));
      }
      return Object.assign({}, state, {
        error: null,
        modelName: action.modelName,
        predictionsBreakdown: data,
        predictionsEndTimestamp: state.predictionsEndTimestamp,
        predictionsFinalPageReached: predictionsFinalPageReached,
        predictionsMetrics: state.predictionsMetrics,
        predictionsStartTimestamp: state.predictionsStartTimestamp,
        productSorted: action.productSorted,
        sortAsc: action.sortAsc,
        sortType: action.sortType,
      });
    } else if (productSorted === TablesToSort.SEARCH) {
      const searchMetricsFinalPageReached = state.searchMetricsFinalPageReached;
      sortedData = state.searchBreakdown.flat(1).sort((a: any, b: any) => {
        sortingTypes.map((sortingType: string) => {
          if (sortingType === action.sortType) {
            objectA = a[sortingType];
            objectB = b[sortingType];
            if (isNaN(Number(objectA))) {
              objectA = a[sortingType].toUpperCase();
            }
            if (isNaN(Number(objectB))) {
              objectB = b[sortingType].toUpperCase();
            }
          }
        });
        comparison = action.sortAsc
          ? objectA < objectB
            ? -1
            : 1
          : objectA < objectB
          ? -1 * -1
          : 1 * -1;
        return comparison;
      });
      while (sortedData.length) {
        data.push(sortedData.splice(0, 50));
      }
      return Object.assign({}, state, {
        error: null,
        productSorted: action.productSorted,
        searchAggregate: state.searchAggregate,
        searchBreakdown: data,
        searchMetricsEndTimestamp: state.searchMetricsEndTimestamp,
        searchMetricsFinalPageReached: searchMetricsFinalPageReached,
        searchMetricsStartTimestamp: state.searchMetricsStartTimestamp,
        sortAsc: action.sortAsc,
        sortType: action.sortType,
      });
    } else if (productSorted === TablesToSort.ANSWERS) {
      const answersMetricsFinalPageReached =
        state.answersMetricsFinalPageReached;
      sortedData = state.activeAgentsBreakdown
        .flat(1)
        .sort((a: any, b: any) => {
          sortingTypes.map((sortingType: string) => {
            if (sortingType === action.sortType) {
              objectA = a[sortingType];
              objectB = b[sortingType];
              if (isNaN(Number(objectA))) {
                objectA = a[sortingType].toUpperCase();
              }
              if (isNaN(Number(objectB))) {
                objectB = b[sortingType].toUpperCase();
              }
            }
          });
          comparison = (action.sortAsc ? 1 : -1) * (objectA < objectB ? -1 : 1);
          return comparison;
        });
      while (sortedData.length) {
        data.push(sortedData.splice(0, 50));
      }
      return Object.assign({}, state, {
        activeAgentsAggregate: state.activeAgentsAggregate,
        activeAgentsBreakdown: data,
        answersMetricsEndTimestamp: state.answersMetricsEndTimestamp,
        answersMetricsFinalPageReached: answersMetricsFinalPageReached,
        answersMetricsStartTimestamp: state.answersMetricsStartTimestamp,
        error: null,
        productSorted: action.productSorted,
        sortAsc: action.sortAsc,
        sortType: action.sortType,
      });
    } else if (productSorted === TablesToSort.SOLVE) {
      const solveMetricsFinalPageReached = state.solveMetricsFinalPageReached;
      sortedData = state.solveBreakdown.flat(1).sort((a: any, b: any) => {
        sortingTypes.map((sortingType: string) => {
          if (sortingType === action.sortType) {
            objectA = a[sortingType];
            objectB = b[sortingType];
            if (isNaN(Number(objectA))) {
              objectA = a[sortingType].toUpperCase();
            }
            if (isNaN(Number(objectB))) {
              objectB = b[sortingType].toUpperCase();
            }
          }
        });
        comparison = (action.sortAsc ? 1 : -1) * (objectA < objectB ? -1 : 1);
        return comparison;
      });
      while (sortedData.length) {
        data.push(sortedData.splice(0, 50));
      }
      return Object.assign({}, state, {
        error: null,
        productSorted: action.productSorted,
        solveAggregate: state.solveAggregate,
        solveBreakdown: data,
        solveMetricsEndTimestamp: state.solveMetricsEndTimestamp,
        solveMetricsFinalPageReached: solveMetricsFinalPageReached,
        solveMetricsStartTimestamp: state.solveMetricsStartTimestamp,
        sortAsc: action.sortAsc,
        sortType: action.sortType,
      });
    } else if (productSorted === TablesToSort.MACRO_CONTROLS) {
      return Object.assign({}, state, {
        productSorted: action.productSorted,
        sortAsc: action.sortAsc,
        sortType: action.sortType,
      });
    }
  } else if (action.type === FETCH_ANALYTICS_ERROR) {
    if (action.app === 'predictions') {
      return Object.assign({}, state, {
        error: action.error,
        predictionsBreakdown: [],
        predictionsMetrics: [],
      });
    } else if (action.app === 'search') {
      return Object.assign({}, state, {
        error: action.error,
        searchAggregate: [],
        searchBreakdown: [],
      });
    } else if (action.app === 'answers') {
      return Object.assign({}, state, {
        activeAgentsAggregate: [],
        activeAgentsBreakdown: [],
        error: action.error,
      });
    } else if (action.app === 'solve') {
      return Object.assign({}, state, {
        error: action.error,
        solveAggregate: [],
        solveBreakdown: [],
      });
    }
  } else if (action.type === FETCH_CHART_ERROR) {
    if (action.app === 'predictions') {
      return Object.assign({}, state, {
        chartPredictionMetrics: [],
        chartPredictionMetricsData: [],
        error: action.error,
      });
    } else if (action.app === 'search') {
      return Object.assign({}, state, {
        chartSearchMetrics: [],
        chartSearchMetricsData: [],
        error: action.error,
      });
    } else if (action.app === 'answers') {
      return Object.assign({}, state, {
        chartActiveAgentsAnswersMetrics: [],
        chartActiveAgentsAnswersMetricsData: [],
        chartEngagedTicketsAnswersMetrics: [],
        chartEngagedTicketsAnswersMetricsData: [],
        error: action.error,
      });
    } else if (action.app === 'solve') {
      return Object.assign({}, state, {
        chartSolveData: [],
        chartSolveMetrics: [],
        error: action.error,
      });
    }
  } else if (action.type === FETCH_MODELS_ID_SUCCESS) {
    return Object.assign({}, state, {
      error: null,
      modelsId: action.modelsId,
    });
  } else if (action.type === FETCH_SEARCH_CHART_SUCCESS) {
    return Object.assign({}, state, {
      chartSearchMetrics: action.chartSearchMetrics,
      chartSearchMetricsData: action.chartSearchMetricsData,
      error: null,
    });
  } else if (action.type === FETCH_ANSWERS_CHART_SUCCESS) {
    if (action.chart === AnswersReports.ENGAGED_TICKETS) {
      const totals = { ...state.answersCardsTotals };
      totals.engaged_tickets = action.totals;
      return Object.assign({}, state, {
        answersCardsTotals: totals,
        chartEngagedTicketsAnswersMetrics: action.chartAnswersMetrics,
        chartEngagedTicketsAnswersMetricsData: action.chartAnswersMetricsData,
        dataTypes: action.dataTypes,
        error: null,
        y: action.y,
      });
    } else if (action.chart === AnswersReports.ACTIVE_AGENTS) {
      const totals = { ...state.answersCardsTotals };
      totals.active_agents = action.totals;
      return Object.assign({}, state, {
        answersCardsTotals: totals,
        chartActiveAgentsAnswersMetrics: action.chartAnswersMetrics,
        chartActiveAgentsAnswersMetricsData: action.chartAnswersMetricsData,
        dataTypes: action.dataTypes,
        error: null,
        y: action.y,
      });
    }
  } else if (action.type === FETCH_SOLVE_CHART_SUCCESS) {
    return Object.assign({}, state, {
      chartSolveData: action.chartSolveData,
      chartSolveMetrics: action.chartSolveMetrics,
      dataTypes: action.dataTypes,
      error: null,
      y: action.y,
    });
  } else if (action.type === FETCH_MODELS_ID_ERROR) {
    return Object.assign({}, state, {
      error: action.error,
    });
  } else if (action.type === ANALYTICS_LOADING) {
    return Object.assign({}, state, {
      analyticsLoading: action.analyticsLoading,
    });
  }

  return state;
}
