import { memo, useCallback, useMemo, useState } from 'react';
import {
  type MRT_TableOptions,
  MRT_VisibilityState,
} from 'material-react-table';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import TuneIcon from '@mui/icons-material/Tune';
import { Box, useTheme } from '@mui/material';

import {
  Button,
  DateRangeFilterButton,
  InfiniteTable,
  Order,
  SearchBar,
  Typography,
} from '@forethought-technologies/forethought-elements';
import InsightDownloadCsv from '../common/InsightDownloadCsv';
import { initialInsightParams, INTENT_FILTER_PARAM_NAME } from '../constants';
import {
  dateRangeToTimestamp,
  sortDirectionToInsightSortDirection,
} from '../helpers';
import { useGetFilterCount } from '../hooks/useGetFilterCount';
import { useGetIntentsForFilter } from '../hooks/useGetIntentsForFilter';
import { useGetSearchQuery } from '../hooks/useGetSearchQuery';
import { InsightSortDirection, InsightsTab } from '../types';
import {
  initialInsightWorkflowParams,
  InsightWorkflowSortColumns,
  TABLE_COLUMN_TO_SORT_MAP,
  WORKFLOW_TABLE_FILTER_OPTIONS,
} from './constants';
import {
  buildWorkflowColumns,
  filterCommonIntentWorkflowsToTop,
  WorkflowTableDataFilter,
} from './helpers';
import { WorkflowDropdownMenu } from './WorkflowDropdownMenu';
import GlobalFilterDrawer from 'src/components/global-filter-drawer';
import {
  listCVObjectDeserialize,
  listCVObjectSerialize,
} from 'src/components/global-filter-drawer/helper';
import { ContextVariableFilterItemValue } from 'src/components/global-filter-drawer/types';
import {
  useGetIntentsQueryWithProduct,
  useGetWorkflowTags,
  useStateParams,
} from 'src/hooks/hooks';
import {
  DEFLECTION_INSIGHT_FILTER_OPTIONS,
  getInsightsFilterOptions,
} from 'src/pages/intent-conversation-analytics/constants';
import { buildQueryFromMultiSelected } from 'src/pages/intent-conversation-analytics/hooks/useConversations';
import useGetCSATColor from 'src/pages/intent-conversation-analytics/hooks/useGetCSATColor';
import useIsDeflectionInsightsEnabled from 'src/pages/intent-conversation-analytics/hooks/useIsDeflectionInsightsEnabled';
import {
  useGetKrWorkflowTableQuery,
  useGetWorkflowOverallDeflectionsQuery,
  useGetWorkflowTableQuery,
} from 'src/services/insights';
import { InsightWorkflow } from 'src/services/insights/types';
import { DateRange } from 'src/types/types';
import { ConversationChannel } from 'src/types/workflowBuilderAPITypes';
import {
  dateRangeToTimeFilter,
  genericSerializeAndDeserialize,
  listDeserialize,
  listSerialize,
} from 'src/utils/discover/helpers';
import {
  CommonIntentWorkflowType,
  ExportableTableType,
  Routes,
} from 'src/utils/enums';
import { datePickerRangeOptionsRevamp } from 'src/utils/timeRangeHelpers';

interface WorkflowsTableProps {
  dateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
  tab: InsightsTab;
}

const WorkflowsTable = ({
  dateRange,
  setDateRange,
  tab,
}: WorkflowsTableProps) => {
  const [searchParams] = useSearchParams();
  const isDeflectionInsightsEnabled = useIsDeflectionInsightsEnabled();
  const workflowTags = useGetWorkflowTags();
  const navigate = useNavigate();
  const { palette } = useTheme();
  // State params
  const deserializeSortDirection = useCallback(
    (param: string) => (param === 'ASC' ? 'ASC' : 'DESC'),
    [],
  );

  const deserializeSortColumn = useCallback(
    (param: string) => param as InsightWorkflowSortColumns,
    [],
  );
  const [sortDirection, setSortDirection] =
    useStateParams<InsightSortDirection>({
      deserialize: deserializeSortDirection,
      initialState: initialInsightWorkflowParams.workflowColumnSortDirection,
      paramsName: 'workflow_sort_direction',
      serialize: String,
    });
  const [sortColumn, setSortColumn] =
    useStateParams<InsightWorkflowSortColumns>({
      deserialize: deserializeSortColumn,
      initialState: initialInsightWorkflowParams.workflowColumnSort,
      paramsName: 'workflow_sort_column',
      serialize: String,
    });
  const [searchQuery, setSearchQuery] = useStateParams<string>({
    deserialize: genericSerializeAndDeserialize,
    initialState: '',
    paramsName: 'workflows_search',
    serialize: genericSerializeAndDeserialize,
  });
  const [channels, setChannels] = useStateParams<string[]>({
    deserialize: listDeserialize,
    initialState: [],
    paramsName: 'channels',
    serialize: listSerialize,
  });
  const [multiSelected, setMultiSelected] = useStateParams<string[]>({
    deserialize: listDeserialize,
    initialState: [],
    paramsName: `${INTENT_FILTER_PARAM_NAME}_${tab}`,
    serialize: listSerialize,
  });
  const [cvFilters, setCvFilters] = useStateParams<
    ContextVariableFilterItemValue[]
  >({
    deserialize: listCVObjectDeserialize,
    initialState: [],
    paramsName: `${INTENT_FILTER_PARAM_NAME}_cv_${tab}`,
    serialize: listCVObjectSerialize,
  });

  const timestamps = dateRangeToTimestamp(dateRange);
  const timeFilter = dateRangeToTimeFilter(dateRange);

  const { setUiQuery, uiQuery } = useGetSearchQuery({
    scope: 'workflow',
    searchQuery,
    setSearchQuery,
    tab: 'workflow',
    type: 'workflow',
  });
  const getCSATColor = useGetCSATColor();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    Object.fromEntries(
      initialInsightParams.articleColumns.map(column => [column, true]),
    ),
  );

  const multiSelectFilter = buildQueryFromMultiSelected(multiSelected);
  const tableRequestParams = useMemo(
    () => ({
      channels: buildQueryFromMultiSelected(channels)?.channels,
      data_export_type: ExportableTableType.INSIGHT_WORKFLOWS_TABLE,
      end: timestamps.end_timestamp,
      sort_column: sortColumn,
      sort_direction: sortDirection,
      start: timestamps.start_timestamp,
      tab: tab,
      ...(multiSelectFilter.types && {
        types: multiSelectFilter.types,
      }),
      user_query: searchQuery,
      ...(multiSelectFilter.activate?.length === 1 && {
        activate: multiSelectFilter.activate[0],
      }),
      ...buildQueryFromMultiSelected(multiSelected),
      cv_filters: cvFilters,
    }),
    [
      timestamps.end_timestamp,
      sortColumn,
      sortDirection,
      timestamps.start_timestamp,
      searchQuery,
      multiSelectFilter,
      channels,
      tab,
      multiSelected,
      cvFilters,
    ],
  );

  const {
    data: tableData,
    isError: isTableError,
    isFetching: isTableFetching,
    isLoading: isTableDataLoading,
  } = useGetWorkflowTableQuery({
    ...tableRequestParams,
  });
  const {
    data: krTableData,
    isFetching: isKrTableFetching,
    isLoading: isKrTableDataLoading,
  } = useGetKrWorkflowTableQuery({
    ...tableRequestParams,
  });

  const isKrLoading = isKrTableDataLoading || isKrTableFetching;

  const {
    data: workflowOverallDeflections,
    isLoading: isWorkflowOverallDeflectionsLoading,
  } = useGetWorkflowOverallDeflectionsQuery({
    end: timestamps.end_timestamp,
    start: timestamps.start_timestamp,
  });
  const costPerTicket = workflowOverallDeflections?.misc.cost_per_ticket;

  const initialSorting = useMemo(
    () => [
      {
        desc: sortDirection === 'DESC',
        id: sortColumn,
      },
    ],
    [sortColumn, sortDirection],
  );

  const getWorkflowTableDownloadUrl = useCallback(() => {
    const url = new URL(`${API_URL}data-export`);
    return url.href;
  }, []);

  const rows: InsightWorkflow[] = useMemo(() => {
    if (!tableData?.data) {
      return [];
    }

    const filter = new WorkflowTableDataFilter(tableData?.data);
    filter.filterByType(multiSelected);

    // Get the knowledge retrieval data and merge
    filter.mergeKrData(isKrLoading, krTableData?.data ?? []);
    // Get data from table state
    const filteredTable = filter.fetchData();
    // always show knowledge retrieval and handoff workflows on top
    return filterCommonIntentWorkflowsToTop(filteredTable);
  }, [isKrLoading, multiSelected, tableData?.data, krTableData?.data]);

  const columns = useMemo(
    () => buildWorkflowColumns(palette, navigate, getCSATColor, costPerTicket),
    [palette, navigate, getCSATColor, costPerTicket],
  );

  const channel: ConversationChannel = 'widget';
  const { data: intentsData } = useGetIntentsQueryWithProduct();
  const intentsForFilter = useGetIntentsForFilter({ channel, intentsData });

  const filterCount = useGetFilterCount({
    channels,
    cvFilters,
    multiSelected,
    workflowTags,
  });

  const handleResetAllFilter = useCallback(() => {
    setDateRange(initialInsightParams.date);
    setUiQuery('');
    setSortColumn(initialInsightParams.workflowColumnSort, () =>
      setMultiSelected([], () => setChannels([], () => setCvFilters([]))),
    );
  }, [
    setDateRange,
    setMultiSelected,
    setSortColumn,
    setUiQuery,
    setChannels,
    setCvFilters,
  ]);

  const initialTimeFilter = dateRangeToTimeFilter(initialInsightParams.date);

  const showLoadingSkeleton =
    isTableDataLoading || isWorkflowOverallDeflectionsLoading;

  const areFiltersUsed = Boolean(
    uiQuery.length ||
      timeFilter.key !== initialTimeFilter.key ||
      (channels.length && channels.length !== 3) ||
      multiSelected.length ||
      cvFilters.length,
  );

  const handleSortCallback = useCallback(
    (property: keyof InsightWorkflow, order: Order) => {
      setPage(1);
      setSortDirection(sortDirectionToInsightSortDirection(order));
      const updatedSortColumn =
        TABLE_COLUMN_TO_SORT_MAP.workflow[property] ??
        initialInsightWorkflowParams.workflowColumnSort;
      setSortColumn(updatedSortColumn);
    },
    [setSortColumn, setSortDirection],
  );

  const filterOptions = useMemo(() => {
    const extraFilters = [...WORKFLOW_TABLE_FILTER_OPTIONS];

    if (tab !== 'workflow') {
      extraFilters.push({
        label: 'All workflows',
        options: intentsForFilter,
        value: 'workflow_id',
      });
    }

    if (isDeflectionInsightsEnabled) {
      extraFilters.push(...DEFLECTION_INSIGHT_FILTER_OPTIONS);
    }

    return getInsightsFilterOptions({
      extraFilters,
      hideWorkflowTypes: tab === 'workflow',
    });
  }, [intentsForFilter, isDeflectionInsightsEnabled, tab]);

  const tableOptions = useMemo<MRT_TableOptions<InsightWorkflow>>(
    () => ({
      columns: columns,
      data: rows,
      displayColumnDefOptions: {
        'mrt-row-actions': {
          // TODO: ask Design Team if we want to use the default `Actions` text.
          // Note that the default `Actions` text is misaligned vertically.
          header: '',
          muiTableBodyCellProps: {
            align: 'center',
          },
          muiTableHeadCellProps: {
            align: 'center',
          },
          size: 50,
        },
      },
      enableColumnActions: false,
      enableColumnFilters: false,
      enableExpanding: false,
      enableFullScreenToggle: false,
      enableGlobalFilter: false,
      enableRowActions: true,
      initialState: {
        columnPinning: {
          left: ['intent_title', 'is_active'],
          right: ['mrt-row-actions'],
        },
        density: 'spacious',
      },
      muiTableBodyCellProps: ({ column, row }) => {
        const { intent_definition_id: intentDefinitionId } = row.original;
        const isKnowledge =
          intentDefinitionId === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE;
        const isFallback =
          intentDefinitionId === CommonIntentWorkflowType.FALLBACK;

        function getBackgroundColor() {
          if (row.getIsSelected()) {
            return palette.colors.purple[100];
          }

          if (column.getIsPinned()) {
            return palette.colors.blue[50];
          }

          if (isKnowledge || isFallback) {
            return '#DBBCFB12';
          }

          return 'inherit';
        }

        return {
          sx: {
            '::before, ::after': {
              backgroundColor: 'initial !important',
            },
            backgroundColor: getBackgroundColor(),
            fontWeight: row.getCanExpand() ? 'bold' : 'normal',
            opacity: 'initial',
          },
        };
      },
      muiTableBodyRowProps: ({ row }) => ({
        onClick: () => {
          if (row.original.is_loading) {
            return;
          }
          const pathName = Routes.SOLVE_INSIGHTS_WORKFLOW_DETAIL.replace(
            ':workflowId',
            row.original.intent_workflow_id,
          );
          const tab = searchParams.get('tab');

          navigate({
            pathname: pathName,
            search: `?tab=${tab}&channels=${channels.join(',')}`,
          });
        },
        sx: {
          cursor: 'pointer',
        },
      }),
      onColumnVisibilityChange: setColumnVisibility,
      renderRowActions: ({ row }) => (
        <WorkflowDropdownMenu data={row.original} />
      ),
      renderTopToolbarCustomActions: () => (
        <Box display='flex' gap='8px' paddingTop='4px' width='100%'>
          <Box key={1} width='200px'>
            <SearchBar
              onChange={e => setUiQuery(e.target.value)}
              placeholder='Search workflow'
              size='small'
              value={uiQuery}
            />
          </Box>
          <DateRangeFilterButton
            explicitLabel={true}
            initialValue={initialInsightParams.date}
            onChange={value => setDateRange(value)}
            options={datePickerRangeOptionsRevamp}
            value={dateRange}
          />
          <Button
            onClick={() => setIsDrawerOpen(true)}
            size='medium'
            startIcon={<TuneIcon />}
            variant='secondary'
          >
            <Typography noWrap variant='font14Medium'>
              {filterCount ? `All filters (${filterCount})` : 'All filters'}
            </Typography>
          </Button>
          {areFiltersUsed && (
            <Button
              onClick={handleResetAllFilter}
              size='medium'
              variant='ghost'
            >
              <Typography noWrap variant='font14Medium'>
                Reset filters
              </Typography>
            </Button>
          )}
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
            }}
          >
            <InsightDownloadCsv
              filename='workflows.csv'
              requestData={{
                ...tableRequestParams,
              }}
              url={getWorkflowTableDownloadUrl()}
            />
          </Box>
        </Box>
      ),
      state: {
        columnVisibility,
      },
    }),
    [
      columns,
      columnVisibility,
      uiQuery,
      dateRange,
      setUiQuery,
      setDateRange,
      getWorkflowTableDownloadUrl,
      navigate,
      areFiltersUsed,
      handleResetAllFilter,
      palette,
      channels,
      tableRequestParams,
      rows,
      searchParams,
      filterCount,
    ],
  );

  return (
    <>
      {isDrawerOpen && (
        <GlobalFilterDrawer
          filterType='chat'
          handleReset={handleResetAllFilter}
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          otherFiltersOptions={filterOptions}
          setUiQuery={setUiQuery}
          tab={tab}
          uiQuery={uiQuery}
        />
      )}
      <InfiniteTable
        hasNextPage={typeof tableData?.metadata.next_page === 'number'}
        initialSorting={initialSorting}
        isError={isTableError}
        isLoadingFirstPage={showLoadingSkeleton}
        isLoadingNextPage={isTableFetching}
        onLoadMore={() => {
          const nextPage = page + 1;
          setPage(nextPage);
        }}
        onSortCallback={handleSortCallback}
        stickyHeaderHeight={0}
        tableOptions={tableOptions}
      />
    </>
  );
};

const MemoizedWorkflowsTable = memo(WorkflowsTable);

export default MemoizedWorkflowsTable;
