import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { ThemeProvider } from '@emotion/react';
import { Box } from '@mui/material';
import {
  IconLanguageHiragana,
  IconTag,
  IconTemplate,
} from '@tabler/icons-react';

import {
  FilterButton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  useGetIsTemplateIntentWorkflow,
  useGetTemplateAutoflowWorkflow,
} from '../workflow-builder-edit/hooks';
import { useTemplatePickerState } from './hooks';
import { InputCvForm } from './InputCvForm';
import { IntentFiltersButton } from './IntentFiltersButton';
import {
  allTagsOption,
  englishOption,
  getLanguageOptions,
  getTemplateIntentWorkflowId,
  getWorkflowTagOptions,
  useGetThemeBasedOnMode,
  useIsCurrentChannelEmail,
} from './utils';
import {
  getWorkflowBuilderLanguages,
  getWorkflowBuilderLanguagesEnabled,
} from 'src/actions/workflow-builder/workflowBuilderActions';
import { useGetWorkflowTags } from 'src/hooks/hooks';
import {
  selectWorkflowBuilderLanguages,
  selectWorkflowBuilderLanguagesEnabled,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import {
  selectPreviewLanguage,
  selectPreviewWorkflowTag,
  setPreviewLanguage,
  setPreviewWorkflowTag,
} from 'src/slices/workflow-preview/workflowPreviewSlice';
import { useAppDispatch } from 'src/store/hooks';

export const WidgetSetupControl = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const theme = useGetThemeBasedOnMode();

  const isTemplate = useGetIsTemplateIntentWorkflow();

  const selectedLanguage = useSelector(selectPreviewLanguage);
  const selectedWorkflowTag = useSelector(selectPreviewWorkflowTag);
  const languages = useSelector(selectWorkflowBuilderLanguages);
  const languageCodesEnabled = useSelector(
    selectWorkflowBuilderLanguagesEnabled,
  ).language_codes;

  const params = new URLSearchParams(location.search);
  const previewFromIntentId = params.get('intentId');
  const { channel } = useParams<'channel'>();
  const isCurrentChannelEmail = useIsCurrentChannelEmail();
  const { template } = useGetTemplateAutoflowWorkflow(
    getTemplateIntentWorkflowId(previewFromIntentId ?? ''),
  );
  const { onChange, options, value } = useTemplatePickerState();
  const workflowTags = useGetWorkflowTags();
  const languageOptions = getLanguageOptions(languages, languageCodesEnabled);
  const workflowTagOptions = getWorkflowTagOptions(workflowTags ?? []);

  const isDarkMode = theme.palette.mode === 'dark';

  const getTemplateHeaderText = () => {
    if (isTemplate && template) {
      return template.name + ' template';
    }

    return 'Preview';
  };

  useEffect(() => {
    if (languages.length === 0) {
      dispatch(getWorkflowBuilderLanguages());
    }
    if (languageCodesEnabled.length === 0) {
      dispatch(getWorkflowBuilderLanguagesEnabled('widget'));
    }
    // only need to do these fetch once to handle the case that the user goes into preview by URL without landing on
    // dashboard or canvas (or if the user refreshes the page)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Box display='flex' flexDirection='column' gap='16px'>
      <ThemeProvider theme={theme}>
        <Box display='flex' justifyContent='space-between'>
          <Typography
            color={
              isDarkMode
                ? theme.palette.colors.white
                : theme.palette.colors.black
            }
            variant='font16Bold'
          >
            {isTemplate ? (
              <> {getTemplateHeaderText()}</>
            ) : (
              <>Filter intents for preview</>
            )}
          </Typography>
        </Box>
        {!isTemplate && <IntentFiltersButton />}
        <Box display='flex' flexWrap='wrap' gap='8px'>
          {!isTemplate && !isCurrentChannelEmail && (
            <>
              {channel !== 'api' && (
                <FilterButton
                  aria-label='language'
                  initialValue={englishOption.value}
                  menuProps={{
                    isMenuSearchable: true,
                  }}
                  onChange={value => {
                    dispatch(setPreviewLanguage(value));
                  }}
                  options={languageOptions}
                  startAdornment={
                    <IconLanguageHiragana
                      color={
                        isDarkMode
                          ? theme.palette.grey[500]
                          : theme.palette.colors.grey[700]
                      }
                      size={20}
                    />
                  }
                  value={selectedLanguage}
                />
              )}
              {channel !== 'api' && (
                <FilterButton
                  aria-label='tags'
                  initialValue={allTagsOption.value}
                  menuProps={{
                    isMenuSearchable: true,
                  }}
                  onChange={value => {
                    dispatch(setPreviewWorkflowTag(value));
                  }}
                  options={workflowTagOptions}
                  startAdornment={
                    <IconTag
                      color={
                        isDarkMode
                          ? theme.palette.grey[500]
                          : theme.palette.colors.grey[700]
                      }
                      size={20}
                    />
                  }
                  value={selectedWorkflowTag ?? ''}
                />
              )}
            </>
          )}
          {isTemplate && (
            <FilterButton
              aria-label='Templates'
              initialValue={value}
              onChange={onChange}
              options={options}
              startAdornment={
                <IconTemplate
                  color={
                    isDarkMode
                      ? theme.palette.grey[500]
                      : theme.palette.colors.grey[700]
                  }
                  size={20}
                />
              }
              value={value}
            />
          )}
          {!isCurrentChannelEmail && <InputCvForm />}
        </Box>
      </ThemeProvider>
    </Box>
  );
};
