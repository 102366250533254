import { useSelector } from 'react-redux';
import { Box, styled, useTheme } from '@mui/material';
import { IconX } from '@tabler/icons-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import {
  CALL_TO_ACTION_LABEL_PLACEHOLDER,
  PRIVACY_POLICY_PLACEHOLDER,
  PROMPT_HEADER_PLACEHOLDER,
} from '../constants';
import { useGetRemirrorContextVariables } from '../hooks/useGetRemirrorContextVariables';
import HtmlComponent from 'src/components/html-component/HtmlComponent';
import { solveLinkifyHtmlOptions } from 'src/components/html-component/solveLinkifyHtmlOptions';
import { replaceContextVariableIdsWithMarkup } from 'src/components/rich-text-editor/helpers/replaceContextVariableIdsWithMarkup';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import {
  selectLegibleTextColor,
  selectWidgetConfiguration,
} from 'src/slices/solve-config/solveConfigSlice';

const PrivacyConsentWidgetContent = () => {
  const theme = useTheme();
  const remirrorContextVariables = useGetRemirrorContextVariables();
  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const fontColor = useSelector(selectLegibleTextColor);
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};

  const isPrivacyConsentEnabled = featureFlags.includes('privacy_consent');

  if (!isPrivacyConsentEnabled) {
    return null;
  }

  const { theme_color: themeColor } = widgetConfiguration;
  const {
    call_to_action_label: callToActionLabel,
    privacy_policy: privacyPolicy,
    prompt_header: promptHeader,
    show_when_conversation_starts: showWhenConversationStarts,
  } = widgetConfiguration.privacy_consent_config;
  return (
    <Box>
      {showWhenConversationStarts && (
        <Box
          sx={{
            bgcolor: 'rgba(0, 0, 0, 0.3)',
            borderRadius: '12px',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        >
          <Box
            sx={{
              alignSelf: 'flex-end',
              bgcolor: `${theme.palette.colors.white}`,
              border: `1px solid ${theme.palette.colors.grey[100]}`,
              borderRadius: '12px',
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              height: 'fit-content',
              maxHeight: '80%',
              padding: '24px',
              width: '100%',
            }}
          >
            <Box
              alignItems='center'
              display='flex'
              justifyContent='space-between'
            >
              <Typography variant='font16Bold'>
                {promptHeader || PROMPT_HEADER_PLACEHOLDER}
              </Typography>
              <IconX
                color={theme.palette.colors.grey[700]}
                size={24}
                strokeWidth={1.5}
              />
            </Box>
            <Box
              sx={{
                overflowY: 'scroll',
              }}
            >
              <HtmlComponent
                content={replaceContextVariableIdsWithMarkup(
                  privacyPolicy || `<p>${PRIVACY_POLICY_PLACEHOLDER}</p>`,
                  remirrorContextVariables,
                )}
                linkifyHtmlOptions={solveLinkifyHtmlOptions}
              />
            </Box>
            <Box>
              <Box
                sx={{
                  alignItems: 'center',
                  bgcolor: themeColor,
                  borderRadius: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  minHeight: '40px',
                  padding: '8px 13px',
                }}
              >
                <Typography color={fontColor} variant='font14'>
                  {callToActionLabel || CALL_TO_ACTION_LABEL_PLACEHOLDER}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      <InstructionalTextWrapper className='plus-jakarta-sans'>
        <Box
          alignSelf='center'
          display='flex'
          flexDirection='column'
          padding='30px 90px 0px 90px'
          sx={{
            '.MuiTypography-root': {
              background:
                'linear-gradient(270deg, #BD00FF 5.08%, #3866F5 94.92%)',
              fontStyle: 'italic',
              textAlign: 'center',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            },
          }}
        >
          <Typography variant='font16Medium'>
            Personalize your Privacy and consent by using the menu on the right.
            Utilize a prompt to make the privacy policy mandatory for customers
            to accept your terms
          </Typography>
          <Box pb='20px' />
          <Typography color={theme.palette.colors.white} variant='font16Medium'>
            Check the preview for the correct resolution
          </Typography>
        </Box>
      </InstructionalTextWrapper>
    </Box>
  );
};

export default PrivacyConsentWidgetContent;

const InstructionalTextWrapper = styled('div')`
  text-align: center;
`;
