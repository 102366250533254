import { useCallback, useEffect, useState } from 'react';
import { SolveConfigContentContainer, ToolbarContainer } from '.';
import Pusher from 'pusher-js';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import { VerticalTabBar } from '@forethought-technologies/forethought-elements';
import WorkflowConflictMessage from '../workflow-builder-edit/WorkflowConflictMessage';
import { BrandSidebar } from './brand-sidebar/BrandSidebar';
import CodeWidgetContent from './code-tab-content/CodeWidgetContent';
import PromptWidgetContent from './code-tab-content/PromptWidgetContent';
import CsatQuickFeedbackParentWidgetContent from './csat-tab-content/CsatQuickFeedbackParentWidgetContent';
import FallbackWidgetContent from './fallback-tab-content/FallbackWidgetContent';
import { useGetWidgetTabIndex } from './hooks/useGetTabIndex';
import { useLoadingState } from './hooks/useLoadingState';
import { InstructionWidgetContent } from './instruction-tab-content/InstructionWidgetContent';
import KnowledgeWidgetContent from './knowledge-tab-content/KnowledgeWidgetContent';
import PrivacyConsentWidgetContent from './privacy-consent-tab-content/PrivacyConsentWidgetContent';
import StaticSolveWidget from './static-solve-widget/StaticSolveWidget';
import ThemeWidgetContent from './theme-tab-content/ThemeWidgetContent';
import {
  SUB_TABS_WITH_MINIMIZED_WIDGET,
  TABS_WITH_MINIMIZED_WIDGET,
} from './constants';
import { ToneTabWidgetContent } from './tone-tab-content';
import { Channel } from './types';
import { getSolveWidgetConfigTabs } from './utils';
import { useSolveConfigTrackingEventAction } from 'src/hooks/hooks';
import { useConfigurationChangeEventSubscription } from 'src/hooks/PusherEventHooks';
import {
  selectIsSolveLiteEnabled,
  selectUserRole,
} from 'src/reducers/userReducer/userReducer';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { getKnowledgeConfiguration } from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes, SolveConfigTrackingEventTypes } from 'src/utils/enums';
import { getUserOrgCode } from 'src/utils/getUserOrgCode';

export default function WidgetTab({ channel }: { channel: Channel }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const widgetTabIndex = useGetWidgetTabIndex();
  const { data: featureFlagsData, isLoading: isFeatureFlagsLoading } =
    useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const userRole = useSelector(selectUserRole);
  const isSolveLiteEnabled = useSelector(selectIsSolveLiteEnabled);
  const isExtraLarge = useMediaQuery('(min-width:1600px)');

  const isSolveKnowledgeConfigRevamp = featureFlags.includes(
    'knowledge_article_workflow',
  );
  const isHandoffRevampEnabled = featureFlags.includes('handoff_revamp');
  const isPrivacyConsentEnabled = featureFlags.includes('privacy_consent');
  const isBannerImageEnabled = featureFlags.includes('banner_image');
  const isCsatConfigRevampEnabled = featureFlags.includes('csat_config_revamp');
  const isFallbackWorkflowEnabled = featureFlags.includes('fallback_workflow');
  const isQuickFeedbackEnabled = featureFlags.includes('quick_feedback');
  const isToneOfVoiceEnabled = featureFlags.includes('tone_of_voice');
  const isMultibrandEnabled = featureFlags.includes('multibrand_enabled');

  const dispatchTrackingAction = useSolveConfigTrackingEventAction();

  useLoadingState();

  const [hasConfigurationConflict, setHasConfigurationConflict] =
    useState(false);

  const params = new URLSearchParams(location.search);
  const query = params.get('tab') ?? '';
  const subTab = params.get('sub-tab') ?? '';

  const widgetTabsFiltered = getSolveWidgetConfigTabs({
    isBannerImageEnabled,
    isCsatConfigRevampEnabled,
    isFallbackWorkflowEnabled,
    isHandoffRevampEnabled,
    isMultibrandEnabled,
    isPrivacyConsentEnabled,
    isQuickFeedbackEnabled,
    isSolveKnowledgeConfigRevamp,
    isSolveLiteEnabled,
    isToneOfVoiceEnabled,
  });

  const isFullWidthContent = query === 'more';

  /**
   * Needs to be constantly checked on navigation to see if
   * the source gets built to enable the publish button on header
   */
  useEffect(() => {
    if (isSolveLiteEnabled && !isFeatureFlagsLoading) {
      dispatch(getKnowledgeConfiguration());
    }
  }, [location.search, isSolveLiteEnabled, dispatch, isFeatureFlagsLoading]);

  useEffect(() => {
    if (!window.pusher) {
      window.pusher = new Pusher(PUSHER_KEY, {
        cluster: PUSHER_CLUSTER,
      });
    }
  }, [dispatch, isSolveLiteEnabled, isFeatureFlagsLoading, userRole]);

  /**
   * Needs to be constantly checked on navigation to see if
   * the source gets built to enable the publish button on header
   */
  useEffect(() => {
    if (isSolveLiteEnabled && !isFeatureFlagsLoading) {
      dispatch(getKnowledgeConfiguration());
    }
  }, [location.search, isSolveLiteEnabled, dispatch, isFeatureFlagsLoading]);

  useConfigurationChangeEventSubscription({
    onEvent: useCallback(() => setHasConfigurationConflict(true), []),
    orgCode: getUserOrgCode(),
  });

  const handleWidgetTabOnClick = (index: number) => {
    const tab = widgetTabsFiltered[index].label.toLowerCase();
    navigate(
      {
        pathname: isSolveLiteEnabled
          ? Routes.SOLVE_LITE
          : Routes.SOLVE_CONFIGURATION,
        search: `?channel=${channel}&tab=${tab}`,
      },
      { replace: true },
    );
    dispatchTrackingAction(SolveConfigTrackingEventTypes.TAB_NAVIGATION, {
      tab: tab,
    });
  };

  const getStaticWidgetType = () => {
    return TABS_WITH_MINIMIZED_WIDGET.includes(query) ||
      SUB_TABS_WITH_MINIMIZED_WIDGET.includes(subTab)
      ? 'minimized'
      : 'expanded';
  };

  const handleRenderWidgetConfigurationBasedOnTabType = () => {
    switch (query) {
      case 'code':
        return <CodeWidgetContent />;
      case 'theme':
        if (subTab === 'prompt') {
          return <PromptWidgetContent />;
        }
        return <ThemeWidgetContent includeBanner includeIntro />;
      case 'knowledge':
        return <KnowledgeWidgetContent />;
      case 'fallback':
        return <FallbackWidgetContent />;
      case 'privacy':
        return <PrivacyConsentWidgetContent />;
      case 'csat':
        return <CsatQuickFeedbackParentWidgetContent />;
      case 'banner':
        return (
          <ThemeWidgetContent
            includeBanner
            primaryInstruction='Customize your banner by uploading a designed image and providing
              a URL for your customers to be redirected to when the banner is
              clicked'
            secondaryInstruction='Preview your widget for the standard image dimensions'
          />
        );
      case 'tone':
        return (
          <ThemeWidgetContent
            primaryInstruction='Express your brand through tone of voice, establishing consistency to strengthen brand identity and foster connections with customers.'
            spaceBetween={false}
            WidgetContent={ToneTabWidgetContent}
          />
        );
      case 'instruction':
        return (
          <ThemeWidgetContent
            primaryInstruction={
              "The instructional note is to guide the AI in generating responses that are relevant, engaging, and personalized to users' unique needs."
            }
            WidgetContent={InstructionWidgetContent}
          />
        );
      default:
        return <ThemeWidgetContent />;
    }
  };

  const bannerTabIndex = widgetTabsFiltered.findIndex(
    tab => tab.label === 'Banner',
  );

  return (
    <BrandSidebar>
      {!isFullWidthContent && (
        <SolveConfigContentContainer>
          <StaticSolveWidget type={getStaticWidgetType()}>
            {handleRenderWidgetConfigurationBasedOnTabType()}
          </StaticSolveWidget>
        </SolveConfigContentContainer>
      )}
      <ToolbarContainer
        isFullScreen={isFullWidthContent}
        isTabSelected={typeof widgetTabIndex === 'number'}
      >
        <VerticalTabBar
          divideAfterIndex={isMultibrandEnabled ? bannerTabIndex : -1}
          setTabIndex={handleWidgetTabOnClick}
          tabBodyWidth={isMultibrandEnabled && !isExtraLarge ? 440 : 520}
          tabIndex={widgetTabIndex}
          tabs={widgetTabsFiltered}
        />
      </ToolbarContainer>
      <WorkflowConflictMessage
        buttonMessage='Reload'
        message='Reload this page to continue editing from the last updated version.'
        onButtonClick={() => {
          window.location.reload();
        }}
        open={hasConfigurationConflict}
        title='Configuration has been updated by another user in your organization'
      />
    </BrandSidebar>
  );
}
