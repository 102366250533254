import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

import { Tabs } from '@forethought-technologies/forethought-elements';
import VoiceInsightsConversations from './conversations';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';

const VoiceInsightsMain = () => {
  const { palette } = useTheme();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();

  // state params
  const [searchParams, setSearchParams] = useSearchParams();
  const serializedTab = searchParams.get('tab') ?? '0';
  const activeTab = Number(serializedTab);

  const availableTabs = useMemo(() => {
    return ['Conversations'];
  }, []);

  useEffect(() => {
    emitTrackingEventCallback('voice-insight-initial-page', {
      scope: 'main',
      tab: availableTabs[activeTab],
    });
  }, [emitTrackingEventCallback, activeTab, availableTabs]);

  const tabValue = useMemo(
    () => availableTabs[activeTab],
    [availableTabs, activeTab],
  );

  const handlePageRender = useCallback(() => {
    if (typeof tabValue === 'string') {
      if (tabValue === 'Conversations') {
        return <VoiceInsightsConversations />;
      }
    }
    return <VoiceInsightsConversations />;
  }, [tabValue]);

  return (
    <>
      <Box
        padding='0px 40px'
        sx={{
          bgcolor: palette.colors.white,
        }}
      >
        <Tabs
          a11yProps={index => ({
            'aria-controls': `tabpanel-${index}`,
            id: `main-tab-${index}`,
          })}
          aria-label='tabs to pick data table below'
          onChange={(_, tab) => {
            emitTrackingEventCallback('insight-tab-change', {
              scope: 'main',
              tab: tabValue,
            });
            const newSearchParams = new URLSearchParams();
            newSearchParams.set('tab', tab.toString());
            setSearchParams(newSearchParams);
          }}
          tabs={availableTabs}
          typographyVariant='font18'
          value={activeTab}
        />
      </Box>

      {handlePageRender()}
    </>
  );
};

export default VoiceInsightsMain;
