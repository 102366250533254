import { Box, useTheme } from '@mui/material';
import { useRTVIClientTransportState } from '@pipecat-ai/client-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { NeonCircle } from './NeonCircle';

export function StatusDisplay() {
  const theme = useTheme();
  const transportState = useRTVIClientTransportState();

  const handleMessageToDisplay = () => {
    switch (transportState) {
      case 'disconnected':
      case 'disconnecting':
        return 'Click start to connect to an AI agent';
      case 'initializing':
      case 'authenticating':
      case 'connecting':
        return 'Establishing connection...';
      case 'connected':
        return 'Waiting for an AI agent to pick up the call...';
      case 'ready':
        return 'In the call with an AI agent';
      case 'error':
        return 'Something went wrong. Please try again later.';
    }
  };

  return (
    <Box
      alignItems='center'
      display='flex'
      flex={1}
      flexDirection='column'
      gap='16px'
      justifyContent='center'
    >
      <NeonCircle />
      <Typography color={theme.palette.colors.purple[100]} variant='font14'>
        {handleMessageToDisplay()}
      </Typography>
    </Box>
  );
}
