import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { TEMPLATE_INTENT_WORKFLOW_ID_PREFIX } from '../workflow-builder-edit/constants';
import { useGetTemplateAutoflowWorkflow } from '../workflow-builder-edit/hooks';
import {
  convertIntentFiltersToDict,
  getTemplateIntentWorkflowId,
} from './utils';
import {
  getPreviewLogs,
  initializeConversation,
  updateConversation,
} from 'src/actions/api-preview/apiPreviewAction';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { useGetActionBuilderActionsQuery } from 'src/services/action-builder/actionBuilderApi';
import { useGetSolveApiTokenQuery } from 'src/services/workflow-builder-canvas/workflowBuilderCanvasApi';
import { useGetAutoflowTemplatesQuery } from 'src/services/workflowBuilderAutoflowApi.ts/workflowBuilderAutoflowApi';
import { SolveApiRequest } from 'src/slices/workflow-preview/types';
import {
  pushApiPreviewRequest,
  selectApiPreviewState,
  selectPreviewColorMode,
  selectPreviewIntentFilters,
  selectPreviewWorkflowCvs,
} from 'src/slices/workflow-preview/workflowPreviewSlice';
import { useAppDispatch } from 'src/store/hooks';
import { isTemplateApiActionFields } from 'src/types/actionBuilderApiTypes';
import { getVariablesFromAction } from 'src/utils/actionBuilder/helpers';

export const useGetTemplatePreviewMessage = (intentId = '') => {
  const intentWorkflowId = getTemplateIntentWorkflowId(intentId);

  const { template } = useGetTemplateAutoflowWorkflow(intentWorkflowId);
  const { data } = useGetActionBuilderActionsQuery({});
  const { contextVariables } = useGetContextVariables({
    shouldIncludeTemplateContextVariables: true,
  });

  return useMemo(() => {
    const getSeparator = (
      index: number,
      numKeys: number,
      isContextVariable = false,
    ) => {
      if (index === 0) {
        return ':';
      }

      if (index === numKeys - 1) {
        return isContextVariable ? 'or' : 'and';
      }

      return ',';
    };

    if (template?.instruction) {
      return [template.instruction];
    }

    const actionMessages = template?.tool_definitions.map(tool => {
      const action = data?.actions.find(
        action => action.action_id === tool.tool_id,
      );

      if (!action) {
        return '';
      }

      const { action_fields: actionFields } = action;

      if (!isTemplateApiActionFields(actionFields)) {
        return '';
      }

      const { inputVariables } = getVariablesFromAction(
        action,
        contextVariables,
      );

      const mockResponsesKeys = Object.keys(actionFields.mock_responses).sort(
        (a, b) => {
          // fallback key needs to be add the end of the array
          if (a === 'fallback') {
            return 1;
          }

          if (b === 'fallback') {
            return -1;
          }

          return 0;
        },
      );
      const inputVarName = inputVariables[0]?.context_variable_name;

      if (!inputVarName) {
        return '';
      }

      const responseList = mockResponsesKeys.map((inputVar, index) => {
        const mockResponse = actionFields.mock_responses[inputVar];
        const value = Object.values(mockResponse.response)[0];

        if (inputVar === 'fallback') {
          return `. Any other ${inputVarName} ${value}`;
        }

        return `${getSeparator(
          index,
          mockResponsesKeys.length,
        )} ${inputVar} for ${value}`;
      });

      const isSingleValidInputVar =
        mockResponsesKeys.filter(key => key !== 'fallback').length === 1;

      return `Try typing the following ${inputVarName}${
        isSingleValidInputVar ? '' : 's'
      }${responseList.join(' ')}.`;
    });

    const contextVariableMessages = template?.context_variables?.map(cvId => {
      const cv = contextVariables.find(cv => cv.context_variable_id === cvId);

      if (!cv || !cv.list_type_options) {
        return '';
      }

      const responseList = cv.list_type_options?.map((option, index) => {
        return `${getSeparator(
          index,
          cv.list_type_options?.length ?? 0,
          true,
        )} ${option.value}`;
      });

      const isSingleValidInputVar = cv.list_type_options.length === 1;

      return `Try typing the following ${cv.context_variable_name} value${
        isSingleValidInputVar ? '' : 's'
      }${responseList.join(' ')}.`;
    });

    return [...(actionMessages ?? []), ...(contextVariableMessages ?? [])];
  }, [
    contextVariables,
    data?.actions,
    template?.context_variables,
    template?.instruction,
    template?.tool_definitions,
  ]);
};

export const useTemplatePickerState = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data = [] } = useGetAutoflowTemplatesQuery();

  const onChange: (value: string) => void = useCallback(
    value => {
      setSearchParams(prev => {
        prev.set('intentId', value);

        return prev;
      });
    },
    [setSearchParams],
  );

  return {
    onChange,
    options: data.map(template => ({
      label: template.name + ' template',
      value: TEMPLATE_INTENT_WORKFLOW_ID_PREFIX + template.intent_workflow_id,
    })),
    value: searchParams.get('intentId') ?? '',
  };
};

export const useSendPreviewApiRequest = () => {
  const dispatch = useAppDispatch();
  const { data } = useGetSolveApiTokenQuery();
  const solveApiToken = data ? data.api_key : '';
  const currentIntentFilters = useSelector(selectPreviewIntentFilters);
  const { conversation_id: conversationId, isStream } = useSelector(
    selectApiPreviewState,
  );

  // workflow CVS
  const savedWorkflowCvs = useSelector(selectPreviewWorkflowCvs);
  const workflowCvsToSend = useMemo(() => {
    const formattedCvs: Record<string, string> = {};
    savedWorkflowCvs.forEach(cv => (formattedCvs[cv.name] = cv.value));

    return formattedCvs;
  }, [savedWorkflowCvs]);

  // intent filters
  const intentFilters = convertIntentFiltersToDict(currentIntentFilters);

  const sendPreviewApiRequest = useCallback(
    async ({
      contextVariables,
      query,
    }: {
      contextVariables?: Record<string, string>;
      query?: string;
    }) => {
      // need to initialize the conversation
      if (!conversationId) {
        const request: SolveApiRequest = {
          context_variables: workflowCvsToSend,
          intent_filters_for_preview: intentFilters,
          is_preview: true,
          query: query,
          solve_api_token: solveApiToken,
          stream: isStream,
        };
        dispatch(pushApiPreviewRequest(request));

        // send initialize solve api request
        await dispatch(initializeConversation(request));

        dispatch(
          getPreviewLogs({
            token: solveApiToken,
          }),
        );
      }
      // update the ongoing conversation
      else {
        const request = {
          context_variables: contextVariables || {},
          conversation_id: conversationId,
          intent_filters_for_preview: intentFilters,
          is_preview: true,
          query: query,
          solve_api_token: solveApiToken,
          stream: isStream,
        };
        dispatch(pushApiPreviewRequest(request));
        // send update solve api request
        await dispatch(updateConversation(request));

        dispatch(getPreviewLogs({ conversationId, token: solveApiToken }));
      }
    },
    [
      conversationId,
      dispatch,
      solveApiToken,
      workflowCvsToSend,
      intentFilters,
      isStream,
    ],
  );

  return sendPreviewApiRequest;
};

export const useGetColorMode = () => {
  const { channel } = useParams();
  const colorMode = useSelector(selectPreviewColorMode);

  return channel === 'api' || channel === 'voice' ? 'dark' : colorMode;
};
