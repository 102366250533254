import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { FormProps } from '@rjsf/core';

import { Button, theme } from '@forethought-technologies/forethought-elements';
import { createConnector } from '../../../../../slices/connectors/thunks';
import DrawerSection from '../../DrawerSection';
import ConnectConnectorButton from '../ConnectConnectorButton';
import IntegrationForm from '../IntegrationForm';
import useHandleConnectOAuthSetupType from './hooks/useHandleConnectOAuthSetupType';
import {
  Connector,
  ConnectorDefinition,
  Credentials,
} from 'src/services/apiInterfaces';
import { selectConnectors } from 'src/slices/connectors/connectorsSlice';

export interface CreateIntegrationFormProps extends FormProps<Credentials> {
  connectorDefinition: ConnectorDefinition;
  existingNames?: string[];
  multiConnectorsEnabled: boolean;
  /* When using the create form to re-start oauth flow for an existing oauth connector */
  oauthConnector?: Connector;
}

export default function CreateIntegrationForm({
  connectorDefinition,
  existingNames,
  multiConnectorsEnabled,
  oauthConnector,
  ...rest
}: CreateIntegrationFormProps) {
  const dispatch = useDispatch();
  const {
    handleConnectOAuthSetupType,
    handleConnectOAuthSetupTypeForConnector,
  } = useHandleConnectOAuthSetupType({
    connectorDefinition,
    multiConnectorsEnabled,
  });

  const { isUpdateLoading } = useSelector(selectConnectors);
  const [displayName, setDisplayName] = useState('');

  const handleConnectFormSetupType = async (formData: Credentials) => {
    const formDataWithDisplayName = {
      ...formData,
      display_name: displayName,
    };

    dispatch(
      createConnector({
        connectorDefinitionSlug: connectorDefinition.slug,
        connectorFields: formDataWithDisplayName,
        connectorTypes: connectorDefinition.connector_types,
      }),
    );
  };

  const handleConnect = async ({ formData }: { formData?: Credentials }) => {
    if (formData === undefined) {
      return;
    }

    const { setup_type } = connectorDefinition;

    if (oauthConnector?.is_active && multiConnectorsEnabled) {
      // Restart Auth Flow
      handleConnectOAuthSetupTypeForConnector(
        oauthConnector.connector_id,
        formData,
      );
      return;
    }

    if (setup_type === 'OAUTH') {
      handleConnectOAuthSetupType({
        ...formData,
        display_name: displayName,
      });
    } else if (setup_type === 'FORM') {
      handleConnectFormSetupType(formData);
    }
  };

  const formButtons = useMemo(() => {
    return (
      <>
        {oauthConnector?.is_active && (
          <ConnectConnectorButton connector={oauthConnector} />
        )}
        <Button
          disabled={isUpdateLoading}
          fullWidth
          isLoading={isUpdateLoading}
          size='large'
          type='submit'
          variant='secondary'
        >
          {oauthConnector?.is_active ? 'Restart Auth Flow' : 'Connect'}
        </Button>
      </>
    );
  }, [isUpdateLoading, oauthConnector]);

  return (
    <>
      {connectorDefinition.setup_instructions && (
        <DrawerSection title='Setup Instructions'>
          <SetupInstructions
            dangerouslySetInnerHTML={{
              __html: connectorDefinition.setup_instructions,
            }}
          />
        </DrawerSection>
      )}
      <IntegrationForm
        existingNames={existingNames}
        formButtons={formButtons}
        formData={undefined}
        isCreate
        multiConnectorsEnabled={multiConnectorsEnabled}
        onDisplayNameChange={setDisplayName}
        onSubmit={handleConnect}
        {...rest}
      />
    </>
  );
}

const SetupInstructions = styled('div')`
  color: ${theme.palette.grey[700]};
  font-size: 14px;
  line-height: 24px;

  ol,
  ul {
    padding-inline-start: 16px;
  }
`;
