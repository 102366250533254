import { Box } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';

export default function FallbackDescription({
  isFallbackWordWithGradient: doesFallbackWordHaveGradient,
}: {
  isFallbackWordWithGradient: boolean;
}): JSX.Element {
  return (
    <div className='plus-jakarta-sans'>
      <Typography variant='font16Medium'>
        The “
        <Box
          component='span'
          sx={
            doesFallbackWordHaveGradient
              ? {
                  background:
                    'linear-gradient(270deg, #BD00FF 0%, #3866F5 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }
              : undefined
          }
        >
          Fallback
        </Box>
        “ workflow is a default feature that activates when the Dynamic Article
        Suggestion component (part of the Knowledge Retrieval workflow) fails to
        find relevant content for a user&apos;s inquiry.
      </Typography>
      <Box pb='20px' />
      <Typography variant='font16Medium'>
        It lets you define how the chatbot should respond in such cases. By
        default, the chatbot will initiate a standard handoff, but you can
        customize this response.
      </Typography>
      <Box pb='20px' />
      <Typography variant='font16Medium'>
        To prevent further unhelpful article suggestions, the Articles feature
        is disabled during fallback scenarios.
      </Typography>
    </div>
  );
}
