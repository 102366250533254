import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, styled, useTheme } from '@mui/material';
import { IconPlayerPause, IconPlayerPlay } from '@tabler/icons-react';

import {
  LoadingIndicator,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useCartesiaAudio } from '../utils';
import { ActivenessIndicator } from 'src/pages/workflow-builder-edit/autonomous-agent/action-drawer/ActivenessIndicator';
import { selectVoiceConfiguration } from 'src/slices/solve-config/solveConfigSlice';
import { AvailableVoice } from 'src/slices/solve-config/types';

export const AgentVoice = ({
  updateAgentVoiceId,
  voice,
}: {
  updateAgentVoiceId: (voiceId: string) => void;
  voice: AvailableVoice;
}) => {
  const theme = useTheme();
  const [isPlaying, setIsPlaying] = useState(false);
  const voiceConfiguration = useSelector(selectVoiceConfiguration);
  const { agent_voice_id: selectedVoiceId, greeting_message: greetingMessage } =
    voiceConfiguration;
  const { handlePlay: handlePlayCartesia, isBuffering } = useCartesiaAudio();

  const onFinishPlaying = () => {
    setIsPlaying(false);
  };

  const handlePlayElevenLabs = () => {
    if (voice.preview_url) {
      const audio = new Audio(voice.preview_url);
      audio.addEventListener('ended', function () {
        onFinishPlaying();
      });

      audio.play();
    }
  };

  return (
    <VoiceContainer
      isSelected={selectedVoiceId === voice.voice_id}
      onClick={() => {
        updateAgentVoiceId(voice.voice_id);
      }}
    >
      <PlayButton
        disabled={isPlaying}
        onClick={e => {
          e.stopPropagation();
          if (isPlaying) {
            return;
          }
          setIsPlaying(true);

          if (voice.preview_url) {
            handlePlayElevenLabs();
          } else {
            handlePlayCartesia({
              onFinishPlaying: onFinishPlaying,
              text: greetingMessage,
              voiceId: voice.voice_id,
            });
          }
        }}
      >
        {isBuffering ? (
          <LoadingIndicator color='white' />
        ) : isPlaying ? (
          <IconPlayerPause color={theme.palette.colors.white} size={20} />
        ) : (
          <IconPlayerPlay color={theme.palette.colors.white} size={20} />
        )}
      </PlayButton>

      <Box display='flex' flexDirection='column'>
        <Typography variant='font14Bold'>{voice.name}</Typography>
        <Typography color={theme.palette.colors.grey[600]} variant='font14'>
          {voice.description}
        </Typography>
      </Box>

      <Box
        alignSelf='center'
        display='flex'
        flex={1}
        gap={1}
        justifyContent='flex-end'
      >
        <ActivenessIndicator isActive={selectedVoiceId === voice.voice_id} />
      </Box>
    </VoiceContainer>
  );
};

const VoiceContainer = styled('div')<{ isSelected: boolean }>`
  border: 1px solid ${props => props.theme.palette.colors.slate[100]};
  border-radius: 8px;
  display: flex;
  gap: 8px;
  padding: 16px 16px 16px 8px;
  width: 100%;
  cursor: pointer;
  background-color: ${props =>
    props.isSelected && props.theme.palette.colors.slate[100]};

  &:hover {
    background-color: ${props => props.theme.palette.colors.slate[100]};
  }
`;

const PlayButton = styled('button')`
  align-items: center;
  background-color: ${props => props.theme.palette.colors.purple[500]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  cursor: pointer;

  :disabled {
    cursor: default;
  }
`;
