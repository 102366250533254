import { styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { IconX } from '@tabler/icons-react';
import { IconSend } from '@tabler/icons-react';

import {
  Tabs,
  Typography,
} from '@forethought-technologies/forethought-elements';
import PoweredByText from '../../theme-tab-content/PoweredByText';
import ftLogoWhite from 'src/assets/images/ft-logo-white.png';

const AssistWrapper = ({ preview }: { preview: React.ReactNode }) => {
  const { palette } = useTheme();
  return (
    <Box
      bgcolor='white'
      borderRadius={4}
      display='grid'
      gridTemplateRows='56px 48px auto 106px'
      height='80%'
      overflow='hidden'
      width='540px'
    >
      <Box
        alignItems='center'
        bgcolor='black'
        display='flex'
        height='56px'
        justifyContent='space-between'
        px={1.5}
      >
        <img src={ftLogoWhite} width='19px' />
        <Typography color='white' variant='font14Bold'>
          Forethought Assist
        </Typography>
        <IconX color='white' size={20} />
      </Box>
      <Box display='flex' justifyContent='center'>
        <Tabs
          a11yProps={() => ({
            'aria-controls': 'assist-preview-tabpanel',
            id: 'assist-preview-tab',
          })}
          aria-label='assist preview tabs'
          ContainerProps={{
            sx: {
              borderBottom: `1px solid ${palette.colors.slate[200]}`,
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            },
          }}
          onChange={() => undefined}
          tabs={['Feed', 'Assist AI', 'Notes', 'Favorites']}
          typographyVariant='font14'
          value={0}
        />
      </Box>
      <Box sx={{ overflowY: 'auto' }}>{preview}</Box>
      <Box
        display='flex'
        flexDirection='column'
        gap='12px'
        marginTop='16px'
        paddingBottom={2}
      >
        <MessageContainer>
          <Typography color={palette.colors.grey[400]} variant='font16Medium'>
            Ask a question...
          </Typography>
          <IconSend color={palette.colors.grey[400]} />
        </MessageContainer>
        <PoweredByText />
      </Box>
    </Box>
  );
};

const MessageContainer = styled('div')`
  padding: 16px 10px;
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  border-color: ${props => props.theme.palette.colors.grey[400]};
  margin: 0px 8px;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default AssistWrapper;
