import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@mui/material';

import {
  ALLOWED_DISPLAY_NAME_CHARACTER_REGEX,
  ALLOWED_DISPLAY_NAME_LENGTH,
  CV_LENGTH_VALIDATION_ERROR,
} from './constants';
import flow from 'lodash/fp/flow';
import groupBy from 'lodash/fp/groupBy';
import isNumber from 'lodash/fp/isNumber';
import keyBy from 'lodash/fp/keyBy';
import mapValues from 'lodash/fp/mapValues';
import { Helpdesk } from 'src/components/app/types';
import { HELPDESK_LOGOS } from 'src/constants/solve';
import { ContextVariablesTableData } from 'src/pages/workflow-builder-config/context-variables/ContextVariablesPage';
import {
  CreateTicketFieldMappingRequest,
  TicketCustomField,
} from 'src/pages/workflow-builder-edit/types';
import { TriageModelDetail } from 'src/reducers/triageSettingsReducer/types';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';
import { ActionBuilderSettingsVariablesDict } from 'src/utils/actionBuilder/types';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';
import { ContextVariableTypeKeys, ContextVariableTypes } from 'src/utils/enums';

export const createContextVariableID = () => uuidv4().replaceAll('-', '_');

export const cvValidators = (
  variables: ActionBuilderSettingsVariablesDict[],
) => {
  const handleCvLengthInputValidation = (
    minLength?: number,
    maxLength?: number,
  ) => {
    // If error return true, else return false
    if (isNumber(maxLength) && isNumber(minLength)) {
      if (minLength > maxLength) {
        return true;
      }
    }

    return false;
  };

  const freqMap = flow(
    groupBy('displayName'),
    mapValues((v: Array<ActionBuilderSettingsVariablesDict>) => v.length),
  )(variables);

  return variables.map(
    ({
      configurationFields,
      displayName,
      isSystemContext,
      listTypeOptions,
      type,
    }) => {
      if (isSystemContext) {
        return null;
      }

      let message: string | null = null;

      if (displayName.length > ALLOWED_DISPLAY_NAME_LENGTH) {
        message = `Display names must be ${ALLOWED_DISPLAY_NAME_LENGTH} characters or less`;
      } else if (ALLOWED_DISPLAY_NAME_CHARACTER_REGEX.test(displayName)) {
        message = 'Display names must not use special characters';
      } else if (freqMap[displayName] > 1) {
        message = 'Display names must be unique';
      } else if (
        handleCvLengthInputValidation(
          configurationFields?.minLength,
          configurationFields?.maxLength,
        )
      ) {
        message = CV_LENGTH_VALIDATION_ERROR;
      } else if (displayName === '') {
        message = 'Display names must be greater than 1 character';
      }

      if (type === 'LIST' && !listTypeOptions?.length && !message) {
        message = 'Must add list options';
      }

      return message;
    },
  );
};

export const formatCustomTicketFieldOptions = (
  customTicketFields: Array<TicketCustomField>,
  helpdesk: Helpdesk,
  includeCategory: boolean,
) => {
  const formattedCustomTicketFields = customTicketFields.map(field => ({
    category: includeCategory
      ? `${capitalizeFirstLetter(helpdesk)} Custom Ticket Fields`
      : '',
    label: field.title,
    optionStartAdornment: (
      <Box display='flex' mr={0.5}>
        <img alt='' src={HELPDESK_LOGOS[helpdesk]} />
      </Box>
    ),
    value: field.id,
  }));

  return formattedCustomTicketFields;
};

export const getTableDataWithCustomTicketFields = (
  variables: Array<ActionBuilderSettingsVariablesDict>,
  contextVarIdToMapping: { [key: string]: CreateTicketFieldMappingRequest },
): Array<ContextVariablesTableData> => {
  return variables.map(cv => {
    const { external_ticket_field_id, is_in_use, mapping_data } =
      contextVarIdToMapping[cv.id] || {};
    return {
      ...cv,
      ticketMappingData: {
        isInUse: !!is_in_use,
        value: external_ticket_field_id || mapping_data?.model_name || '',
      },
    };
  });
};

export const useGetCvCustomFields = (
  cvId: string,
  ticketFieldMappings: CreateTicketFieldMappingRequest[],
) => {
  const contextVarIdToMapping = keyBy(
    'context_variable_id',
    ticketFieldMappings,
  );
  const { external_ticket_field_id, is_in_use, mapping_data } =
    contextVarIdToMapping[cvId] || {};

  return {
    isInUse: !!is_in_use,
    value: external_ticket_field_id || mapping_data?.model_name || '',
  };
};

export const filterCustomTicketFieldsByType = (
  customTicketFields: Array<TicketCustomField>,
  type: keyof typeof ContextVariableTypes,
) => {
  return customTicketFields.filter(field => {
    switch (type) {
      case ContextVariableTypeKeys.SHORT_TEXT: {
        return field.type === 'text';
      }
      case ContextVariableTypeKeys.LIST: {
        return field.type === 'sing_select';
      }
      default: {
        return false;
      }
    }
  });
};

export const allowedTypesByHelpdesk: {
  [key: string]: Array<keyof typeof ContextVariableTypes>;
} = {
  freshdesk: [ContextVariableTypeKeys.SHORT_TEXT],
  intercom: [ContextVariableTypeKeys.SHORT_TEXT, ContextVariableTypeKeys.LIST],
  jira: [ContextVariableTypeKeys.SHORT_TEXT, ContextVariableTypeKeys.LIST],
  kustomer: [ContextVariableTypeKeys.SHORT_TEXT],
  salesforce: [
    ContextVariableTypeKeys.SHORT_TEXT,
    ContextVariableTypeKeys.LIST,
  ],
  zendesk: [ContextVariableTypeKeys.SHORT_TEXT, ContextVariableTypeKeys.LIST],
};

export const useGetMappingDropdownStartAdornment = (
  helpdesk: Helpdesk,
  triageModels: Array<TriageModelDetail>,
  selectedValue: string,
) => {
  const { data: featureFlagsData } = useGetFeatureFlagsQuery();
  const { feature_flags: featureFlags = [] } = featureFlagsData ?? {};
  const isTriageMappingEnabled = featureFlags.includes('triage_model_mapping');

  return useMemo(() => {
    if (!helpdesk) {
      return null;
    }

    const helpdeskLogoSrc = HELPDESK_LOGOS[helpdesk];

    if (!isTriageMappingEnabled) {
      return helpdeskLogoSrc;
    } else if (isTriageMappingEnabled && selectedValue) {
      const selectedModel = triageModels.find(
        model => model.name === selectedValue,
      );

      if (selectedModel) {
        return selectedModel?.icon || '';
      } else {
        return helpdeskLogoSrc;
      }
    } else {
      return '';
    }
  }, [triageModels, helpdesk, selectedValue, isTriageMappingEnabled]);
};
