import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { IconCopy, IconPlus, IconTrash } from '@tabler/icons-react';

import {
  Button,
  IconButton,
  Tooltip,
} from '@forethought-technologies/forethought-elements';
import { formatPhoneNumber } from '../utils';
import { AddNumberModal } from './AddNumberModal';
import { RemoveNumberModal } from './RemoveNumberModal';
import { releasePhoneNumber } from 'src/services/solve-config/solveConfigApi';
import {
  removeBoughtPhoneNumber,
  selectBoughtNumbers,
} from 'src/slices/solve-config/solveConfigSlice';
import { setGlobalToastOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes } from 'src/utils/enums';

const IntegrateTabContent = () => {
  const { palette } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const boughtNumbers = useSelector(selectBoughtNumbers);
  const [isAddNumberModalVisible, setIsAddNumberModalVisible] = useState(false);
  const [isRemoveNumberModalVisible, setIsRemoveNumberModalVisible] =
    useState(false);
  const [phoneNumberToRemove, setPhoneNumberToRemove] = useState<string | null>(
    null,
  );

  const voiceHref = `${location.pathname}?channel=voice&tab=voice`;

  return (
    <Box
      sx={{
        a: {
          textDecoration: 'none',
        },
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <Typography variant='font16Bold'>Number details</Typography>
      <Typography variant='font14'>
        {/* To integrate with your Zendesk Talk, see the detailed guide here.
          Alternatively,  */}
        Add a phone number to get started. Once a phone number is added, go to
        <Link
          onClick={() => {
            navigate(Routes.SOLVE_CONFIGURATION, { replace: true });
          }}
          to={voiceHref}
        >
          {' '}
          Voice{' '}
        </Link>
        tab to configure the voice settings.
      </Typography>
      <Typography variant='font14'>
        Note: newly added numbers can only be deleted after 14 days.
      </Typography>
      {boughtNumbers.map(number => {
        const voiceSetupSearch = `${
          location.pathname
        }?channel=voice&tab=voice&number=${encodeURIComponent(number)}`;
        return (
          <Box
            display='flex'
            justifyContent='space-between'
            key={number}
            pr='16px'
          >
            <Typography variant='font16'>
              {formatPhoneNumber(number)}
            </Typography>
            <Box display='flex' gap='8px'>
              <Button
                onClick={() => {
                  navigate({
                    pathname: Routes.SOLVE_CONFIGURATION,
                    search: voiceSetupSearch,
                  });
                }}
                variant='ghost'
              >
                <Typography
                  color={palette.colors.purple[500]}
                  variant='font14Bold'
                >
                  Voice Setup
                </Typography>
              </Button>
              <Tooltip tooltipContent='Copy'>
                <IconButton
                  aria-label='Copy phone number'
                  onClick={() => {
                    navigator.clipboard.writeText(number);
                  }}
                  variant='ghost'
                >
                  <IconCopy color={palette.colors.purple[500]} size={20} />
                </IconButton>
              </Tooltip>
              <Tooltip tooltipContent='Delete'>
                <IconButton
                  aria-label='Delete phone number'
                  onClick={() => {
                    setPhoneNumberToRemove(number);
                    setIsRemoveNumberModalVisible(true);
                  }}
                  variant='ghost'
                >
                  <IconTrash color={palette.colors.purple[500]} size={20} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        );
      })}
      <Box>
        <Button
          onClick={() => {
            setIsAddNumberModalVisible(true);
          }}
          startIcon={<IconPlus size={20} />}
          variant='secondary'
        >
          Phone number
        </Button>
      </Box>

      <AddNumberModal
        isAddNumberModalVisible={isAddNumberModalVisible}
        setIsAddNumberModalVisible={setIsAddNumberModalVisible}
      />
      <RemoveNumberModal
        isModalVisible={isRemoveNumberModalVisible}
        onDeleteClicked={async () => {
          try {
            const resp = await releasePhoneNumber({
              phoneNumber: phoneNumberToRemove || '',
            });

            if (resp.acknowledged) {
              dispatch(removeBoughtPhoneNumber(phoneNumberToRemove || ''));
            } else {
              dispatch(
                setGlobalToastOptions({
                  title:
                    "We're sorry, but the deletion couldn't be completed at the moment. Please try again later",
                  variant: 'danger',
                }),
              );
            }
            setIsRemoveNumberModalVisible(false);
          } catch (error) {
            dispatch(
              setGlobalToastOptions({
                title: 'Failed to delete a new number. Try again in 14 days',
                variant: 'danger',
              }),
            );
          }
        }}
        setIsModalVisible={setIsRemoveNumberModalVisible}
      />
    </Box>
  );
};

export default IntegrateTabContent;
