import { ReactNode } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IconTag } from '@tabler/icons-react';

import { GLOBAL_WIDGET_CONFIG_TABS } from '../constants';
import { Brand } from 'src/types/brandTypes';
import { Routes } from 'src/utils/enums';

export const WidgetBrandTagLabels = ({ brand }: { brand: Brand }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const tab = params.get('tab') ?? '';
  const brandHref = `${location.pathname}?channel=widget&tab=brand`;

  if (GLOBAL_WIDGET_CONFIG_TABS.includes(tab)) {
    return (
      <Box
        alignItems='center'
        alignSelf='center'
        display='flex'
        gap={0.75}
        overflow='hidden'
      >
        <Box alignItems='center' display='flex' height='26px'>
          <Typography noWrap variant='font14Bold'>
            Enabled for all brands
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      alignItems='center'
      alignSelf='center'
      display='flex'
      gap={0.75}
      maxWidth='588px'
      minHeight='48px'
      overflow='auto'
      width='80%'
    >
      <Box alignItems='center' display='flex' height='26px'>
        <Typography noWrap variant='font14Bold'>
          &lsquo;{brand.brand_name}&rsquo;
          <Typography
            color={theme => theme.palette.colors.grey[500]}
            noWrap
            variant='font14Bold'
          >
            {' '}
            {!brand.tags.length ? 'is' : 'for'}
          </Typography>
        </Typography>
      </Box>
      {brand.tags.map(tag => (
        <BrandTag key={tag}>
          <Typography
            color={theme => theme.palette.grey[800]}
            noWrap
            variant='font12Medium'
          >
            {tag}
          </Typography>
        </BrandTag>
      ))}
      {!brand.tags.length && (
        <Link
          onClick={() => {
            navigate(Routes.SOLVE_CONFIGURATION, { replace: true });
          }}
          to={brandHref}
        >
          <Typography
            color={theme => theme.palette.colors.red[500]}
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
            variant='font14Bold'
          >
            missing a tag
          </Typography>
        </Link>
      )}
    </Box>
  );
};

const BrandTag = ({ children }: { children?: ReactNode }) => {
  const { palette } = useTheme();
  return (
    <Box
      alignItems='center'
      bgcolor={palette.colors.white}
      borderRadius='4px'
      display='flex'
      gap='2px'
      px={1}
      py={0.5}
    >
      <IconTag color={palette.colors.blue[500]} size={16} />
      {children}
    </Box>
  );
};
