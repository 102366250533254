import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';

export interface LeftSidebarProps<T> {
  navigationItems: { icon: React.ReactNode; name: string; tab: T }[];
  onTabChange: (tab: T) => void;
  selectedTab: T;
}

const LeftSidebar = <T extends string>({
  navigationItems,
  onTabChange,
  selectedTab,
}: LeftSidebarProps<T>) => {
  const { palette } = useTheme();

  return (
    <Box
      bgcolor='white'
      display='flex'
      flexDirection='column'
      gap={1.25}
      px={1}
      py={2.5}
      sx={{
        overflowY: 'auto',
      }}
      zIndex={1}
    >
      {navigationItems.map(navItem => {
        const variant = selectedTab === navItem.tab ? 'secondary' : 'ghost';

        return (
          <Box
            alignItems='center'
            display='flex'
            flexDirection='column'
            gap={0.5}
            key={navItem.tab}
            sx={{ button: { height: '40px', width: '40px' } }}
          >
            <Button
              onClick={() => onTabChange(navItem.tab)}
              startIcon={null}
              variant={variant}
            >
              {navItem.icon}
            </Button>
            <Typography color={palette.colors.grey[700]} variant='font11'>
              {navItem.name}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default LeftSidebar;
