import { IntentDataWithTopics } from './types';
import partition from 'lodash/fp/partition';
import {
  apiWorkflowTypes,
  slackWorkflowTypes,
  widgetWorkflowTypes,
} from 'src/pages/workflow-builder-edit/constants';
import { WorkflowTypes } from 'src/pages/workflow-builder-edit/types';
import {
  ConversationChannel,
  IntentData,
  TranslationChannel,
} from 'src/types/workflowBuilderAPITypes';
import { CommonIntentWorkflowType } from 'src/utils/enums';
import { isIntentActiveForChannel } from 'src/utils/solve/intentUtils';

export class IntentTableDataFilter<
  IntentDataType extends IntentDataWithTopics | IntentData,
> {
  constructor(private data: IntentDataType[]) {}

  // Filter by search substrings
  filterByKeyword = (searchText: string) => {
    if (searchText.trim()) {
      this.data = this.data.filter(({ intent_name: name }) => {
        return name.toLowerCase().includes(searchText.trim().toLowerCase());
      });
    }

    return this;
  };

  setIsOtherQuestionsActive = (channel: ConversationChannel) => {
    const knowledgeArticleIntentData = this.getKnowledgeArticle();
    const otherQuestionsIntentData = this.getOtherQuestions();

    if (
      !knowledgeArticleIntentData ||
      !otherQuestionsIntentData ||
      channel === 'email'
    ) {
      return this;
    }

    const isKnowledgeArticleActive = isIntentActiveForChannel(
      knowledgeArticleIntentData.active_workflow_types,
      channel,
    );

    if (isKnowledgeArticleActive) {
      this.setActiveWorkflowTypeOnCondition(
        datum =>
          datum.intent_definition_id ===
          CommonIntentWorkflowType.GENERAL_HANDOFF,
      );
    }

    return this;
  };

  setDefaultHandoffWorkflowActive = (
    channel: TranslationChannel = 'widget',
  ) => {
    const knowledgeArticleIntentData = this.getKnowledgeArticle();
    const otherQuestionsIntentData = this.getOtherQuestions();

    if (!knowledgeArticleIntentData) {
      // it should always exist, this is a to make typescript happy
      return this;
    }

    const isKnowledgeArticleActive = isIntentActiveForChannel(
      knowledgeArticleIntentData.active_workflow_types,
      channel,
    );

    // Show as active if Knowledge Retrieval is active AND Other Questions exists
    if (isKnowledgeArticleActive && otherQuestionsIntentData) {
      this.setActiveWorkflowTypeOnCondition(
        datum => getIsDefaultByChannel(channel, datum),
        [...widgetWorkflowTypes, ...apiWorkflowTypes, ...slackWorkflowTypes],
      );
      // Show as active if Other Questions does not exist, default handoff will be active regardless of knowledge retrieval
    } else if (!otherQuestionsIntentData) {
      this.setActiveWorkflowTypeOnCondition(
        datum => getIsDefaultByChannel(channel, datum),
        [...widgetWorkflowTypes, ...apiWorkflowTypes, ...slackWorkflowTypes],
      );
    }

    return this;
  };

  private getOtherQuestions = () => {
    return this.data.find(
      ({ intent_definition_id: intentId }) =>
        intentId === CommonIntentWorkflowType.GENERAL_HANDOFF,
    );
  };

  private getKnowledgeArticle = () => {
    return this.data.find(
      ({ intent_definition_id: intentId }) =>
        intentId === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE,
    );
  };

  private setActiveWorkflowTypeOnCondition = (
    conditionChecker: (datum: (typeof this.data)[number]) => boolean,
    activeWorkflowTypes: WorkflowTypes[] = [],
  ) => {
    this.data = this.data.map(datum => {
      if (conditionChecker(datum)) {
        return {
          ...datum,
          active_workflow_types: activeWorkflowTypes,
        };
      }

      return datum;
    });
  };

  getTableData = () => {
    return this.data;
  };
}

export const filterCommonIntentWorkflowsToTop = <
  IntentDataType extends IntentDataWithTopics | IntentData,
>(
  table: IntentDataType[],
) => {
  const [workflowsAtTop, otherWorkflows] = partition(
    ({ intent_definition_id }) =>
      intent_definition_id === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE ||
      intent_definition_id === CommonIntentWorkflowType.FALLBACK,
    table,
  );

  const [knowledgeArticle, fallback] = partition(
    ({ intent_definition_id }) =>
      intent_definition_id === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE,
    workflowsAtTop,
  );

  return [...knowledgeArticle, ...fallback, ...otherWorkflows];
};

export const getIsDefaultByChannel = (
  channel: TranslationChannel,
  { is_default_handoff: isDefaultHandoff = false }: Partial<IntentData>,
) => {
  return isDefaultHandoff;
};

export const getWorkflowIdByChannel = (
  channel: TranslationChannel,
  {
    api_workflow_id = '',
    intent_workflow_id = '',
    interactive_email_workflow_id = '',
    playbook_workflow_id = '',
    slack_workflow_id = '',
    voice_workflow_id = '',
  }: Partial<IntentData>,
) => {
  if (channel === 'widget') {
    return intent_workflow_id;
  }

  if (channel === 'slack') {
    return slack_workflow_id;
  }

  if (channel === 'api') {
    return api_workflow_id;
  }

  if (channel === 'interactive_email') {
    return interactive_email_workflow_id;
  }

  if (channel === 'voice') {
    return voice_workflow_id || intent_workflow_id;
  }

  if (channel === 'playbook') {
    return playbook_workflow_id || intent_workflow_id;
  }

  return intent_workflow_id;
};

export const getWorkflowType = (
  {
    api_is_default_handoff,
    api_workflow_is_autoflow: isApiAutoflow,
    intent_definition_id: intentDefinitionId,
    is_autoflow: isAutoflow,
    is_default_handoff,
    is_handoff: isHandoff,
    slack_is_default_handoff,
    slack_workflow_is_autoflow: isSlackAutoflow,
  }: IntentDataWithTopics,
  channel: ConversationChannel,
  isFallbackWorkflowEnabled: boolean | undefined,
) => {
  const isKnowledge =
    intentDefinitionId === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE;
  const isFallback = intentDefinitionId === CommonIntentWorkflowType.FALLBACK;

  if (
    isFallback ||
    (getIsDefaultByChannel(channel, {
      api_is_default_handoff,
      is_default_handoff,
      slack_is_default_handoff,
    }) &&
      !isFallbackWorkflowEnabled)
  ) {
    return 'Fallback';
  }

  if (isHandoff) {
    return 'Handoff';
  }

  if (isKnowledge) {
    return 'Knowledge Retrieval';
  }

  if (channel === 'api') {
    return isApiAutoflow ? 'AutoFlow' : 'Classic';
  }
  if (channel === 'slack') {
    return isSlackAutoflow ? 'AutoFlow' : 'Classic';
  }
  if (channel === 'voice') {
    return 'Autoflow';
  }
  return isAutoflow ? 'AutoFlow' : 'Classic';
};
