export const MAX_KNOWLEDGE_CONFIGURATION_PROMPT_LENGTH = 200;
export const MAX_KNOWLEDGE_CONFIGURATION_PROMPT_LENGTH_EXTRA = 300;

export const KNOWLEDGE_CONFIGURATION_VALIDATION_MESSAGE = ({
  shouldUseLongerNoteLength,
}: {
  shouldUseLongerNoteLength: boolean;
}) =>
  `Keep the note under ${
    shouldUseLongerNoteLength
      ? MAX_KNOWLEDGE_CONFIGURATION_PROMPT_LENGTH_EXTRA
      : MAX_KNOWLEDGE_CONFIGURATION_PROMPT_LENGTH
  } words`;

export const validateKnowledgeConfigurationPrompt = ({
  prompt,
  shouldUseLongerNoteLength,
}: {
  prompt: string;
  shouldUseLongerNoteLength: boolean;
}) => {
  const numWords = prompt.split(' ');
  const maxLength = shouldUseLongerNoteLength
    ? MAX_KNOWLEDGE_CONFIGURATION_PROMPT_LENGTH_EXTRA
    : MAX_KNOWLEDGE_CONFIGURATION_PROMPT_LENGTH;

  if (numWords.length >= maxLength) {
    return KNOWLEDGE_CONFIGURATION_VALIDATION_MESSAGE({
      shouldUseLongerNoteLength,
    });
  }

  return false;
};
