import { Box, styled, useTheme } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import { InlineData } from '../ConversationDrawer/InlineData';
import { formatTimeSpent } from '../ConversationDrawer/utils';
import ConversationTranscript from '../ConversationTranscript';
import { TranscriptContainer } from '../ConversationTranscript/RevampConversationTranscript';
import { formatTimestamp } from '../helpers';
import { Conversation } from 'src/services/apiInterfaces';

export const VoiceDrawerBody = ({
  selectedConversation,
}: {
  selectedConversation: Conversation;
}) => {
  const {
    call_duration,
    channel: conversationChannel,
    conversation_id,
    deflected,
    last_executed_intent_user_query,
    sentiment,
    start_datetime,
    transcript,
    user_queries,
  } = selectedConversation;

  const { palette } = useTheme();

  return (
    <Box p='40px'>
      <Box py='6px'>
        <Typography variant='font24'>Conversation</Typography>
      </Box>
      <Box display='flex' flexDirection='column' gap='30px' py='24px'>
        {/* Info section */}
        <Box display='flex' flexDirection='column' gap='12px'>
          <InlineData label='Chat ID' value={conversation_id} />
          <InlineData label='Deflected' value={deflected ? 'True' : 'False'} />
        </Box>
        {/* Chat section */}
        <Box display='flex' flexDirection='column' gap='10px'>
          <Typography variant='font11'>Conversation</Typography>
          <Card>
            <CardSection>
              <InlineData
                label='Time created'
                value={formatTimestamp(start_datetime + 'Z')}
              />
              <InlineData label='Channel' value={conversationChannel} />
              <InlineData
                label='Call length'
                value={call_duration ? formatTimeSpent(call_duration) : 'n/a'}
              />
              <InlineData label='Sentiment' value={sentiment || 'n/a'} />
            </CardSection>

            <Divider />

            <CardSection>
              <Typography color={palette.colors.grey[600]} variant='font12'>
                User queries
              </Typography>
              {user_queries ? (
                <Box display='flex' flexDirection='column'>
                  {user_queries.map((query, index) => (
                    <i key={index}>
                      <Typography
                        color={palette.colors.blue[600]}
                        variant='font14'
                      >
                        {query}
                      </Typography>
                    </i>
                  ))}
                </Box>
              ) : (
                <i>
                  <Typography color={palette.colors.blue[600]} variant='font14'>
                    &quot;{last_executed_intent_user_query}&quot;
                  </Typography>
                </i>
              )}
            </CardSection>
          </Card>
        </Box>
        <Box display='flex' flexDirection='column' gap='10px'>
          <Typography variant='font11'>Call Transcript</Typography>
          <TranscriptContainer>
            <ConversationTranscript transcript={transcript} variant='drawer' />
          </TranscriptContainer>
        </Box>
      </Box>
    </Box>
  );
};

const Divider = styled('hr')`
  border-width: 0;
  border-bottom-width: thin;
  border-color: ${({ theme }) => theme.palette.colors.slate[200]};
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Card = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.colors.slate[200]};
  border-radius: 8px;
  /* padding: 16px; */
  display: flex;
  flex-direction: column;
`;

const CardSection = styled(Box)<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  ${props => props.gap && `gap: ${props.gap}`}
`;
