import { Box, styled, useTheme } from '@mui/material';

import {
  Skeleton,
  Typography,
} from '@forethought-technologies/forethought-elements';
import {
  dateRangeToTimestamp,
  numbersToStringWithCommas,
} from '../../solve-insights/helpers';
import { useGetWidgetAggregateMetricsQuery } from 'src/services/workflow-builder-metrics';
import { DateRange } from 'src/types/types';

export const OverallCards = ({ dateRange }: { dateRange: DateRange }) => {
  return (
    <Box
      display='flex'
      gap={3}
      justifyContent='space-between'
      minHeight='280px'
      pb={1}
      sx={{
        overflowX: 'scroll',
      }}
    >
      <TotalConversationSavingCard dateRange={dateRange} />
    </Box>
  );
};

export const TotalConversationSavingCard = ({
  dateRange,
}: {
  dateRange: DateRange;
}) => {
  const { palette } = useTheme();
  const timestamps = dateRangeToTimestamp(dateRange);
  const { data: data, isLoading } = useGetWidgetAggregateMetricsQuery({
    channel: 'voice',
    from: timestamps.start_timestamp.toString(),
    to: timestamps.end_timestamp.toString(),
  });

  if (isLoading) {
    return (
      <CardContainer>
        <Skeleton variant='text' width={100} />
        <Skeleton variant='text' width={300} />
        <Skeleton variant='text' width={300} />
      </CardContainer>
    );
  }

  const totalConversations = data?.aggregate.voice?.conversations;
  const deflectedConversations = data?.aggregate.voice?.deflected;

  return (
    <CardContainer>
      <Typography variant='font16Bold'>Conversations</Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '8px',
          justifyContent: 'start',
        }}
      >
        <ConversationDetails
          color={palette.colors.purple[500]}
          label='deflections'
          number={deflectedConversations || 0}
        />
        <ConversationDetails
          color={palette.colors.red[500]}
          label='non-deflections'
          number={
            totalConversations && deflectedConversations
              ? totalConversations - deflectedConversations
              : 0
          }
        />
      </Box>
    </CardContainer>
  );
};

const ConversationDetails = ({
  color,
  label,
  number,
}: {
  color: string;
  label: string;
  number: number;
}) => {
  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: '8px' }}>
      <Box
        sx={{
          alignItems: 'baseline',
          display: 'flex',
          flexDirection: 'row',
          gap: '4px',
        }}
      >
        <Typography variant='font24'>
          {numbersToStringWithCommas({
            number,
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          ':before': {
            backgroundColor: color,
            borderRadius: '2px',
            content: '""',
            display: 'block',
            height: '12px',
            width: '12px',
          },
          alignItems: 'center',
          display: 'flex',
          gap: '4px',
        }}
      >
        <Typography variant='font14'>{label}</Typography>
      </Box>
    </Box>
  );
};

const CardContainer = styled('div')`
  border-radius: 8px;
  border: 1px solid ${props => props.theme.palette.colors.slate[200]};
  background: #fff;
  padding: 24px;
  min-width: 419px;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;
