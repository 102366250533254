import { Box, styled } from '@mui/material';
import { IconX } from '@tabler/icons-react';

import {
  Button,
  IconButton,
  SelectDropdown,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { useGetAvailableConversationAttributes } from './hooks';
import ContextVariableAutocomplete from 'src/components/context-variable-autocomplete';
import ContextVariableSelectDropdown from 'src/components/context-variable-select-dropdown';
import { IntercomLiveChatActionSettings } from 'src/types/workflowBuilderAPITypes';

interface ConversationAttributesProps {
  conversationAttributes: IntercomLiveChatActionSettings['conversation_attributes'];
  setConversationAtributes: (
    attribute: IntercomLiveChatActionSettings['conversation_attributes'],
  ) => void;
}

const ConversationAttributes = ({
  conversationAttributes,
  setConversationAtributes,
}: ConversationAttributesProps) => {
  const availableConversationAttributes =
    useGetAvailableConversationAttributes();

  const removeConversationAttribute = (index: number) => {
    const updatedFieldValues = conversationAttributes?.filter(
      (_, currentIndex) => currentIndex !== index,
    );

    setConversationAtributes(updatedFieldValues || []);
  };

  const addConversationAttribute = () => {
    const currentAttributes = conversationAttributes || [];
    setConversationAtributes([...currentAttributes, { name: '', value: '' }]);
  };

  const updateConversationAttribute = (
    index: number,
    key: 'name' | 'value',
    value: string,
  ) => {
    const updatedFieldValues = conversationAttributes?.map(
      (attribute, currentIndex) => {
        if (currentIndex === index) {
          return {
            ...attribute,
            [key]: value,
          };
        }
        return { ...attribute };
      },
    );

    setConversationAtributes(updatedFieldValues || []);
  };

  const getInputComponent = (
    conversationAttribute: {
      name: string;
      value: string;
    },
    index: number,
  ) => {
    const { data_type, options } =
      availableConversationAttributes.find(
        attribute => attribute.name === conversationAttribute.name,
      ) || {};

    if (data_type === 'list') {
      return (
        <ContextVariableSelectDropdown
          additionalOptions={options?.map(option => {
            return {
              category: 'Attribute Options',
              label: option,
              value: option,
            };
          })}
          aria-label='Select list type conversation attribute'
          onChange={value => {
            updateConversationAttribute(index, 'value', value);
          }}
          placeholder='Select a value'
          shouldIncludeSystemContextVariables
          shouldProvideCVIdFormatting
          value={conversationAttribute.value}
        />
      );
    }

    return (
      <ContextVariableAutocomplete
        aria-label='Field Value'
        onChange={value => {
          updateConversationAttribute(index, 'value', value);
        }}
        placeholder='Insert value'
        shouldIncludeSystemContextVariables
        value={conversationAttribute.value}
      />
    );
  };

  return (
    <Box display='flex' flexDirection='column' gap='15px'>
      <Typography variant='font14Medium'>Conversation Attributes</Typography>
      <Box display='flex' flexDirection='column' gap='8px'>
        {conversationAttributes?.map((conversationAttribute, index) => {
          return (
            <Box
              alignItems='center'
              display='flex'
              gap='8px'
              key={conversationAttribute.name}
            >
              <SelectWrapper>
                <SelectDropdown
                  id={conversationAttribute.name}
                  isMenuSearchable
                  menuMaxHeight={180}
                  onChange={e => {
                    const value = e?.target.value;
                    if (value) {
                      updateConversationAttribute(index, 'name', value);
                    }
                  }}
                  options={availableConversationAttributes.map(attribute => ({
                    label: attribute.name,
                    value: attribute.name,
                  }))}
                  placeholder='Select a Field'
                  value={conversationAttribute.name}
                />
              </SelectWrapper>
              <Box
                sx={{
                  flex: '1 1 50%',
                }}
              >
                {getInputComponent(conversationAttribute, index)}
              </Box>
              <IconButton
                aria-label='Remove conversation attribute'
                onClick={() => removeConversationAttribute(index)}
                variant='ghost'
              >
                <IconX size={24} />
              </IconButton>
            </Box>
          );
        })}
        <Box alignSelf='flex-start'>
          <Button
            disabled={
              conversationAttributes?.length ===
              availableConversationAttributes.length
            }
            onClick={() => addConversationAttribute()}
            variant='ghost'
          >
            + Add Field
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ConversationAttributes;

const SelectWrapper = styled('div')`
  flex: 1 1 50%;
`;
