import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, styled } from '@mui/material';

import { SelectDropdown } from '@forethought-technologies/forethought-elements';
import { formatPhoneNumber } from '../utils';
import { VoiceConfig } from './VoiceConfig';
import { useStateParams } from 'src/hooks/hooks';
import {
  getVoiceConfigurationByPhoneNumber,
  selectBoughtNumbers,
  selectSelectedPhoneNumber,
  setSelectedPhoneNumber,
} from 'src/slices/solve-config/solveConfigSlice';
import { useAppDispatch } from 'src/store/hooks';
import { genericSerializeAndDeserialize } from 'src/utils/discover/helpers';

const VoiceTabContent = () => {
  const dispatch = useAppDispatch();
  const selectedNumber = useSelector(selectSelectedPhoneNumber);
  const boughtNumbers = useSelector(selectBoughtNumbers);
  const [number, setNumber] = useStateParams<string>({
    deserialize: (param: string) => param,
    initialState: '',
    paramsName: 'number',
    serialize: genericSerializeAndDeserialize,
  });

  const boughtNumberOptions = useMemo(() => {
    return boughtNumbers.map(number => ({
      label: formatPhoneNumber(number),
      value: number,
    }));
  }, [boughtNumbers]);

  useEffect(() => {
    if (selectedNumber === number) {
      return;
    }

    // number is set but selected number is not set, fetch the config for the number
    if (number && number !== selectedNumber) {
      dispatch(
        getVoiceConfigurationByPhoneNumber({
          phoneNumber: number,
        }),
      );
    }

    // in the case that user leaves voice and tab and come back, we should update the
    // number to be the selectedNumber that the user was previously viewing
    if (!number && selectedNumber) {
      setNumber(selectedNumber);
    }
  }, [selectedNumber, number, setNumber, dispatch]);

  useEffect(() => {
    // no number selected or selected number still exists in the bought numbers
    if (!selectedNumber || boughtNumbers.includes(selectedNumber)) {
      return;
    }

    // selected number is no longer in the bought numbers, unset it
    dispatch(setSelectedPhoneNumber(null));
  }, [boughtNumbers, selectedNumber, dispatch]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      <SelectDropdown
        id='phone-numbers'
        onChange={e => {
          // this would trigger the useEffect to fetch configuration
          setNumber(e.target.value as string);
        }}
        options={boughtNumberOptions}
        placeholder='Select a phone number to edit configuration'
        value={number || ''}
      />
      <Separator />
      <VoiceConfig />
    </Box>
  );
};

const Separator = styled('div')`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.palette.colors.grey[100]};
`;

export default VoiceTabContent;
