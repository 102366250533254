import { PlaybookTabKeys } from './types';

export const getTitle = (currentTab: PlaybookTabKeys) => {
  switch (currentTab) {
    case 'tone-of-voice':
      return 'Tone of voice';
    case 'quick-feedback':
      return 'Quick feedback';
    case 'knowledge':
      return 'Knowledge base';
    default:
      return '';
  }
};
