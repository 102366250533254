import { useCallback, useMemo } from 'react';
import { createMRTColumnHelper, MRT_Cell, MRT_Row } from 'material-react-table';
import Skeleton from 'react-loading-skeleton';
import { NavigateFunction } from 'react-router';
import { Box, Palette } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';

import {
  Button,
  Tooltip,
  Typography,
} from '@forethought-technologies/forethought-elements';
import OverflowDropdown from '../common/overflow-dropdown/OverflowDropdown';
import { INSIGHT_TOOLTIP_COPY, TABLE_COLUMN_TO_LABEL_MAP } from '../constants';
import { numbersToStringWithCommas, numberToCurrency } from '../helpers';
import partition from 'lodash/fp/partition';
import dynamicArticleSuggestionIcon from 'src/assets/images/dynamic-article-suggestion-revamped.svg';
import iconFallbackColorful from 'src/assets/images/fallback-colorful.svg';
import StatusIndicator from 'src/components/status-indicator/StatusIndicator';
import { NA, TEMP_BLUE } from 'src/constants/solve';
import { SMALL_CELL_WIDTH } from 'src/pages/intent-conversation-analytics/constants';
import { formatCSAT } from 'src/pages/intent-conversation-analytics/helpers';
import {
  FALLBACK_INTENT_DEF_ID,
  KNOWLEDGE_RETRIEVAL_INTENT_DEF_ID,
} from 'src/pages/solve-config/constants';
import { BreakdownPaletteTooltip } from 'src/pages/workflow-builder/BreakdownPaletteTooltip';
import {
  RelevanceTooltip,
  UserEngagementTooltip,
} from 'src/pages/workflow-builder/intent-workflows-table/containers/WidgetIntentWorkflowsTableContainer';
import { HorizontalStackedBar } from 'src/pages/workflow-builder/intent-workflows-table/HorizontalStackedBar';
import { InsightWorkflow } from 'src/services/insights/types';
import { capitalizeFirstLetter } from 'src/utils/capitalizeFirstLetter';
import { CommonIntentWorkflowType, Routes } from 'src/utils/enums';
import { calculateSum } from 'src/utils/solve/deflectionInsightsUtils';

export const buildWorkflowColumns = (
  palette: Palette,
  navigate: NavigateFunction,
  getCSATColor: (score?: number | undefined) => string,
  costPerTicket?: number,
) => {
  const columnHelper = createMRTColumnHelper<InsightWorkflow>();

  const columns = [
    columnHelper.accessor('intent_title', {
      Cell: ({
        renderedCellValue,
        row,
      }: {
        renderedCellValue: React.ReactNode;
        row: MRT_Row<InsightWorkflow>;
      }) => {
        const intentDefinitionId = row.original.intent_definition_id;
        const showBold = [
          FALLBACK_INTENT_DEF_ID,
          KNOWLEDGE_RETRIEVAL_INTENT_DEF_ID,
        ].includes(intentDefinitionId);
        const title =
          intentDefinitionId === 'general-handoff'
            ? 'Other Questions'
            : renderedCellValue;
        return (
          <Box alignItems='center' columnGap={1} display='flex'>
            <Typography variant={showBold ? 'font14Bold' : 'font14'}>
              {title}
            </Typography>
          </Box>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.workflow.intent_title,
      size: 250,
    }),
    columnHelper.accessor('is_active', {
      Cell: ({ row }: { row: MRT_Row<InsightWorkflow> }) => {
        const { is_active: isActive, is_loading: isLoading } = row.original;
        if (isLoading) {
          return <Skeleton width='100px' />;
        }
        return (
          <StatusIndicator
            height={8}
            label={isActive ? 'On' : 'Off'}
            status={isActive ? 'active' : 'idle'}
            width={8}
          />
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.workflow.is_active,
      size: 150,
    }),
    columnHelper.accessor('channel', {
      Cell: ({ row }: { row: MRT_Row<InsightWorkflow> }) => {
        const { channel, is_loading: isLoading } = row.original;
        const value = channel ? capitalizeFirstLetter(channel) : NA;
        if (isLoading) {
          return <Skeleton width='100px' />;
        }

        return <Typography variant='font14'>{value}</Typography>;
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.conversation.channel,
      size: SMALL_CELL_WIDTH,
    }),
    columnHelper.accessor('is_autoflow', {
      Cell: ({
        cell,
        row,
      }: {
        cell: MRT_Cell<InsightWorkflow, boolean>;
        row: MRT_Row<InsightWorkflow>;
      }) => {
        const isAutoflow = cell.getValue();
        const intentDefinitionId = row.original.intent_definition_id;
        const isHandoff = row.original.is_handoff;
        let icon = <></>;
        let label = isAutoflow ? 'Autoflow' : 'Classic';
        if (isHandoff) {
          label = 'Handoff';
        }
        if (intentDefinitionId == FALLBACK_INTENT_DEF_ID) {
          label = 'Fallback';
          icon = (
            <img alt='' height='24' src={iconFallbackColorful} width='24' />
          );
        }
        if (intentDefinitionId == KNOWLEDGE_RETRIEVAL_INTENT_DEF_ID) {
          label = 'Knowledge Retrieval';
          icon = (
            <img
              alt=''
              height='24'
              src={dynamicArticleSuggestionIcon}
              width='24'
            />
          );
        }
        return (
          <Box display='flex' flexDirection='column'>
            <Box alignItems='center' display='flex' gap='4px'>
              {icon}
              <Typography variant='font14'>{label}</Typography>
            </Box>
          </Box>
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.workflow.is_autoflow,
      size: 250,
    }),
    columnHelper.accessor('chats', {
      Cell: ({ row }: { row: MRT_Row<InsightWorkflow> }) => {
        const { chats, is_loading: isLoading } = row.original;

        if (isLoading) {
          return <Skeleton width='100px' />;
        }

        return (
          <Box display='flex' flexDirection='column'>
            <Box>
              <Typography variant='font14'>
                {numbersToStringWithCommas({
                  number: chats,
                })}
              </Typography>
            </Box>
          </Box>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.workflow.chats,
    }),
    columnHelper.accessor('deflected_count', {
      Cell: ({ row }: { row: MRT_Row<InsightWorkflow> }) => {
        const {
          chats,
          deflected_count: deflectedCount,
          is_loading: isLoading,
        } = row.original;
        const rate = ((deflectedCount / chats) * 100).toFixed(0);
        const isRateNaN = isNaN(Number(rate));

        if (isLoading) {
          return <Skeleton width='100px' />;
        }

        return (
          <Box display='flex' flexDirection='column'>
            <Box>
              <Typography variant='font14'>
                {numbersToStringWithCommas({
                  number: deflectedCount,
                })}{' '}
              </Typography>
              {!isRateNaN && (
                <Typography color={palette.colors.grey[600]} variant='font14'>
                  ({rate}%)
                </Typography>
              )}
            </Box>
          </Box>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.workflow.deflected_count,
    }),
    columnHelper.accessor('non_deflected_count', {
      Cell: ({ row }: { row: MRT_Row<InsightWorkflow> }) => {
        const {
          is_loading: isLoading,
          non_deflected_count: nonDeflectedCount,
        } = row.original;

        if (isLoading) {
          return <Skeleton width='100px' />;
        }

        return (
          <Box display='flex' flexDirection='column'>
            <Box>
              <Typography variant='font14'>
                {numbersToStringWithCommas({
                  number: nonDeflectedCount,
                })}
              </Typography>
            </Box>
          </Box>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.workflow.non_deflected_count,
      size: 200,
    }),
    columnHelper.accessor('avg_csat', {
      Cell: ({ row }: { row: MRT_Row<InsightWorkflow> }) => {
        const { avg_csat: avgCsat, is_loading: isLoading } = row.original;

        if (isLoading) {
          return <Skeleton width='100px' />;
        }

        return (
          <Box display='flex' flexDirection='column'>
            <Box>
              <Typography
                color={getCSATColor(avgCsat ?? undefined)}
                variant='font14'
              >
                {formatCSAT(avgCsat)}
              </Typography>
            </Box>
          </Box>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.workflow.avg_csat,
    }),
    columnHelper.accessor('surfaced_articles', {
      Cell: ({ row }: { row: MRT_Row<InsightWorkflow> }) => {
        const {
          intent_workflow_id: intentWorkflowId,
          is_loading: isLoading,
          surfaced_articles: articles,
        } = row.original;

        const handleNavigation = useCallback(
          (
            e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
            intentWorkflowId: string,
          ) => {
            e.stopPropagation();
            const search = '?tableTab=1&articles_sort_column=times_surfaced';
            const pathName = Routes.SOLVE_INSIGHTS_WORKFLOW_DETAIL.replace(
              ':workflowId',
              intentWorkflowId,
            );

            navigate({
              pathname: pathName,
              search: search,
            });
          },
          [],
        );

        const NavigateComponent = useMemo(
          () => (
            <Box sx={{ display: 'flex', padding: '4px 16px' }}>
              <Button
                onClick={e => handleNavigation(e, intentWorkflowId)}
                variant='secondary'
              >
                View all
              </Button>
            </Box>
          ),
          [intentWorkflowId, handleNavigation],
        );

        if (isLoading) {
          return <Skeleton width='100px' />;
        }

        if (articles?.length > 0) {
          return (
            <OverflowDropdown
              data={row.original.surfaced_articles}
              menuTitle='Articles surfaced in this chat'
              NavigateComponent={NavigateComponent}
              scope='workflow'
              tab='workflow'
              variant='article'
            />
          );
        }
        return (
          <Typography color={palette.colors.grey[600]} variant='font14'>
            {NA}
          </Typography>
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.workflow.surfaced_articles,
      Header: () => {
        return (
          <Box alignItems='center' display='flex' gap='4px'>
            {TABLE_COLUMN_TO_LABEL_MAP.workflow.surfaced_articles}
            <Tooltip
              tooltipContent={INSIGHT_TOOLTIP_COPY.chats_surfaced_articles}
            >
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        );
      },
      size: 260,
    }),
    columnHelper.accessor('feedback_breakdown', {
      Cell: ({ row }: { row: MRT_Row<InsightWorkflow> }) => {
        const { feedback_breakdown: feedbackBreakdown, is_loading: isLoading } =
          row.original;

        if (isLoading) {
          return <Skeleton width='100px' />;
        }

        const totalFeedback =
          feedbackBreakdown.positive +
          feedbackBreakdown.negative +
          feedbackBreakdown.unanswered;
        if (totalFeedback < 1) {
          return (
            <Typography color={palette.colors.grey[600]} variant='font14'>
              {NA}
            </Typography>
          );
        }
        return (
          <Box flex={1} maxWidth={140} padding='16px 16px 0 0'>
            <Tooltip
              fullWidth
              maxWidth={320}
              tooltipContent={
                <BreakdownPaletteTooltip
                  values={[
                    {
                      color: palette.colors.green[500],
                      label: 'Positive',
                      value: feedbackBreakdown.positive,
                    },
                    {
                      color: palette.colors.red[500],
                      label: 'Negative',
                      value: feedbackBreakdown.negative,
                    },
                    {
                      color: palette.colors.slate[100],
                      label: 'Not answered',
                      value: feedbackBreakdown.unanswered,
                    },
                  ]}
                />
              }
            >
              <HorizontalStackedBar
                data={[
                  {
                    color: palette.colors.green[500],
                    value: feedbackBreakdown.positive,
                  },
                  {
                    color: palette.colors.red[500],
                    value: feedbackBreakdown.negative,
                  },
                ]}
              />
            </Tooltip>
          </Box>
        );
      },
      enableSorting: false,
      Header: () => (
        <Box display='flex' gap='4px'>
          {TABLE_COLUMN_TO_LABEL_MAP.workflow.feedback_breakdown}
          <Box display='flex'>
            <Tooltip
              tooltipContent={INSIGHT_TOOLTIP_COPY.articles_user_feedback}
            >
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        </Box>
      ),
      header: 'Quick feedback',
    }),
    columnHelper.accessor('relevance_breakdown', {
      Cell: ({ row }: { row: MRT_Row<InsightWorkflow> }) => {
        const {
          is_loading: isLoading,
          relevance_breakdown: relevanceBreakdown,
        } = row.original;

        if (isLoading) {
          return <Skeleton width='100px' />;
        }

        if (
          !relevanceBreakdown ||
          calculateSum(Object.values(relevanceBreakdown)) === 0
        ) {
          return (
            <Typography color={palette.colors.grey[500]} variant='font14'>
              {NA}
            </Typography>
          );
        }
        return (
          <Box flex={1} maxWidth={140} padding='16px 16px 0 0'>
            <Tooltip
              fullWidth
              maxWidth={320}
              tooltipContent={
                <BreakdownPaletteTooltip
                  values={[
                    {
                      color: TEMP_BLUE,
                      label: 'Relevant',

                      value: relevanceBreakdown.relevant_count,
                    },
                    {
                      color: palette.colors.blue[400],
                      label: 'Somewhat relevant',
                      value: relevanceBreakdown.somewhat_relevant_count,
                    },
                    {
                      color: palette.colors.red[500],
                      label: 'Irrelevant',
                      value: relevanceBreakdown.irrelevant_count,
                    },
                  ]}
                />
              }
            >
              <HorizontalStackedBar
                data={[
                  {
                    color: TEMP_BLUE,
                    value: relevanceBreakdown.relevant_count,
                  },
                  {
                    color: palette.colors.blue[400],
                    value: relevanceBreakdown.somewhat_relevant_count,
                  },
                  {
                    color: palette.colors.red[500],
                    value: relevanceBreakdown.irrelevant_count,
                  },
                ]}
              />
            </Tooltip>
          </Box>
        );
      },
      Header: () => (
        <Box alignItems='center' display='flex' gap='4px'>
          {TABLE_COLUMN_TO_LABEL_MAP.workflow.relevance_breakdown}
          <Tooltip
            maxWidth={320}
            tooltipContent={
              <RelevanceTooltip text={INSIGHT_TOOLTIP_COPY.relevance} />
            }
          >
            <IconInfoCircle color={palette.colors.grey[700]} size={20} />
          </Tooltip>
        </Box>
      ),
      header: TABLE_COLUMN_TO_LABEL_MAP.article.relevance_breakdown,
      id: 'relevance_breakdown',
      size: 180,
    }),
    columnHelper.accessor('dropoff_breakdown', {
      Cell: ({ row }: { row: MRT_Row<InsightWorkflow> }) => {
        const { dropoff_breakdown: dropoffBreakdown, is_loading: isLoading } =
          row.original;

        if (isLoading) {
          return <Skeleton width='100px' />;
        }

        if (
          !dropoffBreakdown ||
          calculateSum(Object.values(dropoffBreakdown)) === 0
        ) {
          return (
            <Typography color={palette.colors.grey[500]} variant='font14'>
              {NA}
            </Typography>
          );
        }
        return (
          <Box flex={1} maxWidth={140} padding='16px 16px 0 0'>
            <Tooltip
              fullWidth
              maxWidth={320}
              tooltipContent={
                <BreakdownPaletteTooltip
                  values={[
                    {
                      color: TEMP_BLUE,
                      label: 'Yes',
                      value: dropoffBreakdown.no_dropoff_count,
                    },
                    {
                      color: palette.colors.red[500],
                      label: 'No',
                      value: dropoffBreakdown.dropoff_count,
                    },
                  ]}
                />
              }
            >
              <HorizontalStackedBar
                data={[
                  {
                    color: TEMP_BLUE,
                    value: dropoffBreakdown.no_dropoff_count,
                  },
                  {
                    color: palette.colors.red[500],
                    value: dropoffBreakdown.dropoff_count,
                  },
                ]}
              />
            </Tooltip>
          </Box>
        );
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.workflow.dropoff_breakdown,
      Header: () => (
        <Box alignItems='center' display='flex' gap='4px'>
          {TABLE_COLUMN_TO_LABEL_MAP.workflow.dropoff_breakdown}

          <Tooltip
            maxWidth={320}
            tooltipContent={
              <UserEngagementTooltip text={INSIGHT_TOOLTIP_COPY.engagement} />
            }
          >
            <IconInfoCircle color={palette.colors.grey[700]} size={20} />
          </Tooltip>
        </Box>
      ),
      id: 'dropoff_breakdown',
      size: 250,
    }),
    columnHelper.accessor(row => row, {
      Cell: ({ row }: { row: MRT_Row<InsightWorkflow> }) => {
        const { engagement_rate: engagementRate, is_loading: isLoading } =
          row.original;

        if (isLoading) {
          return <Skeleton width='100px' />;
        }

        return `${(engagementRate * 100).toFixed(1)}%`;
      },
      header: TABLE_COLUMN_TO_LABEL_MAP.workflow.engagement_rate,
      id: 'engagement_rate',
      size: 200,
    }),
    columnHelper.accessor('realized_savings', {
      Cell: ({ row }: { row: MRT_Row<InsightWorkflow> }) => {
        const { is_loading: isLoading, realized_savings: realizedSavings } =
          row.original;

        if (isLoading) {
          return <Skeleton width='100px' />;
        }

        return (
          <Box display='flex' flexDirection='column'>
            <Box>
              <Typography variant='font14'>
                {numberToCurrency({ number: realizedSavings })}
              </Typography>
            </Box>
          </Box>
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.workflow.realized_savings,
      Header: () => {
        const tooltipForSavings = getTooltipForMetric(
          'realized_savings',
          costPerTicket,
        );
        return (
          <Box alignItems='center' display='flex' gap='4px'>
            {TABLE_COLUMN_TO_LABEL_MAP.workflow.realized_savings}

            <Tooltip maxWidth={320} tooltipContent={tooltipForSavings}>
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        );
      },
      id: 'realized_savings',
      size: 210,
    }),
    columnHelper.accessor('potential_savings', {
      Cell: ({ row }: { row: MRT_Row<InsightWorkflow> }) => {
        const { is_loading: isLoading, potential_savings: potentialSavings } =
          row.original;

        if (isLoading) {
          return <Skeleton width='100px' />;
        }

        return (
          <Box display='flex' flexDirection='column'>
            <Box>
              <Typography variant='font14'>
                {numberToCurrency({ number: potentialSavings })}
              </Typography>
            </Box>
          </Box>
        );
      },
      enableSorting: false,
      header: TABLE_COLUMN_TO_LABEL_MAP.workflow.potential_savings,
      Header: () => {
        const tooltipContent = getTooltipForMetric(
          'potential_savings',
          costPerTicket,
        );
        return (
          <Box alignItems='center' display='flex' gap='4px'>
            {TABLE_COLUMN_TO_LABEL_MAP.workflow.potential_savings}
            <Tooltip maxWidth={320} tooltipContent={tooltipContent}>
              <IconInfoCircle color={palette.colors.grey[700]} size={20} />
            </Tooltip>
          </Box>
        );
      },
      id: 'potential_savings',
      size: 210,
    }),
  ];

  return columns.filter(
    (item): item is NonNullable<typeof item> => item !== null,
  );
};

export const filterCommonIntentWorkflowsToTop = (table: InsightWorkflow[]) => {
  const [workflowsAtTop, otherWorkflows] = partition(
    ({ intent_definition_id }) =>
      intent_definition_id === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE ||
      intent_definition_id === CommonIntentWorkflowType.FALLBACK,
    table,
  );

  const [knowledgeArticle, fallback] = partition(
    ({ intent_definition_id }) =>
      intent_definition_id === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE,
    workflowsAtTop,
  );

  return [...knowledgeArticle, ...fallback, ...otherWorkflows];
};

export const getWorkflowType = ({
  intent_definition_id: intentDefinitionId,
  is_autoflow: isAutoflow,
  is_handoff: isHandoff,
}: InsightWorkflow) => {
  const isKnowledge =
    intentDefinitionId === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE;
  const isFallback = intentDefinitionId === CommonIntentWorkflowType.FALLBACK;

  if (isFallback) {
    return 'Fallback';
  }

  if (isHandoff) {
    return 'Handoff';
  }

  if (isKnowledge) {
    return 'Knowledge Retrieval';
  }

  if (isAutoflow) {
    return 'AutoFlow';
  }

  return 'Classic';
};

export class WorkflowTableDataFilter {
  constructor(private data: InsightWorkflow[]) {}
  fetchData = () => this.data;

  mergeKrData = (isLoading: boolean, krData: InsightWorkflow[]) => {
    if (!isLoading) {
      // Remove placeholder KR rows and add actual
      this.data = [
        ...this.data.filter(
          row =>
            !row.is_loading &&
            row.intent_definition_id !==
              CommonIntentWorkflowType.KNOWLEDGE_ARTICLE,
        ),
        ...krData,
      ];
    }
    return this.data;
  };

  filterByType = (filters: string[]) => {
    this.data = this.data.filter(row => {
      const type = getWorkflowType(row);

      const isActive = row.is_active;

      const hasActivateFilters = filters.some(filter =>
        filter.startsWith('activate.'),
      );

      // Only filter handoff if that is the only filter selected
      const hasTypeFilters =
        filters.some(filter => filter.startsWith('types.handoff')) &&
        filters.length === 1;

      const isActivateFilterMatch =
        (filters.includes('activate.on') && isActive) ||
        (filters.includes('activate.off') && !isActive);

      const isTypeFilterMatch =
        filters.includes('types.handoff') && type === 'Handoff';

      return (
        (!hasActivateFilters || isActivateFilterMatch) &&
        (!hasTypeFilters || isTypeFilterMatch)
      );
    });

    return this.data;
  };
}

export const getTooltipForMetric = (
  label: string,
  costPerTicket: number | null = null,
): string => {
  switch (label) {
    case 'realized_savings':
      if (costPerTicket) {
        return `Dollarized savings assuming a $${costPerTicket} cost per deflection.`;
      }
    case 'potential_savings':
      return `Maximum potential savings you could have realized with workflow automation, assuming a $${costPerTicket} cost per deflection.`;
    default:
      return '';
  }
};
