import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatorType } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Drawer } from '@forethought-technologies/forethought-elements';
import FormSkeleton from '../integration-form/FormSkeleton';
import { useConnectorFormData } from './hooks';
import IntegrationOverview from './integration-overview';
import IntegrationDrawerBody from './IntegrationDrawerBody';
import IntegrationDrawerHeader from './IntegrationDrawerHeader';
import { DrawerBody } from './styles';
import isEqual from 'lodash/fp/isEqual';
import {
  Connector,
  ConnectorDefinition,
  Credentials,
} from 'src/services/apiInterfaces';
import {
  selectConnectors,
  setIsUpdateError,
} from 'src/slices/connectors/connectorsSlice';

interface IntegrationDrawerProps {
  connectorDefinition?: ConnectorDefinition;
  existingNames?: string[];
  isCollectionsPage: boolean;
  multiConnectorsEnabled: boolean;
  onClose: React.MouseEventHandler;
  selectedConnector?: Connector;
}

export default function IntegrationDrawer({
  connectorDefinition,
  existingNames,
  multiConnectorsEnabled,
  onClose,
  selectedConnector,
}: IntegrationDrawerProps) {
  const dispatch = useDispatch();
  const { isUpdateError } = useSelector(selectConnectors);
  const [selectedConnectorDefinition, setSelectedConnectorDefinition] =
    useState(connectorDefinition);

  useEffect(() => {
    if (!isEqual(selectedConnectorDefinition, connectorDefinition)) {
      setSelectedConnectorDefinition(connectorDefinition);
    }
  }, [selectedConnectorDefinition, connectorDefinition]);

  const { credentialSchema, formData, setFormData } = useConnectorFormData({
    connectorDefinition: selectedConnectorDefinition,
    connectorId: selectedConnector?.connector_id,
  });

  const alertProps = useMemo(() => {
    if (isUpdateError) {
      return {
        children: 'Please ensure that the provided credentials are correct',
        onClose: () => dispatch(setIsUpdateError(false)),
        title: 'Something went wrong processing your request',
        variant: 'danger',
      } as const;
    }
  }, [dispatch, isUpdateError]);

  return (
    <>
      <Drawer
        alertProps={alertProps}
        isOpen={!!selectedConnectorDefinition}
        onClose={onClose}
      >
        {selectedConnectorDefinition && (
          <DrawerBody>
            <IntegrationDrawerHeader
              connectorDefinition={selectedConnectorDefinition}
              selectedConnector={selectedConnector}
            />

            {!credentialSchema &&
              selectedConnectorDefinition.setup_type !==
                'THIRD_PARTY_TRAY_WIZARD' && <FormSkeleton />}
            {credentialSchema && (
              <>
                <IntegrationOverview
                  connectorDefinition={selectedConnectorDefinition}
                  selectedConnector={selectedConnector}
                />
                <IntegrationDrawerBody
                  connectorDefinition={selectedConnectorDefinition}
                  existingNames={existingNames}
                  formProps={{
                    formData,
                    onChange: e => {
                      if (e.formData) {
                        setFormData(e.formData);
                      }
                    },
                    schema: credentialSchema,
                    validator: validator as ValidatorType<Credentials>,
                  }}
                  multiConnectorsEnabled={multiConnectorsEnabled}
                  selectedConnector={selectedConnector}
                />
              </>
            )}
          </DrawerBody>
        )}
      </Drawer>
    </>
  );
}
