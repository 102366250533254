import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Box, styled } from '@mui/material';

import { selectWidgetConfiguration } from 'src/slices/solve-config/solveConfigSlice';
import { WidgetConfiguration } from 'src/slices/solve-config/types';

interface MinimizedWidgetProps {
  children?: ReactNode;
}

const MinimizedWidget = ({ children }: MinimizedWidgetProps) => {
  const { font, placement } = useSelector(selectWidgetConfiguration);
  const [params] = useSearchParams();
  const tab = params.get('tab');

  return (
    <Container placement={placement}>
      <FakeBrowserHeader placement={placement} />
      <ContentContainer font={font} placement={placement}>
        {/* Content */}
        <Box
          display='flex'
          height='100%'
          justifyContent={
            tab === 'theme'
              ? placement === 'bottom_left'
                ? 'flex-start'
                : 'flex-end'
              : 'center'
          }
        >
          {children}
        </Box>
        {/* TODO - create bubble that is customizable */}
      </ContentContainer>
    </Container>
  );
};

const Container = styled('div')<{
  placement: WidgetConfiguration['placement'];
}>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.palette.colors.purple[500]};
  height: 100%;
  width: 100%;
  overflow: hidden;

  ${props =>
    props.placement === 'bottom_left'
      ? `
        border-right: none;
        border-radius: 10px 0px 0px 10px;
  `
      : `
        border-left: none;
        border-radius: 0px 10px 10px 0px;
      `}

  ${props =>
    props.placement === 'bottom_left'
      ? 'margin-right: -32px;'
      : 'margin-left: -32px;'}
`;

const FakeBrowserHeader = styled('div')<{
  placement: WidgetConfiguration['placement'];
}>`
  overflow: auto;
  border: 1px solid ${props => props.theme.palette.colors.purple[500]};
  height: 30px;

  ${props =>
    props.placement === 'bottom_left'
      ? `
        border-right: none;
        border-radius: 5px 0px 0px 5px;
        margin: 11px 0 13px 100px;
  `
      : `
        border-left: none;
        border-radius: 0px 5px 5px 0px;
        margin: 11px 100px 13px 0px;
      `}
`;

const ContentContainer = styled('div')<{
  font: string;
  placement: WidgetConfiguration['placement'];
}>`
  height: 100%;
  overflow: auto;
  background: linear-gradient(
    331.81deg,
    rgba(255, 255, 255, 0.79) 34.78%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: ${props =>
    props.placement === 'bottom_left'
      ? '0px 0px 0px 10px'
      : '0px 0px 10px 0px'};
  border-top: 1px solid ${props => props.theme.palette.colors.purple[500]};
  padding: 24px;
  font-family: ${props => props.font} !important;

  *,
  ::before,
  ::after {
    font-family: inherit !important;
  }
`;

export default MinimizedWidget;
