import { styled } from '@mui/material';

export const WidgetContentContainer = styled('div')<{ spaceBetween?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${props =>
    props.spaceBetween === false ? undefined : 'space-between'};
  padding: 0 8px 0 8px;
  gap: 32px;
  flex-grow: 1;
`;

// this is the same as the ImageContainer in solve-ui Modal.tsx
export const CustomImage = styled('img')`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  object-fit: contain;
  align-self: flex-end;
  margin-right: -16px;
  z-index: 1;
`;

export const HeaderLogo = styled('img')`
  height: 32px;
  width: 32px;
  border-radius: 4px;
  object-fit: contain;
`;

export const AgentMessageContainer = styled('div')`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const AgentMessage = styled('div')`
  background-color: ${props => props.theme.palette.colors.slate[100]};
  border-radius: 24px;
  max-width: 426px;
  width: fit-content;
  padding: 0 16px;
  word-break: break-word;
  min-height: 50px;
  display: flex;
  align-items: center;

  a {
    color: var(--color-blue-500);
  }

  a:visited {
    color: var(--color-purple-600);
  }

  a:hover {
    color: var(--color-purple-600);
    text-decoration: none;
  }

  a:active {
    color: var(--color-purple-700);
  }
`;

export const CustomerMessageContainer = styled('div')`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 8px;
`;

export const CustomerMessage = styled('div')`
  background-color: ${props => props.theme.palette.colors.black};
  border-radius: 8px;
  max-width: 426px;
  padding: 8px 16px;
  word-break: break-word;
`;

export const TabContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Field = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const RTEContainer = styled('div')<{ hasError?: boolean }>`
  border: 1px solid
    ${props =>
      props.hasError
        ? props.theme.palette.colors.red[500]
        : props.theme.palette.colors.grey[300]};
  border-radius: 6px;
  width: 100%;
`;

export const ChatContainer = styled('div')`
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ResponseMessage = styled('div')<{ backgroundColor: string }>`
  align-self: flex-end;
  background-color: ${props => props.backgroundColor};
  border-radius: 24px;
  padding: 10px 16px;
  min-height: 50px;
  display: flex;
  align-items: center;
`;
