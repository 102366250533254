import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { Box, styled, Typography, useTheme } from '@mui/material';

import {
  Badge,
  Checkbox,
  SelectDropdown,
  TextField,
  theme,
  Toggle,
} from '@forethought-technologies/forethought-elements';
import { quickFeedbackOneToTwoOptions } from '../constants';
import {
  useDebouncedSubmitConfigForNonRTE,
  useDebouncedSubmitConfigForRTE,
} from '../hooks/useDebouncedSubmitConfig';
import { useGetIntents } from '../hooks/useGetIntents';
import { TextFieldWithMessage } from './TextFieldWithMessage';
import set from 'lodash/fp/set';
import {
  selectWidgetConfiguration,
  setQuickFeedbackConfigByKey,
} from 'src/slices/solve-config/solveConfigSlice';
import {
  QuickFeedbackConfig,
  WidgetConfiguration,
} from 'src/slices/solve-config/types';
import { useAppDispatch } from 'src/store/hooks';
import { isIntentDuplicable } from 'src/utils/solve/intentUtils';

const QuickFeedbackTabContent = ({ isPlaybook }: { isPlaybook?: boolean }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const widgetConfiguration = useSelector(selectWidgetConfiguration);
  const debouncedSubmitConfigForRTE = useDebouncedSubmitConfigForRTE();
  const debouncedSubmitConfigForNonRTE = useDebouncedSubmitConfigForNonRTE();

  const intents = useGetIntents({ isActiveUserCreatedWorkflows: true });
  const selectableIntents = intents.filter(intent =>
    isIntentDuplicable(intent.intent_definition_id),
  );

  const configKey = isPlaybook
    ? 'quick_feedback_config_playbook'
    : 'quick_feedback_config';

  const {
    feedback_prompt: feedbackPrompt,
    hover_label_bad: hoverLabelBad,
    hover_label_good: hoverLabelGood,
    is_enabled: isEnabled,
    negative_routing_intent_workflow_id: negativeRoutingIntentWorkflowId,
    question_for_high_ratings: questionForHighRatings,
    question_for_low_ratings: questionForLowRatings,
    should_request_feedback_for_high_ratings:
      shouldRequestFeedbackForHighRatings,
    should_request_feedback_for_low_ratings: shouldRequestFeedbackForLowRatings,
    style,
  } = (widgetConfiguration[configKey] as QuickFeedbackConfig) || {};

  const workflowOptions = [
    {
      label: 'None (no rerouting)',
      value: '',
    },
    ...selectableIntents.map(intent => ({
      label: intent.intent_name,
      value: intent.intent_workflow_id || '',
    })),
  ];

  const chosenStyleOptions = quickFeedbackOneToTwoOptions.find(
    option => option.value === style,
  );

  const handleUpdateField = ({
    isGenericField,
    key,
    val,
  }: {
    isGenericField?: boolean;
    key: keyof QuickFeedbackConfig;
    val: string | boolean | null;
  }) => {
    const updatedConfig: WidgetConfiguration = set(
      [configKey, key],
      val,
      widgetConfiguration,
    );

    dispatch(
      setQuickFeedbackConfigByKey({
        isGenericField,
        isPlaybook,
        key: key,
        value: val,
      }),
    );

    if (isGenericField !== undefined) {
      debouncedSubmitConfigForNonRTE(
        updatedConfig,
        `quick feedback config - ${key}`,
        (val || '').toString(),
      );
    } else {
      debouncedSubmitConfigForRTE(
        updatedConfig,
        `quick feedback config - ${key}`,
        (val || '').toString(),
      );
    }
  };

  return (
    <Box>
      <Toggle
        checked={isEnabled}
        label={`Quick Feedback is ${isEnabled ? 'on' : 'off'} for all articles`}
        onChange={e => {
          handleUpdateField({
            isGenericField: true,
            key: 'is_enabled',
            val: e.target.checked,
          });
        }}
      />
      {isEnabled ? (
        <Box>
          <Line />
          <Box display='flex' flexDirection='column' gap='8px'>
            <Typography variant='font16Bold'>Style</Typography>
            <Box display='flex' gap='16px' justifyContent='space-between'>
              {quickFeedbackOneToTwoOptions.map(styleOption => {
                const selected = styleOption.value === style;
                return (
                  <StyleCard
                    key={styleOption.value}
                    onClick={() => {
                      handleUpdateField({
                        isGenericField: true,
                        key: 'style',
                        val: styleOption.value,
                      });
                    }}
                    selected={selected}
                  >
                    <Box alignItems='center' display='flex' gap={1}>
                      <StyleCardImg
                        src={
                          selected
                            ? styleOption?.positiveIcons?.active
                            : styleOption?.positiveIcons?.default
                        }
                      />
                      <StyleCardImgDivider />
                      <StyleCardImg
                        src={
                          selected
                            ? styleOption?.negativeIcons?.active
                            : styleOption?.negativeIcons?.default
                        }
                      />
                    </Box>
                    <Typography
                      color={
                        selected
                          ? theme.palette.colors.black
                          : theme.palette.colors.grey[400]
                      }
                      variant='font11'
                    >
                      {styleOption.label}
                    </Typography>
                  </StyleCard>
                );
              })}
            </Box>
            <Box display='flex' flexDirection='column' gap='8px' mt='16px'>
              <Typography variant='font14'>On hover labels</Typography>
              <Box alignItems='center' display='flex' gap='8px'>
                <Box display='flex'>
                  <SVGIcon
                    src={chosenStyleOptions?.positiveIcons?.default || ''}
                  />
                </Box>
                <TextField
                  aria-label='Hover label for good'
                  onChange={e => {
                    handleUpdateField({
                      isGenericField: true,
                      key: 'hover_label_good',
                      val: e.target.value,
                    });
                  }}
                  value={hoverLabelGood}
                />
              </Box>
              <Box alignItems='center' display='flex' gap='8px'>
                <Box display='flex'>
                  <SVGIcon
                    src={chosenStyleOptions?.negativeIcons?.default || ''}
                  />
                </Box>
                <TextField
                  aria-label='Hover label for bad'
                  onChange={e => {
                    handleUpdateField({
                      key: 'hover_label_bad',
                      val: e.target.value,
                    });
                  }}
                  value={hoverLabelBad}
                />
              </Box>
            </Box>
          </Box>
          <Line />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              margin: '24px 0',
            }}
          >
            <Typography variant='font16Bold'>Article quick feedback</Typography>
            <Typography variant='font14'>
              Question for articles
              <Typography
                color={theme.palette.colors.grey[700]}
                marginLeft='12px'
                variant='font14'
              >
                Optional
              </Typography>
            </Typography>
            <TextFieldWithMessage
              ariaLabel='questions for articles'
              footer='Keep the question under 60 characters'
              onChange={e => {
                handleUpdateField({
                  key: 'feedback_prompt',
                  val: e.target.value,
                });
              }}
              placeholder='Was this helpful?'
              value={feedbackPrompt}
            />
            <Box alignItems='center' display='flex' gap={1} mb={1}>
              <Typography variant='font14'>Reroute to Workflow for</Typography>
              <Badge label='Negative' variant='error' />
            </Box>
            <SelectDropdown
              id='workflow-options'
              onChange={e => {
                handleUpdateField({
                  isGenericField: true,
                  key: 'negative_routing_intent_workflow_id',
                  val: e.target.value === '' ? null : String(e.target.value),
                });
              }}
              options={workflowOptions}
              placeholder='Select an option'
              value={
                negativeRoutingIntentWorkflowId === null
                  ? ''
                  : negativeRoutingIntentWorkflowId
              }
            />
          </Box>
          <Line />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              margin: '24px 0',
            }}
          >
            <Typography variant='font16Bold'>
              Additional feedback in free-form text
            </Typography>
            <Box>
              <Checkbox
                checked={shouldRequestFeedbackForLowRatings}
                label='Request feedback for negative'
                onChange={e => {
                  handleUpdateField({
                    isGenericField: true,
                    key: 'should_request_feedback_for_low_ratings',
                    val: e.target.checked,
                  });
                }}
              />
              <Checkbox
                checked={shouldRequestFeedbackForHighRatings}
                label='Request feedback for positive'
                onChange={e => {
                  handleUpdateField({
                    isGenericField: true,
                    key: 'should_request_feedback_for_high_ratings',
                    val: e.target.checked,
                  });
                }}
              />
            </Box>
            {shouldRequestFeedbackForLowRatings && (
              <Box>
                <Box alignItems='center' display='flex' gap={1} mb={1}>
                  <Typography variant='font14'>
                    Open-ended question for
                  </Typography>
                  <Badge label='Negative' variant='error' />
                </Box>
                <TextFieldWithMessage
                  ariaLabel='open ended question for negative'
                  footer='Keep the question under 60 characters'
                  onChange={e => {
                    handleUpdateField({
                      key: 'question_for_low_ratings',
                      val: e.target.value,
                    });
                  }}
                  placeholder=''
                  value={questionForLowRatings}
                />
              </Box>
            )}
            {shouldRequestFeedbackForHighRatings && (
              <Box>
                <Box alignItems='center' display='flex' gap={1} mb={1}>
                  <Typography variant='font14'>
                    Open-ended question for
                  </Typography>
                  <Badge label='Positive' variant='success' />
                </Box>
                <TextFieldWithMessage
                  ariaLabel='open ended question for positive'
                  footer='Keep the question under 60 characters'
                  onChange={e => {
                    handleUpdateField({
                      key: 'question_for_high_ratings',
                      val: e.target.value,
                    });
                  }}
                  placeholder=''
                  value={questionForHighRatings}
                />
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box mt={3}>
          <Typography variant='font14'>
            Turn on and enable Quick Feedback for customization.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const Line = styled('hr')`
  border: 1px solid ${theme.palette.colors.slate[100]};
  margin: 24px 0;
  width: 100%;
`;

const StyleCard = styled('div', {
  shouldForwardProp: propName => propName !== 'selected',
})<{ selected: boolean }>`
  cursor: pointer;
  padding: 5px 16px;
  flex-direction: column;
  align-items: center;
  gap: 1px;
  border-radius: 6px;
  border: 1px solid
    ${props =>
      props.selected
        ? props.theme.palette.colors.purple[500]
        : props.theme.palette.colors.grey[100]};
  background: ${props => props.theme.palette.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyleCardImg = styled('img')`
  height: 40px;
  width: 40px;
`;

const SVGIcon = styled(ReactSVG)`
  div {
    display: flex;
    align-items: center;

    svg {
      height: 24px;
      width: 24px;

      path {
        stroke: ${props => props.theme.palette.colors.black};
        stroke-width: 2px;
        stroke-linejoin: round;
      }
    }
  }
`;

const StyleCardImgDivider = styled('div')`
  height: 24px;
  border: 1px solid ${props => props.theme.palette.colors.grey[100]};
`;

export default QuickFeedbackTabContent;
