import { useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';

import { useGetKnowledgeGapRecommendations } from '../hooks/useGetKnowledgeGapRecommendations';
import { AutomationTabComponentProps } from '../types';
import KnowledgeGapTable from './KnowledgeGapTable';
import debounce from 'lodash/fp/debounce';
import { DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT } from 'src/constants/discover';
import { useEmitTrackingEventCallback } from 'src/hooks/hooks';
import { setGlobalDiscoverArticleOptions } from 'src/slices/ui/uiSlice';
import { useAppDispatch } from 'src/store/hooks';

const KnowledgeGapTab = ({
  hasBrands,
  setHasScrolled,
}: AutomationTabComponentProps) => {
  const dispatch = useAppDispatch();
  const { data, error, loading } = useGetKnowledgeGapRecommendations();
  const emitTrackingEventCallback = useEmitTrackingEventCallback();
  const isBrandsEnabled = useMemo(() => {
    if (hasBrands && !data) {
      return true;
    }
    return Boolean(
      hasBrands && data?.articles.some(item => item.api_data_filter),
    );
  }, [hasBrands, data]);

  useEffect(() => {
    setHasScrolled(false);
  }, [setHasScrolled]);

  const onMouseEnter = useMemo(() => {
    return debounce(
      DEBOUNCED_AUTOMATION_TRACKING_EVENT_TIMEOUT,
      (topicName: string) =>
        emitTrackingEventCallback(
          'discover-knowledge-gap-recommendation-hovered',
          {
            page: 'Automation',
            'page-area': 'Knowledge gap',
            'topic-name': topicName,
          },
        ),
    );
  }, [emitTrackingEventCallback]);

  const handleClick = (selectedArticleId: string) => {
    dispatch(
      setGlobalDiscoverArticleOptions({
        isBrandsEnabled,
        selectedArticleId,
      }),
    );
  };

  const handleScroll = (scroll: number) => {
    setHasScrolled(Boolean(scroll));
  };

  return (
    <Box height='71vh'>
      <KnowledgeGapTable
        data={data?.articles}
        isBrandsEnabled={isBrandsEnabled}
        isError={Boolean(error)}
        loading={loading}
        onMouseEnter={onMouseEnter}
        onScroll={handleScroll}
        onSelect={row => handleClick(row.original.article_id)}
      />
    </Box>
  );
};

export default KnowledgeGapTab;
