import { PropsWithChildren } from 'react';
import { Box, styled } from '@mui/material';

import { Typography } from '@forethought-technologies/forethought-elements';
import banner from 'src/assets/images/solve_lite_knowledge_banner.png';

const KnowledgeWidgetContent = ({
  children,
  headerBanner,
}: PropsWithChildren<{
  headerBanner?: React.ReactNode;
}>) => {
  return (
    <Box>
      <Box pb='20px'>{headerBanner || <Banner alt='' src={banner} />}</Box>
      <InstructionalTextWrapper className='plus-jakarta-sans'>
        <Box
          alignSelf='center'
          display='flex'
          flexDirection='column'
          padding='30px 90px 0px 90px'
          sx={{
            '.MuiTypography-root': {
              background:
                'linear-gradient(270deg, #BD00FF 5.08%, #3866F5 94.92%)',
              fontStyle: 'italic',
              textAlign: 'center',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            },
          }}
        >
          <Typography variant='font16Medium'>
            Please provide the public URL of your organization&apos;s knowledge
            base or an integrated help center such as Zendesk or Salesforce.
          </Typography>
          <Box pb='20px' />
          <Typography variant='font16Medium'>
            Our AI models can connect with your help center and use the
            information to train your bot, giving you a more personalized
            experience.
          </Typography>
        </Box>
        {children}
      </InstructionalTextWrapper>
    </Box>
  );
};

const Banner = styled('img')`
  max-width: 100%;
`;

const InstructionalTextWrapper = styled('div')`
  text-align: center;
`;

export default KnowledgeWidgetContent;
