import {
  CreateTicketFieldMappingRequest,
  EmailConfigurationListItem,
  StepMap,
} from '../types';
import {
  freshdeskEdgeInfo,
  intercomEdgeInfo,
  jiraEdgeInfo,
  kustomerEdgeInfo,
  salesforceEdgeInfo,
  zendeskEdgeInfo,
} from './intent-email-journey-map/constants';
import { Helpdesk } from 'src/components/app/types';
import { SettingsInterface } from 'src/types/actionBuilderApiTypes';
import { getContextVariablevNameById } from 'src/utils/getContextVariablevNameById';

export const getTextLengthErrorMessage = (charLimit: number) =>
  `The text is too long, reduce it to ${charLimit} characters`;

export const getEntryNodeInformation = (
  helpdesk: Helpdesk,
  contextVariables: SettingsInterface['context_variables'],
  ticketFieldMappings: Array<CreateTicketFieldMappingRequest>,
  defaultContextVars: Array<string>,
) => {
  const defaultValues = [...defaultContextVars];

  ticketFieldMappings.forEach(mapping => {
    const cvName = getContextVariablevNameById(
      mapping.context_variable_id,
      contextVariables,
    );
    if (!defaultValues.includes(cvName) && cvName) {
      defaultValues.push(cvName);
    }
  });

  const capitalizedHelpdesk =
    helpdesk.charAt(0).toUpperCase() + helpdesk.slice(1);

  return `${capitalizedHelpdesk} Information:
  ${defaultValues.map(cv => `$${cv}`).join(', ')}`;
};

/**
 * Given a step map and a list of email configurations, returns the list of responses that are in use in the step map
 */
export function getResponsesInStepMap(
  stepMap: StepMap,
  emailConfigurations: EmailConfigurationListItem[],
) {
  const usedResponses: EmailConfigurationListItem[] = [];

  Object.values(stepMap).forEach(step => {
    if (step.step_type !== 'response') {
      return;
    }

    const response = emailConfigurations.find(
      configuration =>
        configuration.configuration_id ===
        step.step_fields.intent_email_configuration_id,
    );

    if (response) {
      usedResponses.push(response);
    }
  });

  return usedResponses;
}

export const getEntryNodeEdgeInfo = (helpdesk: Helpdesk) => {
  switch (helpdesk) {
    case 'zendesk':
      return zendeskEdgeInfo;
    case 'salesforce':
      return salesforceEdgeInfo;
    case 'kustomer':
      return kustomerEdgeInfo;
    case 'freshdesk':
      return freshdeskEdgeInfo;
    case 'intercom':
      return intercomEdgeInfo;
    case 'jira':
      return jiraEdgeInfo;
    default:
      throw new Error(`Unknown helpdesk: ${helpdesk}`);
  }
};
