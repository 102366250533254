/* eslint-disable simple-import-sort/imports  */
import './styles/app.scss';
import './styles/app-loading.scss';
import './styles/pagination.scss';

import React, { useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { appSelectedMappings } from './appRouteMappings';
import AppRoutes from './appRoutes';
import { isRouteValid, loadAppCues } from './helpers';
import { rolePermissions } from './rolePermissions';
import { setUserRole } from './setUserRole';
import { Roles } from './types';
import { getPermissionInfo } from 'src/actions/auth';
import { sendTrackingEvent } from 'src/actions/search';
import {
  appsAvailable,
  saveRoleData,
  saveUserData,
  setCurrentApplication,
} from 'src/actions/user';
import AgentLandingPage from 'src/components/dashboard-pages/agent-landing-page';
import { getPermission } from 'src/services/api';
import {
  Apps,
  ModuleName,
  redirectMap,
  Routes,
  getSelectedOrganizationForSuperAdmin,
  Tabs,
  getUserRole,
  UserRoles,
  Permission,
} from 'src/utils/enums';
import { setSentryTraceIdTag } from 'src/utils/sentryTraceId';
import { AppDispatch } from 'src/store/hooks';
import { getAppCuesStateAPI } from 'src/services/apiV1';

import { setSentryOrgIdTag } from 'src/utils/setSentryOrgIdTag';
import {
  getIsValidRoute,
  getPermissionKeys,
  getTabsFromPermissions,
  goToHomePage,
  forethoughtSupportWidget,
  rmForethoughtSupportWidget,
  processAuthenticatedUser,
} from './utils';
import { Permissions } from './types';
import { useSetupHeaders } from 'src/hooks/useSetupHeaders';
import { withRouter, WithRouterProps } from 'src/utils/withRouter';
import GlobalWorkflowBuilder from '../global-workflow-builder/GlobalWorkflowBuilder';

interface UserType {
  applications: Array<string>;
  currentApplication: string;
  currentTab: string;
  insightsTabs: Array<string>;
  role: string;
  user: {
    aud: string;
    auth_time: number;
    'cognito:groups': Array<string>;
    'cognito:username': string;
    email: string;
    email_verified: boolean;
    exp: number;
    iat: number;
    iss: string;
    sub: string;
    token_use: string;
  };
}

type MyState = {
  isAppCuesEnabled: boolean;
  permissions: Permissions;
  permissionsLoaded: boolean;
};

type MyProps = {
  appsAvailable: Function;
  getPermissionInfo: Function;
  role: UserRoles;
  saveRoleData: Function;
  saveUserData: Function;
  sendTrackingEvent: Function;
  setCurrentApplication: Function;
  user: UserType;
};

class App extends React.Component<WithRouterProps & MyProps, MyState> {
  constructor(props: WithRouterProps & MyProps) {
    super(props);
    this.state = {
      isAppCuesEnabled: false,
      permissions: {
        answers_analytics: 0,
        flamethrower: 0,
        // TODO: get rid of the fake `flamethrower_action_builder_edit`
        // permission needed for implementing the `/action-builder-edit` route
        flamethrower_action_builder_edit: 0,
        flamethrower_workflow_builder_edit: 0,
        macro_controls_admin: 0,
        predictions_analytics: 0,
        search_analytics: 0,
        [Permission.SOLVE_LITE]: 0,
        solve_workflows: 0,
        workflow_builder: 0,
        workflow_builder_config: 0,
        workflow_builder_conversations: 0,
        [Permission.PREDICT_INTENTS]: 0,
        [Permission.SOLVE_INSIGHTS]: 0,
        [Permission.SOLVE_INSIGHTS_WIP]: 0,
        [Permission.LOG]: 0,
      },
      permissionsLoaded: false,
    };

    const { appsAvailable, saveRoleData, saveUserData } = this.props;

    appsAvailable([], []);
    Hub.listen('auth', async data => {
      switch (data.payload.event) {
        case 'signIn':
          Auth.currentAuthenticatedUser().then(user => {
            const userRole = user
              .getSignInUserSession()
              .getIdToken()
              .decodePayload()
              ['cognito:groups'].filter((role: string) => role);
            saveRoleData(setUserRole(userRole) as string);
            saveUserData({
              user: user.getSignInUserSession().getIdToken().decodePayload(),
            });
          });

          if (
            getUserRole() !== UserRoles.ROLE_SUPER_ADMIN ||
            (getUserRole() === UserRoles.ROLE_SUPER_ADMIN &&
              getSelectedOrganizationForSuperAdmin())
          ) {
            const permissions = await getPermission();
            this.setState(state => ({
              ...state,
              permissions,
              permissionsLoaded: true,
            }));
          }
          break;
        case 'signIn_failure':
          break;
        default:
          break;
      }
    });

    Auth.currentAuthenticatedUser().then(async user => {
      const userEmail = user?.attributes?.email;
      const userInfoArr = user
        .getSignInUserSession()
        .getIdToken()
        .decodePayload()
        ['cognito:groups']?.filter((role: string) => role);
      if (userInfoArr) {
        const userRole = setUserRole(userInfoArr);
        if (userRole) {
          saveRoleData(userRole);
        }
      }
      saveUserData({
        user: user.getSignInUserSession().getIdToken().decodePayload(),
      });

      Sentry.setUser({
        email: userEmail,
        role: user?.pool?.storage?.userRole,
      });
      setSentryOrgIdTag();
      // Initialize to default value in case of error before network call
      setSentryTraceIdTag();
      // Check or Create Salesforce Contact for current User
      processAuthenticatedUser(user, userEmail);
    });
  }

  componentDidMount = async () => {
    const { getPermissionInfo, sendTrackingEvent, setCurrentApplication } =
      this.props;
    const selectedOrg = getSelectedOrganizationForSuperAdmin();

    let { permissions } = this.state;

    if (
      getUserRole() &&
      (getUserRole() !== UserRoles.ROLE_SUPER_ADMIN ||
        (getUserRole() === UserRoles.ROLE_SUPER_ADMIN && selectedOrg))
    ) {
      permissions = await getPermissionInfo();
      this.setState(state => ({
        ...state,
        permissions,
        permissionsLoaded: true,
      }));
    }

    //if user enters matching path instead of using tab buttons it will store it and mark the tab as selected
    const userLocation = location.pathname;
    if (getIsValidRoute()) {
      if (permissions[appSelectedMappings[userLocation].permission]) {
        const application = appSelectedMappings[userLocation].tab;
        setCurrentApplication(Apps.INSIGHTS, application);
        const applicationSelected =
          appSelectedMappings[userLocation].permission;
        sendTrackingEvent(ModuleName.DASHBOARD, {
          application: applicationSelected,
          event_type: 'application-selected',
        });
      } else {
        window.location.href = '/';
      }
    }

    getAppCuesStateAPI((res: { enabled: boolean; org_id: number }) => {
      this.setState(state => ({ ...state, isAppCuesEnabled: res.enabled }));
    });
  };

  componentDidUpdate = async (
    prevProps: Readonly<WithRouterProps & MyProps>,
    prevState: Readonly<MyState>,
  ) => {
    window.addEventListener('storage', goToHomePage);

    if (
      this.props.role &&
      this.props.role !== UserRoles.ROLE_SUPER_ADMIN &&
      this.props.role !== prevProps.role
    ) {
      const permissions = await this.props.getPermissionInfo(this.props.role);
      this.setState(state => ({
        ...state,
        permissions,
        permissionsLoaded: true,
      }));
    }

    if (!prevState.isAppCuesEnabled && this.state.isAppCuesEnabled) {
      loadAppCues();
    }
  };

  componentWillUnmount() {
    window.removeEventListener('storage', goToHomePage);
  }

  render() {
    const path = window.location.pathname;
    const { permissions } = this.state;

    const permissionKeys = getPermissionKeys(permissions);

    const availableTabs = getTabsFromPermissions(permissionKeys);

    const { appsAvailable, role, setCurrentApplication } = this.props;

    let shouldRedirectToActionBuilderHomePage = false;

    if (role) {
      const userCanEditActions =
        rolePermissions[role as Roles].includes('edit_action');

      shouldRedirectToActionBuilderHomePage =
        path === Routes.ACTION_BUILDER_EDIT && !userCanEditActions;
    }

    if (role === UserRoles.ROLE_AGENT) {
      return <AgentLandingPage />;
    }

    // SET USER LOCATION
    if (path && path === Routes.HOMEPAGE) {
      const availableTabsArr = availableTabs.filter(
        (tab: string) =>
          tab !== Tabs.MACRO_CONTROLS && tab !== Tabs.WORKFLOW_BUILDER,
      );

      setCurrentApplication(Apps.INSIGHTS, availableTabsArr[0]);
    }

    appsAvailable([Apps.INSIGHTS], availableTabs);

    const validPaths = Object.values(Routes);
    const urlEnd = window.location.search + window.location.hash;

    const redirect = redirectMap[path];

    return (
      <>
        <GlobalWorkflowBuilder />
        <Hooks />

        {!isRouteValid(path, validPaths) && (
          <Navigate to={Routes.ACCOUNT_SETTINGS} />
        )}
        {redirect && <Navigate to={`${redirect}${urlEnd}`} />}
        {shouldRedirectToActionBuilderHomePage && (
          <Navigate to={Routes.ACTION_BUILDER} />
        )}

        {path === Routes.LOGOUT && <Navigate to={Routes.HOMEPAGE} />}
        <AppRoutes
          permissionKeys={permissionKeys}
          permissionsLoaded={this.state.permissionsLoaded}
        />
      </>
    );
  }
}
const mapStateToProps = (state: any) => ({
  role: state.user.role,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  appsAvailable: (applications: [], insightsTabs?: []) =>
    dispatch(appsAvailable(applications, insightsTabs)),
  getPermissionInfo: () => dispatch(getPermissionInfo()),
  saveRoleData: (role: any) => dispatch(saveRoleData(role)),
  saveUserData: (user: any) => dispatch(saveUserData(user)),
  sendTrackingEvent: (moduleName: string, event: any) =>
    dispatch(sendTrackingEvent(moduleName, event)),
  setCurrentApplication: (currentApplication: string, currentTab: string) =>
    dispatch(setCurrentApplication(currentApplication, currentTab)),
});

const ConnectedApp = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(App));

export default ConnectedApp;

const Hooks = () => {
  useSetupHeaders();

  const location = useLocation();
  useEffect(() => {
    // Do not inject widget when developing locally
    // and when running e2e tests in CI:
    if (ENVIRONMENT === 'local') {
      return;
    }

    forethoughtSupportWidget(getUserRole(), location.pathname);
    rmForethoughtSupportWidget();
  }, [location.pathname]);

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      if (event.data.event === 'forethoughtWidgetClosed') {
        window.Forethought('widget', 'hide');
      }
    };

    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, []);

  return null;
};
