import { useMemo } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import UndefinedContextVariablesTooltip from '../../UndefinedContextVariablesTooltip';
import { getStepTypeFromActionType, linkifyActionName } from './helpers';
import StepContainer from './StepContainer';
import StepHeader from './StepHeader';
import TextBox from './TextBox';
import VariableBox from './VariableBox';
import mountainsSrc from 'src/assets/images/mountains.svg';
import { formatContextVariablesForInput } from 'src/components/context-variable-autocomplete/formatContextVariablesForInput';
import { selectCustomizableActionId } from 'src/reducers/actionBuilderReducer/actionBuilderReducer';
import {
  selectCanvasActionIdToActionComponent,
  selectUndefinedContextVariablesInStep,
} from 'src/reducers/workflowBuilderReducer/workflowBuilderReducer';
import { useGetContextVariablesQuery } from 'src/services/dashboard-api';
import { selectActionCustomization } from 'src/slices/canvas-workflow-builder/workflowBuilderSlice';
import { Action, ContextVariable } from 'src/types/actionBuilderApiTypes';
import {
  ContextVariableValue as ContextVariableValueType,
  DynamicCardCustomizationSettings,
  SetContextVariableCustomizationSettings,
} from 'src/types/workflowBuilderAPITypes';
import { getVariablesFromAction } from 'src/utils/actionBuilder/helpers';
import { ActionBuilderActionTypes } from 'src/utils/enums';
import {
  dynamicCvValueMessageBuilder,
  isArticleSuggestion,
  isDynamicCardAction,
  isSetContextVariableAction,
} from 'src/utils/solve/stepUtils';

const MAX_IMAGE_COMPONENT_IMAGES = 3;

interface ActionStepProps {
  action: Action;
  contextVariables?: ContextVariable[];
  isBeingEdited?: boolean;
  stepId?: string;
  steps?: string[];
}

const ActionStep = ({
  action,
  contextVariables = [],
  isBeingEdited = false,
  stepId = '',
  steps,
}: ActionStepProps) => {
  const { palette } = useTheme();

  const undefinedContextVariablesInStep = useSelector(
    selectUndefinedContextVariablesInStep,
  );

  const customizableActionId = useSelector(selectCustomizableActionId);
  const {
    dynamicListOutputVariables = [],
    inputVariables,
    outputVariables,
  } = useMemo(() => {
    return getVariablesFromAction(action, contextVariables);
  }, [action, contextVariables]);

  const undefinedContextVariableIds = undefinedContextVariablesInStep[stepId];

  const actionCustomization = useSelector(selectActionCustomization);

  const canvasActionIdToActionComponent = useSelector(
    selectCanvasActionIdToActionComponent,
  );

  const actionComponent = Object.values(canvasActionIdToActionComponent).find(
    value => value.steps.includes(stepId),
  );

  const actionKey =
    Object.keys(canvasActionIdToActionComponent).find(
      key =>
        canvasActionIdToActionComponent[key]['action_id'] ===
          action.action_id &&
        canvasActionIdToActionComponent[key]['entry_step_id'] === stepId,
    ) ?? '';

  return (
    <Box>
      <StepContainer
        isBeingEdited={isBeingEdited || actionKey === customizableActionId}
      >
        <StepHeader
          iconUrl={
            // TODO: Replace this with action.icon_url, Will need more BE work to make icon url correct for all actions
            action.action_type === ActionBuilderActionTypes.SUNCO_LIVE_CHAT
              ? action.icon_url
              : undefined
          }
          stepType={getStepTypeFromActionType(action.action_type)}
        >
          <Box display='flex' flex={1} justifyContent='flex-end'>
            <UndefinedContextVariablesTooltip
              buildMessage={
                action.action_type === 'dynamic_card'
                  ? dynamicCvValueMessageBuilder
                  : undefined
              }
              contextVariables={contextVariables}
              stepId={stepId}
              steps={steps}
            />
          </Box>
        </StepHeader>
        {action.action_type !== 'dynamic_card' && (
          <Box
            mb={1}
            sx={{
              '& a': {
                color: 'inherit',
              },
            }}
          >
            <Typography variant='font16Bold'>
              {linkifyActionName(<>{action.action_name}</>, action)}
            </Typography>
          </Box>
        )}

        {!isArticleSuggestion(action.action_type) && (
          <>
            <VariableBox
              color={palette.colors.purple[500]}
              contextVariables={inputVariables}
              label='Input:'
              undefinedContextVariableIds={undefinedContextVariableIds}
            />
            <VariableBox
              color={palette.colors.purple[500]}
              contextVariables={outputVariables}
              label='Output:'
              undefinedContextVariableIds={undefinedContextVariableIds}
            />
            {dynamicListOutputVariables.length > 0 && (
              <VariableBox
                color={palette.colors.purple[500]}
                contextVariables={dynamicListOutputVariables}
                label='Dynamic list output:'
                undefinedContextVariableIds={undefinedContextVariableIds}
              />
            )}
          </>
        )}
        {isSetContextVariableAction(action.action_type) && (
          <>
            <Typography color={palette.text.secondary} variant='font12'>
              Values
            </Typography>
            {((actionKey === customizableActionId || stepId === '') &&
            actionCustomization
              ? (actionCustomization as SetContextVariableCustomizationSettings)
              : (actionComponent?.config_fields as unknown as SetContextVariableCustomizationSettings)
            )?.mappings.map((mapping, index) => {
              const contextVariable = contextVariables.find(
                cv => cv.context_variable_id === mapping.context_variable_id,
              );

              return (
                <Box
                  fontFamily='Fira Code !important'
                  fontSize='12px'
                  fontWeight='450'
                  key={index}
                  lineHeight='18px'
                  style={{ wordWrap: 'break-word' }}
                >
                  <Typography variant='font12'>Set</Typography>{' '}
                  <Typography color={palette.primary.main} variant='font12'>
                    {contextVariable?.context_variable_name}
                    {' = '}
                    <ContextVariableValue
                      contextVariable={contextVariable}
                      contextVariables={contextVariables}
                      value={mapping.value}
                    />
                  </Typography>
                </Box>
              );
            })}
          </>
        )}
        {isDynamicCardAction(action.action_type) && (
          <DynamicCardActionCard
            fields={
              (actionKey === customizableActionId || stepId === '') &&
              actionCustomization
                ? (actionCustomization as DynamicCardCustomizationSettings)
                : (actionComponent?.config_fields as unknown as DynamicCardCustomizationSettings)
            }
          />
        )}
      </StepContainer>
    </Box>
  );
};

export default ActionStep;

function ContextVariableValue({
  contextVariable,
  contextVariables,
  value,
}: {
  contextVariable: ContextVariable | undefined;
  contextVariables: ContextVariable[];
  value: ContextVariableValueType;
}) {
  const type = contextVariable?.context_variable_type || 'SHORT_TEXT';

  function getLabel(listValue: string) {
    return contextVariable?.list_type_options?.find(
      option => option.value === listValue,
    )?.label;
  }

  if (Array.isArray(value)) {
    if (type === 'MULTI_SELECT_LIST') {
      return <>{value.map(getLabel).join(', ')}</>;
    }

    return <>{value.join(', ')}</>;
  }

  if (typeof value === 'string') {
    if (type === 'DATE') {
      return <>{value && moment(value).format('MM-DD-YYYY')}</>;
    }

    if (type === 'LONG_TEXT') {
      return (
        <Box component='pre' margin={0}>
          {formatContextVariablesForInput(contextVariables, value)}
        </Box>
      );
    }

    if (type === 'SHORT_TEXT') {
      return <>{formatContextVariablesForInput(contextVariables, value)}</>;
    }

    if (type === 'LIST') {
      return <>{getLabel(value)}</>;
    }
  }

  return <>{String(value)}</>;
}

const DynamicCardActionCard = ({
  fields,
}: {
  fields?: DynamicCardCustomizationSettings;
}) => {
  const { palette } = useTheme();

  const { data: cvData } = useGetContextVariablesQuery();
  const parentCVName: string | null = useMemo(() => {
    if (!fields?.dynamic_list_context_variable || !cvData) {
      return null;
    }
    const cv = cvData.context_variables.find(
      cv => cv.context_variable_id === fields.dynamic_list_context_variable,
    );
    if (!cv) {
      return null;
    }
    return cv.context_variable_name;
  }, [cvData, fields?.dynamic_list_context_variable]);

  return (
    <>
      <TextBox
        isRichText
        placeholder='Empty message'
        value={fields?.message ?? undefined}
        variant='dark'
      />
      {fields && (
        <Box
          border={'1px solid ' + palette.colors.grey[100]}
          borderRadius='8px'
          display='flex'
          flexDirection='column'
          gap={1}
          mt={1}
          p={2}
          sx={{ boxShadow: '1px 0px 8px 0px rgba(0, 0, 0, 0.08);' }}
        >
          <Typography variant='font14'>
            1 of{' '}
            {fields.display_option.type === 'capped'
              ? fields.display_option.limit
              : '#'}
          </Typography>
          {fields.sections.map((section, index) => (
            <Section
              contentConfig={fields.content_config}
              key={index}
              parentCVName={parentCVName}
              section={section}
            />
          ))}
        </Box>
      )}
    </>
  );
};

const Section = ({
  contentConfig,
  parentCVName,
  section,
}: {
  contentConfig: DynamicCardCustomizationSettings['content_config'];
  parentCVName: string | null;
  section: DynamicCardCustomizationSettings['sections'][number];
}) => {
  const { palette } = useTheme();

  switch (section.type) {
    case 'content':
      return (
        <Box>
          {section.components.map((component, index) => (
            <Box
              key={index}
              overflow='hidden'
              textOverflow='ellipsis'
              whiteSpace='nowrap'
            >
              {contentConfig?.limit === index && (
                <Box
                  borderTop={'1px solid ' + palette.colors.grey[200]}
                  display='flex'
                  justifyContent='center'
                  my={1}
                  pt={0.5}
                >
                  <Typography color={palette.colors.grey[600]} variant='font12'>
                    Content below is initially collapsed in the card
                  </Typography>
                </Box>
              )}
              {parentCVName &&
              (component.key.trim() || component.label?.trim()) ? (
                <>
                  {component.label && (
                    <Typography
                      color={palette.colors.grey[600]}
                      noWrap
                      variant='font14'
                    >
                      {component.label}
                    </Typography>
                  )}
                  <Typography
                    color={palette.colors.purple[500]}
                    component='span'
                    fontFamily='Fira Code !important'
                    fontSize='12px'
                    fontWeight='450'
                    lineHeight='18px'
                    noWrap
                  >
                    {' '}
                    ${parentCVName}.{component.key}
                  </Typography>
                </>
              ) : (
                <Typography color={palette.colors.grey[600]} variant='font14'>
                  Empty content
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      );
    case 'image':
      return (
        <Box display='flex' gap={1} width='100%'>
          {section.components
            .slice(0, MAX_IMAGE_COMPONENT_IMAGES)
            .map((section, key) => (
              <DynamicImage isEmpty={!section.key.trim()} key={key} />
            ))}
        </Box>
      );
    case 'title':
      return (
        <Box>
          {section.components.map((component, index) => (
            <Box
              key={index}
              overflow='hidden'
              textOverflow='ellipsis'
              whiteSpace='nowrap'
            >
              {parentCVName &&
              (component.key.trim() || component.label?.trim()) ? (
                <>
                  {component.label && (
                    <Typography
                      color={palette.colors.black}
                      noWrap
                      variant='font16Bold'
                    >
                      {component.label}
                    </Typography>
                  )}
                  <Typography
                    color={palette.colors.purple[500]}
                    component='span'
                    fontFamily='Fira Code !important'
                    fontSize='16px'
                    fontWeight='450'
                    lineHeight='18px'
                    noWrap
                  >
                    {' '}
                    ${parentCVName}.{component.key}
                  </Typography>
                </>
              ) : (
                <Typography color={palette.colors.grey[600]} variant='font16'>
                  Empty title
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      );
    default:
      return null;
  }
};

const DynamicImage = ({ isEmpty }: { isEmpty: boolean }) => {
  const { palette } = useTheme();

  return (
    <Box
      alignItems='center'
      bgcolor={isEmpty ? palette.colors.slate[100] : palette.colors.purple[300]}
      borderRadius='8px'
      display='flex'
      height='80px'
      justifyContent='center'
      width='100%'
    >
      {isEmpty ? (
        <Typography color={palette.colors.grey[600]} variant='font14'>
          Empty image
        </Typography>
      ) : (
        <img src={mountainsSrc} />
      )}
    </Box>
  );
};
