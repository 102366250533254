import TabLayout from '../tab-layout';
import AssistWrapper from './assist-wrapper';
import { PLAYBOOK_TABS } from './constants';
import GeneratedReply from './generated-reply';
import { getTitle } from './helpers';
import { PlaybookTabKeys } from './types';
import WidgetContent from './WidgetContent';
import WidgetPreview from './WidgetPreview';
import { useStateParams } from 'src/hooks/hooks';

const PlaybookTab = () => {
  const [selectedTab, setSelectedTab] = useStateParams<PlaybookTabKeys>({
    deserialize: value => value as PlaybookTabKeys,
    initialState: 'knowledge',
    paramsName: 'playbook-config-tab',
    serialize: value => value,
  });

  const isToneTab = selectedTab === 'tone-of-voice';
  const isQuickFeedbackTab = selectedTab === 'quick-feedback';
  const isKnowledgeTab = selectedTab === 'knowledge';

  const title = getTitle(selectedTab);

  const preview = <WidgetPreview widgetType={selectedTab} />;

  return (
    <TabLayout<PlaybookTabKeys>
      configWidget={<WidgetContent widgetType={selectedTab} />}
      navigationItems={PLAYBOOK_TABS}
      onTabChange={setSelectedTab}
      selectedTab={selectedTab}
      title={title}
    >
      {isToneTab && <GeneratedReply preview={preview} />}
      {isQuickFeedbackTab && <AssistWrapper preview={preview} />}
      {isKnowledgeTab && preview}
    </TabLayout>
  );
};

export default PlaybookTab;
