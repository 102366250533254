import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Box, useTheme } from '@mui/material';
import { useRTVIClient } from '@pipecat-ai/client-react';

import {
  Button,
  Typography,
} from '@forethought-technologies/forethought-elements';
import { TEMPLATE_INTENT_WORKFLOW_ID_PREFIX } from '../workflow-builder-edit/constants';
import { useGetIsTemplateIntentWorkflow } from '../workflow-builder-edit/hooks';
import { useGetColorMode } from './hooks';
import {
  initializeApiPreview,
  selectApiPreviewState,
  selectEmailPreviewStage,
  setEmailPreviewStage,
} from 'src/slices/workflow-preview/workflowPreviewSlice';
import { useAppDispatch } from 'src/store/hooks';
import { Routes } from 'src/utils/enums';

export const WorkflowPreviewHeader = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const voiceClient = useRTVIClient();
  const colorMode = useGetColorMode();
  const { channel } = useParams<'channel'>();
  const isTemplate = useGetIsTemplateIntentWorkflow();
  const emailPreviewStage = useSelector(selectEmailPreviewStage);
  const apiPreviewState = useSelector(selectApiPreviewState);

  const onGoBack = async () => {
    const goBackToTemplate = () => {
      const params = new URLSearchParams(location.search);
      const intentId = params.get('intentId');
      const [, intentWorkflowId] =
        intentId?.split(TEMPLATE_INTENT_WORKFLOW_ID_PREFIX) ?? [];
      params.set('intentId', 'template');
      params.set('workflowId', intentWorkflowId);
      const templateDetailUrl = `${Routes.WORKFLOW_BUILDER_EDIT}?${params}`;
      navigate(templateDetailUrl);
    };

    if (isTemplate) {
      // template preview allows navigating to different template previews
      // we need to build the back url rather than go to previous
      goBackToTemplate();
      return;
    }

    // disconnect the call if in voice channel
    if (channel === 'voice' && voiceClient) {
      await voiceClient.disconnect();
    }

    navigate(-1);
  };

  const restartEmailPreview = () => {
    if (channel === 'email') {
      dispatch(setEmailPreviewStage('email-form'));
    }
  };

  const restartApiPreview = () => {
    dispatch(initializeApiPreview());
  };

  return (
    <Box
      bgcolor={
        colorMode === 'dark' ? 'transparent' : theme.palette.colors.white
      }
      borderBottom={`1px solid ${
        colorMode === 'dark'
          ? theme.palette.colors.grey[800]
          : theme.palette.colors.slate[200]
      }`}
      boxSizing='border-box'
      display='flex'
      height='65px'
      padding='16px 24px 16px 40px'
      sx={{
        background: channel === 'voice' ? 'rgba(0,0,0,0.7)' : null,
      }}
      width='100%'
    >
      <Typography
        color={
          colorMode === 'dark'
            ? theme.palette.colors.white
            : theme.palette.colors.black
        }
        variant='font24'
      >
        Preview {isTemplate && 'Template'}
      </Typography>
      <Box display='flex' gap='8px' marginLeft='auto'>
        {channel === 'email' && (
          <Button
            disabled={emailPreviewStage === 'email-form'}
            onClick={restartEmailPreview}
            variant='ghost'
          >
            Restart
          </Button>
        )}
        {channel === 'api' && (
          <Button
            disabled={
              apiPreviewState.history.length === 0 || apiPreviewState.isLoading
            }
            onClick={restartApiPreview}
            variant='ghost'
          >
            <Typography
              color={
                apiPreviewState.history.length === 0
                  ? theme.palette.colors.grey[500]
                  : theme.palette.colors.white
              }
              variant='font14Bold'
            >
              Start new chat
            </Typography>
          </Button>
        )}
        <Button onClick={onGoBack} variant='secondary'>
          Close
        </Button>
      </Box>
    </Box>
  );
};
