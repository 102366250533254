import { TextField } from '@forethought-technologies/forethought-elements';
import ContextVariableAutocomplete from '../context-variable-autocomplete';
import {
  KNOWLEDGE_CONFIGURATION_VALIDATION_MESSAGE,
  validateKnowledgeConfigurationPrompt,
} from './helpers';
import { useGetFeatureFlagsQuery } from 'src/services/dashboard-api';

interface KnowledgeConfigurationPromptTextFieldProps {
  error?: false | string;
  onChange: (value: string) => void;
  shouldAddContextVariables?: boolean;
  value: string;
}

export const KnowledgeConfigurationPromptTextField = ({
  error,
  onChange,
  shouldAddContextVariables = false,
  ...props
}: KnowledgeConfigurationPromptTextFieldProps) => {
  const { data } = useGetFeatureFlagsQuery();
  const extraProps = {
    description: KNOWLEDGE_CONFIGURATION_VALIDATION_MESSAGE({
      shouldUseLongerNoteLength: Boolean(
        data?.feature_flags.includes('allow_longer_instructional_note'),
      ),
    }),
    error:
      error ||
      validateKnowledgeConfigurationPrompt({
        prompt: props.value,
        shouldUseLongerNoteLength: Boolean(
          data?.feature_flags.includes('allow_longer_instructional_note'),
        ),
      }),
    label: 'Note the following in customer conversations',
    minRows: 4,
    multiline: true,
    placeholder:
      "Inform the bot that you may have various user personas (e.g. buyers, sellers) and prompt clarification before providing tailored information utilizing distinct personas or acronyms (e.g 'X' stands for spelled out name).",
    ...props,
  };

  return shouldAddContextVariables ? (
    <ContextVariableAutocomplete {...extraProps} onChange={onChange} />
  ) : (
    <TextField {...extraProps} onChange={e => onChange(e.target.value)} />
  );
};
