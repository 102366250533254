import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { createTheme, useTheme } from '@mui/material';

import { TEMPLATE_INTENT_WORKFLOW_ID_PREFIX } from '../workflow-builder-edit/constants';
import {
  darkModeBlue,
  darkModeGreen,
  darkModeGrey,
  darkModePurple,
} from './constants';
import { useGetColorMode } from './hooks';
import isEqual from 'lodash/fp/isEqual';
import partition from 'lodash/fp/partition';
import { useGetIntentsQueryWithProduct } from 'src/hooks/hooks';
import { useGetContextVariables } from 'src/hooks/useGetContextVariables';
import { useGetHandoffConfigurationsQuery } from 'src/services/workflow-builder-metrics';
import {
  IntentFilter,
  IntentFiltersForEmail,
  IntentFiltersForIFrame,
  IntentFiltersMode,
} from 'src/slices/workflow-preview/types';
import {
  addPreviewLogs,
  clearPreviewLogs,
  selectPreviewIntentFilters,
  selectPreviewLogs,
  setPreviewConversationId,
} from 'src/slices/workflow-preview/workflowPreviewSlice';
import { useAppDispatch } from 'src/store/hooks';
import {
  ForethoughtWidgetPreviewLogsEvent,
  ForethoughtWidgetPreviewRestartEvent,
  isForethoughtWidgetPreviewLogsEvent,
  isForethoughtWidgetPreviewRestartEvent,
} from 'src/types/solveWidgetEventTypes';
import { Language } from 'src/types/workflowBuilderAPITypes';
import { CommonIntentWorkflowType } from 'src/utils/enums';

export const englishOption = { label: 'English', value: 'en' };
export const allTagsOption = { label: 'All workflow tags', value: '' };

export const confidenceScoreToWord = (confidenceScore: number) => {
  if (confidenceScore > 0.8) {
    return 'High';
  } else if (confidenceScore > 0.5) {
    return 'Medium';
  } else {
    return 'Low';
  }
};

export const getIframeUrl = ({
  isTemplate,
  language,
  previewToken,
  product,
  tag,
  templateIntentId,
  workflowCvs,
}: {
  isTemplate: boolean;
  language: string;
  previewToken: string;
  product?: string | null;
  tag: string | null;
  templateIntentId?: string;
  workflowCvs: { name: string; value: string }[];
}) => {
  const url = SOLVE_URL.replace('embed.js', '');
  const attrPrefix = isTemplate ? 'data-template-ft-' : 'data-ft-';

  const iframeURL = new URL(
    `${url}?data-api-key=${previewToken}&full-screen=true&config-ft-disable-close=true`,
  );
  iframeURL.searchParams.append('preview', 'true');

  iframeURL.searchParams.append('data-ft-conversation-language', language);
  if (tag) {
    iframeURL.searchParams.append('data-ft-workflow-tag', tag);
  }

  if (templateIntentId) {
    iframeURL.searchParams.append('data-intent-id', templateIntentId);
  }

  if (product) {
    iframeURL.searchParams.append('data-solve-widget-product', product);
  }

  workflowCvs.forEach(cv => {
    const attrName = attrPrefix + cv.name.replaceAll(' ', '-');
    iframeURL.searchParams.append(attrName, cv.value);
  });

  return iframeURL.href;
};

export const getInteractiveEmailPreviewIframeUrl = ({
  conversationId,
  initialIntentId,
  language,
  previewToken,
  tag,
  token,
  workflowCvs,
}: {
  conversationId: string;
  initialIntentId: string;
  language: string;
  previewToken: string;
  tag: string | null;
  token: string | null;
  workflowCvs: { name: string; value: string }[];
}) => {
  const url = getIframeUrl({
    isTemplate: false,
    language,
    previewToken,
    tag,
    workflowCvs,
  });

  const iframeURL = new URL(url);

  iframeURL.searchParams.append('initial-intent-id', initialIntentId);
  iframeURL.searchParams.append('data-interactive-email-preview', 'true');

  // TODO: replace hardcoded value once BE updates are merged
  iframeURL.searchParams.append(
    'data-interactive-email-conversation-id',
    conversationId,
  );
  if (token) {
    iframeURL.searchParams.append('data-interactive-email-token', token);
  }

  return iframeURL.href;
};

export const getLanguageOptions = (
  languages: Language[],
  languageCodesEnabled: string[],
) => {
  const filteredLanguageOptions = languageCodesEnabled.filter(langCode =>
    languages.find(lang => lang.code === langCode),
  );
  const languageOptions = filteredLanguageOptions.map(langCode => {
    const lang = languages.find(lang => lang.code === langCode);
    return {
      label: lang?.display_name ?? '',
      value: lang?.code ?? '',
    };
  });

  languageOptions.unshift(englishOption);
  return languageOptions;
};

export const getWorkflowTagOptions = (workflowTags: string[]) => {
  const workflowTagOptions = workflowTags.map(tag => ({
    label: tag,
    value: tag,
  }));
  workflowTagOptions.unshift(allTagsOption);

  return workflowTagOptions;
};

export const useGetPreviewLogs = () => {
  const dispatch = useAppDispatch();
  const previewLogs = useSelector(selectPreviewLogs);

  useEffect(() => {
    const handleMessage = (
      e: MessageEvent<
        ForethoughtWidgetPreviewLogsEvent | ForethoughtWidgetPreviewRestartEvent
      >,
    ) => {
      if (isForethoughtWidgetPreviewLogsEvent(e)) {
        const { conversationId, previewLogs: newLogs } = e.data;

        if (newLogs) {
          dispatch(addPreviewLogs(newLogs));
        }

        if (conversationId) {
          dispatch(setPreviewConversationId(conversationId));
        }
      } else if (isForethoughtWidgetPreviewRestartEvent(e)) {
        dispatch(clearPreviewLogs());
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, [dispatch]);

  return previewLogs;
};

export const useGetCvNameDict = (cvIdDict: Record<string, unknown>) => {
  const { contextVariables } = useGetContextVariables();

  const cvIdKeys = Object.keys(cvIdDict);
  const cvNameDict: Record<string, unknown> = {};
  cvIdKeys.forEach(cvId => {
    const matchingCv = contextVariables.find(
      cv => cv.context_variable_id === cvId,
    );
    if (matchingCv) {
      cvNameDict[matchingCv.context_variable_name] = cvIdDict[cvId];
    }
  });

  return cvNameDict;
};

export const useGetDefaultIntentFilters = (): {
  defaultIntentFilters: IntentFilter[];
  defaultIntentFiltersMapping: Record<string, IntentFilter>;
} => {
  const { channel } = useParams<'channel'>();
  const { data: intentsResponse } = useGetIntentsQueryWithProduct();
  const { data: handoffConfigurationsResponse } =
    useGetHandoffConfigurationsQuery() ?? [];

  const intentFilters = useMemo(() => {
    const activeWorkflowTypeForChannel =
      channel === 'api'
        ? 'api-live'
        : channel === 'email'
        ? 'interactive-email-live'
        : 'flamethrower-live';
    const handoffConfigurations =
      handoffConfigurationsResponse?.configurations ?? [];

    // filter out handoffs
    const filteredIntents =
      intentsResponse?.intents.filter(intent => {
        const intentId = intent.intent_definition_id;
        return !handoffConfigurations.find(
          handoffConfig => handoffConfig.intent_definition_id === intentId,
        );
      }) ?? [];

    const intentFilters = filteredIntents.map(intent => ({
      intentId: intent.intent_definition_id,
      intentName: intent.intent_name,
      isAutoflow:
        channel === 'api'
          ? intent.api_workflow_is_autoflow
          : channel === 'email'
          ? intent.interactive_email_workflow_is_autoflow
          : intent.is_autoflow,
      isChecked: intent.active_workflow_types.includes(
        activeWorkflowTypeForChannel,
      ),
      isDraft: !intent.active_workflow_types.includes(
        activeWorkflowTypeForChannel,
      ),
    }));

    // sort alphabetically
    return intentFilters.sort((filterA, filterB) =>
      filterA.intentName.localeCompare(filterB.intentName),
    );
  }, [intentsResponse, handoffConfigurationsResponse, channel]);

  const intentFiltersMapping = useMemo(() => {
    const result: Record<string, IntentFilter> = {};
    intentFilters.forEach(
      intentFilter => (result[intentFilter.intentId] = intentFilter),
    );
    return result;
  }, [intentFilters]);

  return {
    defaultIntentFilters: intentFilters,
    defaultIntentFiltersMapping: intentFiltersMapping,
  };
};

export const convertIntentFiltersToDict = (
  intentFilters: IntentFilter[],
): IntentFiltersForIFrame => {
  const res: IntentFiltersForIFrame = {};

  intentFilters
    .filter(intentFilter => intentFilter.isChecked)
    .forEach(intentFilter => {
      res[intentFilter.intentId] = {
        is_autoflow: intentFilter.isAutoflow,
        is_draft: intentFilter.isDraft,
      };
    });

  return res;
};

export const convertIntentFiltersToDictForEmail = (
  intentFilters: IntentFilter[],
): IntentFiltersForEmail => {
  const res: IntentFiltersForEmail = {};

  intentFilters
    .filter(intentFilter => intentFilter.isChecked)
    .forEach(intentFilter => {
      res[intentFilter.intentId] = {
        is_draft: intentFilter.isDraft,
      };
    });

  return res;
};

export const isGeneralFallback = (intentId: string) => {
  return (
    intentId === CommonIntentWorkflowType.GENERAL_HANDOFF ||
    intentId === CommonIntentWorkflowType.KNOWLEDGE_ARTICLE
  );
};

export const filterNPartitionIntentFilters = ({
  defaultIntentFiltersMapping,
  intentFilters,
  previewFromIntentId,
  search,
}: {
  defaultIntentFiltersMapping: Record<string, IntentFilter>;
  intentFilters: IntentFilter[];
  previewFromIntentId: string | null;
  search: string;
}): [IntentFilter | null, IntentFilter[], IntentFilter[]] => {
  const filteredIntentFilters = intentFilters.filter(intentFilter =>
    intentFilter.intentName.toLowerCase().includes(search.toLowerCase()),
  );
  const currentIntentIndex = previewFromIntentId
    ? filteredIntentFilters.findIndex(
        intentFilter => intentFilter.intentId === previewFromIntentId,
      )
    : -1;
  const currentIntent =
    currentIntentIndex > -1 ? filteredIntentFilters[currentIntentIndex] : null;
  const otherIntents =
    currentIntentIndex > -1
      ? [
          ...filteredIntentFilters.slice(0, currentIntentIndex),
          ...filteredIntentFilters.slice(currentIntentIndex + 1),
        ]
      : filteredIntentFilters;
  const [liveIntents, inactiveIntents] = partition(intentFilter => {
    return defaultIntentFiltersMapping[intentFilter.intentId]?.isChecked;
  }, otherIntents);

  return [currentIntent, liveIntents, inactiveIntents];
};

export const useGetIntentFiltersMode = (): IntentFiltersMode => {
  const intentFilters = useSelector(selectPreviewIntentFilters);
  const { defaultIntentFilters, defaultIntentFiltersMapping } =
    useGetDefaultIntentFilters();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const previewFromIntentId = params.get('intentId');

  const mode: IntentFiltersMode = useMemo(() => {
    // set up the data we need for comparisons
    const previewIntent = intentFilters.find(
      intentFilter => intentFilter.intentId === previewFromIntentId,
    );
    const isPreviewIntentAnActiveIntent =
      defaultIntentFiltersMapping[previewFromIntentId || '']?.isChecked;

    const checkedActiveIntents = intentFilters.filter(
      intentFilter =>
        intentFilter.isChecked &&
        (isPreviewIntentAnActiveIntent ||
          intentFilter.intentId !== previewFromIntentId),
    );
    const activeIntents = defaultIntentFilters.filter(
      intentFilter => intentFilter.isChecked,
    );

    // num of checked active intents don't match
    if (checkedActiveIntents.length !== activeIntents.length) {
      return 'customized';
    }

    // check whether all checked active intent filters are the same as the default intent filter
    // currently previewing active intent is excluded
    const isLiveTraffic = checkedActiveIntents.every(intentFilter => {
      if (
        intentFilter.intentId === previewFromIntentId &&
        isPreviewIntentAnActiveIntent
      ) {
        return true;
      }

      return isEqual(
        intentFilter,
        defaultIntentFiltersMapping[intentFilter.intentId],
      );
    });

    if (!isLiveTraffic) {
      return 'customized';
    }

    return previewIntent?.isChecked && previewIntent?.isDraft
      ? 'current-intent-and-live-traffic'
      : 'live-traffic';
  }, [
    intentFilters,
    defaultIntentFilters,
    defaultIntentFiltersMapping,
    previewFromIntentId,
  ]);

  return mode;
};

export const useGetThemeBasedOnMode = () => {
  const theme = useTheme();
  const colorMode = useGetColorMode();

  const darkTheme = createTheme(theme, {
    palette: {
      colors: {
        blue: {
          500: darkModeBlue,
        },
        green: {
          500: darkModeGreen,
        },
        grey: {
          500: darkModeGrey,
        },
        purple: {
          500: darkModePurple,
        },
      },
      mode: 'dark',
    },
  });

  const colorModeTheme = colorMode === 'dark' ? darkTheme : theme;

  return colorModeTheme;
};

export const useGetLightTheme = () => {
  const theme = useTheme();

  const lightTheme = createTheme(theme, {
    palette: {
      mode: 'light',
    },
  });

  return lightTheme;
};

export const getTemplateIntentWorkflowId = (intentId: string) => {
  const intentWorkflowId = intentId.substring(
    intentId.indexOf(TEMPLATE_INTENT_WORKFLOW_ID_PREFIX) +
      TEMPLATE_INTENT_WORKFLOW_ID_PREFIX.length,
  );

  return intentWorkflowId;
};

export const useIsCurrentChannelEmail = () => {
  const { channel } = useParams<'channel'>();
  return channel === 'email';
};
