import { useCallback, useEffect, useRef, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import { styled } from '@mui/material';
import {
  BotTTSTextData,
  RTVIEvent,
  RTVIMessage,
  TranscriptData,
  TransportState,
} from '@pipecat-ai/client-js';
import {
  useRTVIClient,
  useRTVIClientEvent,
  useRTVIClientTransportState,
} from '@pipecat-ai/client-react';

import { Typography } from '@forethought-technologies/forethought-elements';
import { IntentFiltersButton } from '../IntentFiltersButton';
import { useGetThemeBasedOnMode } from '../utils';
import set from 'lodash/fp/set';
import { AgathaMessageContainer } from 'src/pages/intent-conversation-analytics/ConversationTranscript/StyledComponents';
import { VoiceTranscript } from 'src/slices/workflow-preview/types';

export function VoiceInfoConsole() {
  const endRef = useRef<HTMLDivElement>(null);
  const theme = useGetThemeBasedOnMode();
  const rtviClient = useRTVIClient();
  const transportState = useRTVIClientTransportState();
  const isIdle = ['error', 'disconnected', 'disconnecting'].includes(
    transportState,
  );
  const [transcripts, setTranscripts] = useState<VoiceTranscript[]>([]);

  useEffect(() => {
    if (endRef?.current) {
      endRef.current.scrollIntoView();
    }
  }, [transcripts]);

  useEffect(() => {
    if (transportState === 'initializing' || transportState === 'connecting') {
      setTranscripts([]);
    }
  }, [transportState]);

  const updateLatestBotTranscript = useCallback((botText: string) => {
    setTranscripts(prevTranscripts => {
      const latestTranscript = prevTranscripts[prevTranscripts.length - 1];

      if (latestTranscript === undefined || latestTranscript.from === 'user') {
        return [
          ...prevTranscripts,
          {
            from: 'bot',
            text: botText,
          },
        ];
      }

      if (latestTranscript.isFinal) {
        const newLatestTranscript: VoiceTranscript = {
          from: 'bot',
          isFinal: false,
          text: botText,
        };

        return prevTranscripts.concat([newLatestTranscript]);
      }

      const newLatestTranscript = {
        ...latestTranscript,
        text: latestTranscript.text.concat(botText),
      };

      const updatedTranscripts: VoiceTranscript[] = set(
        [prevTranscripts.length - 1],
        newLatestTranscript,
        prevTranscripts,
      );

      return updatedTranscripts;
    });
  }, []);

  const updateLatestUserTranscript = useCallback(
    (userText: string, isFinal: boolean) => {
      setTranscripts(prevTranscripts => {
        const latestTranscript = prevTranscripts[prevTranscripts.length - 1];

        if (latestTranscript === undefined || latestTranscript.from === 'bot') {
          return [
            ...prevTranscripts,
            {
              from: 'user',
              text: userText,
            },
          ];
        }

        // if latestTranscript msg is a user transcript and has isFinal true, we should create a new user transcript
        if (latestTranscript.isFinal) {
          const newLatestTranscript: VoiceTranscript = {
            from: 'user',
            isFinal: isFinal,
            text: userText,
          };

          return prevTranscripts.concat([newLatestTranscript]);
        }

        // prev transcript is not a final user transcript, modify the transcript directly
        const newLatestTranscript = {
          ...latestTranscript,
          isFinal: isFinal,
          text: userText,
        };

        const updatedTranscripts: VoiceTranscript[] = set(
          [prevTranscripts.length - 1],
          newLatestTranscript,
          prevTranscripts,
        );

        return updatedTranscripts;
      });
    },
    [],
  );

  useRTVIClientEvent(
    RTVIEvent.TransportStateChanged,
    useCallback(
      (state: TransportState) => {
        if (state === 'ready') {
          const message: RTVIMessage = {
            data: 'preview-start',
            id: 'preview-start',
            label: 'preview-start',
            type: 'text',
          };

          rtviClient?.sendMessage(message);
        }
      },
      [rtviClient],
    ),
  );

  useRTVIClientEvent(
    RTVIEvent.BotLlmText,
    useCallback(
      (data: BotTTSTextData) => {
        updateLatestBotTranscript(data.text);
      },
      [updateLatestBotTranscript],
    ),
  );

  useRTVIClientEvent(
    RTVIEvent.BotLlmStopped,
    useCallback(() => {
      setTranscripts(prevTranscripts => {
        const latestTranscript = prevTranscripts[prevTranscripts.length - 1];
        if (!latestTranscript || latestTranscript.from === 'user') {
          return prevTranscripts;
        }

        const updatedTranscripts: VoiceTranscript[] = set(
          [prevTranscripts.length - 1],
          { ...latestTranscript, isFinal: true },
          prevTranscripts,
        );

        return updatedTranscripts;
      });
    }, []),
  );

  useRTVIClientEvent(
    RTVIEvent.UserTranscript,
    useCallback(
      (data: TranscriptData) => {
        updateLatestUserTranscript(data.text, data.final);
      },
      [updateLatestUserTranscript],
    ),
  );

  return (
    <VoiceInfoConsoleContainer>
      <ThemeProvider theme={theme}>
        <Typography variant='font16Bold'>Filter intents for preview</Typography>

        <IntentFiltersButton
          disableUpdateFilter={!isIdle}
          hideModeColumn={true}
        />

        <TranscriptContainer>
          {transcripts.map(
            (transcript, index) =>
              transcript.text && (
                <AgathaMessageContainer
                  alignSelf={
                    transcript.from === 'user' ? 'flex-end' : 'flex-start'
                  }
                  backgroundColor={
                    transcript.from === 'user' ? 'black' : 'white'
                  }
                  key={index}
                  maxWidth='90%'
                  minHeight='fit-content'
                >
                  <Typography
                    color={transcript.from === 'user' ? 'white' : 'black'}
                    variant='font16'
                  >
                    {transcript.text}
                  </Typography>
                </AgathaMessageContainer>
              ),
          )}
          <div ref={endRef} />
        </TranscriptContainer>
      </ThemeProvider>
    </VoiceInfoConsoleContainer>
  );
}

const VoiceInfoConsoleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 550px;
  height: 100%;
  border-radius: 16px 0px 0px 16px;
  background-color: rgb(55, 55, 55, 0.3);
  color: white;
  padding: 24px;
  gap: 16px;

  box-shadow: ${props => props.theme.shadows[3]};
`;

const TranscriptContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  overflow: auto;
  padding: 0 8px 0 0;
`;
