import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material';

import {
  theme,
  Typography,
} from '@forethought-technologies/forethought-elements';
import DiscoverCard from '../../discover-card/DiscoverCard';
import { formatDateForTicketCard, getFirstSentenceAndRest } from './helpers';
import ArticleIcon from 'src/assets/images/article-icon.svg';
import SupportGPTIcon from 'src/assets/images/support-gpt.svg';
import { ArticleFeedbackButtons } from 'src/components/global-discover/article/article-feedback';
import {
  HEADLINE_REGEX,
  INSTRUCTION_REGEX,
} from 'src/components/global-discover/constants';
import { DiscoverArticle } from 'src/reducers/discoverReducer/types';

interface ArticleCardProps {
  articleData: DiscoverArticle;
  isBrandsEnabled?: boolean;
  onClick: () => void;
  setFeedBackArticleModalId: (articleId: string) => void;
}

const ArticleCard: React.FC<React.PropsWithChildren<ArticleCardProps>> = ({
  articleData,
  isBrandsEnabled,
  onClick,
  setFeedBackArticleModalId,
}) => {
  const {
    article_generated_at: dateCreated,
    generated_body: body,
    generated_title: title,
  } = articleData;

  const renderArticleCardBody = () => {
    if (title) {
      const hasBrand = Boolean(articleData?.api_data_filter) && isBrandsEnabled;
      return (
        <Box display='flex' flexDirection='column' gap={0.5}>
          <Box alignItems='center' display='flex' gap='8px'>
            <img src={ArticleIcon} />
            <Typography variant='font14Bold'>
              {title.replace(HEADLINE_REGEX, '')}
            </Typography>
          </Box>
          {hasBrand && (
            <Typography variant='font14'>
              Brand: {articleData.api_data_filter}
            </Typography>
          )}
          <DescriptionText shouldHaveMarginTop>
            {body.slice(0, 500).replace(INSTRUCTION_REGEX, '')}
          </DescriptionText>
        </Box>
      );
    }
    const [firstSentence, rest] = getFirstSentenceAndRest(body ?? '');

    return (
      <DescriptionText>
        <Typography variant='font14Bold'>
          {firstSentence.replace(INSTRUCTION_REGEX, '')}
        </Typography>
        {rest?.slice(0, 500)}
      </DescriptionText>
    );
  };

  return (
    <Box mb={2.5}>
      <DiscoverCard
        featureName='article'
        headerComponent={
          <Box
            alignItems='center'
            display='flex'
            justifyContent='space-between'
          >
            <Box alignItems='center' display='flex' gap='8px'>
              <img src={SupportGPTIcon} />
              <Typography
                color={theme.palette.colors.grey[800]}
                variant='font12'
              >
                SupportGPT™ generated{' '}
                {formatDateForTicketCard(new Date(dateCreated).getTime())}{' '}
              </Typography>
            </Box>
            <ArticleFeedbackButtons
              article={articleData}
              setPatchedArticleModalId={setFeedBackArticleModalId}
            />
          </Box>
        }
        linkText='View details'
        onClick={onClick}
      >
        <Box mt={2}>
          <Box width='100%'>{renderArticleCardBody()}</Box>
        </Box>
      </DiscoverCard>
    </Box>
  );
};

export default ArticleCard;

const DescriptionText = styled('p')<{ shouldHaveMarginTop?: boolean }>`
  font-size: ${theme.typography.font14.fontSize}px;
  font-weight: ${theme.typography.font14.fontWeight};
  line-height: ${theme.typography.font14.lineHeight};
  color: ${theme.palette.colors.black};
  margin-bottom: 0;
  margin-top: ${props => (props.shouldHaveMarginTop ? 8 : 0)}px;
  // css properties for ellipsis after second line
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
